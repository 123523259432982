import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { Media } from '../common/ProductForms/Media'

import { TabEditing } from 'components/common/TabEditing'
import { s } from 'lib/styled'
import { productModule } from 'modules/product'
import { getSubmitMedia } from 'utils/getSubmitMedia'
import { deepMemo } from 'utils/react'

const { array, object } = Validator

const validationSchema = object({
  media: object({
    photos: array().required('Upload at least one photo'),
  }),
})

const Memoized = deepMemo(MediaTab)

function MediaTab({
  inspectedProduct,
  initialValues,
  isEditing,
  setIsEditing,
  tabTitle,
  currentTab,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)

  if (currentTab !== tabTitle) {
    return null
  }

  const { id } = inspectedProduct

  async function handleSubmit({ media }) {
    const submitMedia = getSubmitMedia(inspectedProduct, media)

    await productModule.updateProductMedia(null, id, submitMedia)

    setIsEditing(false)
  }

  return (
    <Formik
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form style={s('flex flex-column', { overflow: 'hidden' })} key={isCanceled}>
        <Media isEditing={isEditing} />

        <TabEditing
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCanceled={isCanceled}
          setIsCanceled={setIsCanceled}
          disabled={isEditingDisabled}
        />
      </Form>
    </Formik>
  )
}

export { Memoized as MediaTab }
