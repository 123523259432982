import CONSTANTS from '@solta/constants'
import { pickThenRename } from '@soltalabs/ramda-extra'
import * as Validator from 'yup'

import { AvatarUpload } from './AvatarUpload'

import { PhoneField } from 'components/common/PhoneField'
import { TextField } from 'components/common/TextField'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'
import { authModule } from 'modules/auth'

const { object, string } = Validator
const validationSchema = Validator.object().shape({
  profile: object().shape({
    firstName: string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, 'Must contain only letters')
      .required('This field is required'),
    lastName: string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, 'Must contain only letters')
      .required('This field is required'),
    phoneNumber: string()
      .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
      .required('This field is required'),
  }),
})

const Root = styled.div(s('mt-8 mx-6 mb-8'))
const Title = styled.h1(
  s('font-light m-0 mb-8 text-gray-800 text-center text-lg'),
  smaller('sm')(s('text-base'))
)

function ProfileStep() {
  async function handleSubmit({ hasPlayzAccount, ...values }) {
    if (!hasPlayzAccount) {
      await authModule.createAccount(null, values.profile)
      return
    }

    const data = pickThenRename(
      ['firstName', 'lastName', 'phoneNumber'],
      {
        phoneNumber: 'mobile',
      },
      values.profile
    )
    await authModule.updateAccount(null, data)
  }

  return (
    <Wizard.Step
      id="profile"
      title="Profile"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Root>
        <Row justify="center">
          <Col>
            <Title>Set up your profile</Title>
          </Col>
        </Row>

        <Row justify="center" style={s('mb-12')}>
          <Col>
            <AvatarUpload />
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={11} md={7}>
            <TextField name="profile.firstName" label="First name" />
          </Col>

          <Col xs={24} sm={11} md={7}>
            <TextField name="profile.lastName" label="Last name" />
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={24} sm={10} md={8} style={s('items-center')}>
            <PhoneField
              onlyCountries={['NZ', 'AU', 'FJ']}
              name="profile.phoneNumber"
              label="Phone number"
            />
          </Col>
        </Row>
      </Root>
    </Wizard.Step>
  )
}

export { ProfileStep as ProfileForm }
