import { CommunityVenue } from './CommunityVenue'
import { OnlineVenue } from './OnlineVenue'
import { PhysicalVenue } from './PhysicalVenue'

function VenueTab({ listingType, ...props }) {
  return (
    <>
      <OnlineVenue listingType={listingType} {...props} />
      <PhysicalVenue listingType={listingType} {...props} />
      <CommunityVenue listingType={listingType} {...props} />
    </>
  )
}

export { VenueTab }
