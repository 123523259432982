import { useWizard } from 'lib/formik-wizard'
import { styled, s, smaller } from 'lib/styled'

const Root = styled.div(s('flex flex-row'))
const Title = styled.div(
  s(
    'flex-1 py-4 text-center font-medium text-base border-0 border-solid border-primary'
  ),
  {
    borderBottomWidth: '2px',
  },
  smaller('sm')(s('text-sm')),
  ({ hasSteppedOver = false }) =>
    hasSteppedOver
      ? s('border-primary text-primary')
      : {
          borderColor: '#DDD',
          color: '#DDD',
        }
)

const stepShape = Props.shape({
  id: Props.string.isRequired,
  title: Props.string.isRequired,
  Component: Props.elementType,
})

WizardProgress.propTypes = {
  steps: Props.arrayOf(stepShape),
}

function WizardProgress({ ...props }) {
  const { currentStepIndex, steps } = useWizard()

  return (
    <Root {...props}>
      {steps.map(({ id, title }, stepIndex) => (
        <Title key={id} hasSteppedOver={stepIndex <= currentStepIndex}>
          {title}
        </Title>
      ))}
    </Root>
  )
}

export { WizardProgress }
