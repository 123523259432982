import { Switch, Route, Redirect } from 'react-router-dom'

import { OrderDetails } from './OrderDetails'
import { OrdersTable } from './OrdersTable'

import { styled, s } from 'lib/styled'

const Container = styled.div(s('flex-1 flex flex-row p-6'))

function OrdersDashboard() {
  return (
    <Container>
      <Switch>
        <Route exact path="/orders/:id" component={OrderDetails} />
        <Route exact path="/orders" component={OrdersTable} />

        <Redirect to="/orders" />
      </Switch>
    </Container>
  )
}

export { OrdersDashboard }
