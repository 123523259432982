import CONSTANTS from '@solta/constants'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { TabEditing } from './TabEditing'

import { CommunityActivityForm } from 'components/listings/common/CommunityActivityForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { styled, s } from 'lib/styled'
import { listingModule, LISTING_TYPES } from 'modules/listing'
import { getCommunityAvailability } from 'utils/getCommunityAvailability'

const Root = styled.div(s('flex-1 flex flex-column w-full mt-6'), {
  overflow: 'hidden',
})

const { object, string, boolean } = Validator

const communityActivitySchema = Validator.object({
  communityActivity: object({
    location: object().shape({
      name: string()
        .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
        .required('This field is required'),
      address: object().required('Not a valid address'),
      hasWheelchairAccess: boolean(),
      transportNotes: string().nullable().optional(),
      parkingNotes: string().nullable().optional(),
      timezone: string(),
    }),

    contact: object({
      name: string().required('This field is required'),
      phoneNumber: string()
        .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
        .required('This field is required'),
      email: string()
        .email('Not a valid email address')
        .required('This field is required'),
    }),
  }),
})

function CommunityVenue({
  listingType,
  isEditing,
  setIsEditing,
  currentTab,
  tabTitle,
  inspectedListing,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)) {
    return null
  }

  if (currentTab !== tabTitle) {
    return null
  }

  const { availabilities } = inspectedListing

  async function handleSubmit({ communityActivity }) {
    await listingModule.updateCommunityAvailability(null, communityActivity)

    setIsEditing(false)
  }

  return (
    <Formik
      id="venues"
      title="Venues"
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={{
        communityActivity: getCommunityAvailability(availabilities),
      }}
      validationSchema={communityActivitySchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form
          style={s('flex flex-column flex-1', { overflow: 'hidden' })}
          key={isCanceled}
        >
          <Root>
            <CommunityActivityForm isEditing={isEditing} />
          </Root>

          <TabEditing
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isCanceled={isCanceled}
            setIsCanceled={setIsCanceled}
            formError={errors}
            disabled={isEditingDisabled}
          />
        </Form>
      )}
    </Formik>
  )
}

export { CommunityVenue }
