import { pick } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { AuthService } from './service'

import { providerModule } from 'modules/provider'
import { UploadService } from 'modules/upload'
import { wallet } from 'wallet'

const INITIAL_STATE = Object.freeze({
  account: undefined,
  user: undefined,
  uploads: {
    avatar: undefined,
  },

  currentProvider: undefined,
})

const checkOnboardingStatus = (module) => async () => {
  const [account, user] = await Promise.all([
    AuthService.readAccount(),
    wallet.getUser(),
    providerModule.listProviders(),
  ])

  module.setState({ account, user })
}

const createAccount = (module) => async (_, data) => {
  const {
    uploads: { avatar },
  } = module.getState()

  const account = await AuthService.createAccount({
    ...pick(['firstName', 'lastName', 'phoneNumber'], data),
    avatar,
  })

  module.setState({ account })
  module.resetAvatar()
}

const updateAccount =
  (module) =>
  async (_, { firstName, lastName, mobile }) => {
    const {
      uploads: { avatar },
    } = module.getState()

    const account = await AuthService.updateAccount({
      firstName,
      lastName,
      mobile,
      avatar,
    })

    module.setState({ account })
    module.resetAvatar()
  }

const uploadAvatar = (module) => async (_, avatarFile) => {
  const { id } = await UploadService.uploadFile(avatarFile)

  module.setState({
    uploads: {
      avatar: id,
    },
  })
}

const resetAvatar = (module) => () => {
  module.setState({
    uploads: {
      avatar: undefined,
    },
  })
}

const resendVerificationEmail = () => async () => {
  await AuthService.resendVerificationEmail()
}

const authModule = createModule({
  name: 'auth',
  initialState: INITIAL_STATE,
  decorators: {
    checkOnboardingStatus,
    uploadAvatar,
    resetAvatar,
    createAccount,
    updateAccount,
    resendVerificationEmail,
  },
})

providerModule.subscribe(({ currentProvider }) => {
  authModule.setState({ currentProvider })
})

export { authModule }
