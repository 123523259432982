import { isNil, path, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useState, useLayoutEffect, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { MediaTab } from './MediaTab'
import { VenueTab } from './VenueTab'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabsNavigation } from 'components/common/TabsNavigation'
import { DescriptionTab } from 'components/listings/ListingDetails/DescriptionTab'
import { LISTING_STATUSES } from 'constants/listingStatus'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { inspectedListing$, isInspectingListing$, listingModule } from 'modules/listing'
import { listingEventModule } from 'modules/listing/event'
import {
  provider$,
  currentProviderTimezone$,
  isProviderSuspended$,
} from 'modules/provider'
import { venueModule, physicalVenueList$, isLoadingVenues$ } from 'modules/venue'

const Root = styled.div(
  s('p-2 flex-1 flex flex-column bg-white rounded-lg shadow-sm w-full')
)

const ConnectedListingDetails = connect(() => ({
  inspectedListing: inspectedListing$,
  isInspectingListing: isInspectingListing$,
  venues: physicalVenueList$,
  isLoadingVenues: isLoadingVenues$,
  provider: provider$,
  currentProviderTimezone: currentProviderTimezone$,
  isProviderSuspended: isProviderSuspended$,
}))(ListingDetails)

const tabs = [{ title: 'Description' }, { title: 'Media' }, { title: 'Venues' }]

function ListingDetails({
  inspectedListing,
  isInspectingListing,
  venues,
  isLoadingVenues,
  provider,
  currentProviderTimezone,
  isProviderSuspended,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(path([0, 'title'], tabs))
  const [newVenues, setNewVenues] = useState([])
  const { id } = useParams()

  useEffect(() => {
    if (!isNil(id) && isNil(inspectedListing)) {
      listingModule.inspectListing(id)
    }
  }, [])

  const listingStatus = prop('status')(inspectedListing)
  const listingType = prop('listingType')(inspectedListing)

  useLayoutEffect(() => {
    venueModule.fetchVenues()
    listingEventModule.fetchEvents()
  }, [])

  if (listingStatus === LISTING_STATUSES.DRAFT) {
    return <Redirect to={`/listings/draft/${inspectedListing.id}`} />
  }

  return (
    <Root>
      <Loader
        loading={isInspectingListing || isLoadingVenues}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <TabsNavigation
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          isEditing={isEditing}
        />

        <Row
          style={s('justify-between flex-1', {
            overflow: 'hidden',
          })}
        >
          <Col
            span={24}
            style={s('flex flex-column h-full', {
              overflow: 'hidden',
            })}
          >
            <DescriptionTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedListing={inspectedListing}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Description"
            />

            <MediaTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedListing={inspectedListing}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Media"
            />

            <VenueTab
              listingType={listingType}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedListing={inspectedListing}
              venues={venues}
              provider={provider}
              newVenues={newVenues}
              setNewVenues={setNewVenues}
              currentProviderTimezone={currentProviderTimezone}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Venues"
            />
          </Col>
        </Row>
      </Loader>
    </Root>
  )
}

export { ConnectedListingDetails as ListingDetails }
