/**
 * Function to generate all possible combinations of an array of n length filled with objects containing arrays of m length.
 * @param {Array} options - The array of option objects
 * @param {Number} basePrice - The price of the product to be used as the base price for all generated variants.
 * @returns Array of all combinations of the given options objects.
 */

function findAllVariants(options, basePrice = 0) {
  if (!Array.isArray(options)) throw new TypeError('options must be an array')
  if (typeof basePrice !== 'number')
    throw new TypeError('basePrice must be of type number')
  if (options.length === 0) return []

  const accumulatedVariants = []

  /**
   * Recursive step function to generate all possible variants step by step and put them in accumulatedVariants
   * @param {Number} index - The index of the options array to start at
   * @param {Array[String]} inProgressVariantData - The variant currently being composed
   */

  const findAllVariantsStep = (index, inProgressVariantData) => {
    if (index >= options.length) {
      accumulatedVariants.push({ price: basePrice, optionSet: inProgressVariantData })
      return
    }
    for (let i = 0; i < options[index].values.length; i += 1) {
      const thisOptionVariant = [...inProgressVariantData]
      thisOptionVariant.push(options[index].values[i])
      findAllVariantsStep(index + 1, thisOptionVariant)
    }
  }

  findAllVariantsStep(0, [])

  return accumulatedVariants
}

export { findAllVariants }
