import { prop, map, keys, head, isNil } from '@soltalabs/ramda-extra'

const fieldsNameMapper = {
  conference: 'Conference Details',
  contact: 'Contact Information',
  pricing: 'Pricing',
  events: 'Date & Time',
}

function getOnlineVenueError(errors) {
  const formErrors = prop('onlineActivity')(errors)

  if (isNil(formErrors)) {
    return undefined
  }

  const firstFieldNeedChange = head(
    map((field) => fieldsNameMapper[field])(keys(formErrors))
  )

  return `There are some issues with ${firstFieldNeedChange}`
}

export { getOnlineVenueError }
