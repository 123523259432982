import CONSTANTS from '@solta/constants'
import { isNotEmpty, pipe, filter, map, prop, reduce } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import { useRef, useState } from 'react'
import * as Validator from 'yup'

import { TabEditing } from './TabEditing'

import { Button } from 'components/common/Button'
import { CreateVenueModal } from 'components/listings/common/CreateVenueModal'
import { PhysicalActivitiesForm } from 'components/listings/common/PhysicalActivitiesForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { styled, s } from 'lib/styled'
import { listingModule, LISTING_TYPES } from 'modules/listing'
import { venueModule } from 'modules/venue'
import { getPhysicalAvailabilities } from 'utils/getPhysicalAvailabilities'

const Root = styled.div(s('flex-1 flex flex-column w-full mt-6'), {
  overflow: 'hidden',
})
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)

const TitleBar = styled.div(s('flex flex-row mb-2 items-center justify-between'))
const CreateVenueButton = styled(Button)(s('border-0'))

const { object, string, boolean, number, array, ref } = Validator

const pricingOptionSchema = object().when('isSelected', {
  is: true,
  then: object({
    hasLimitedSpots: boolean(),
    totalSpots: number()
      .min(0, 'Must be a positive number')
      .max(9999, 'Must be less than 9999')
      .optional(),

    options: array()
      .required('Must have at least one pricing')
      .when('hasLimitedSpots', {
        is: true,
        then: array().test({
          name: "Spots don't exceed limit",
          message:
            'Total spot allocation exceeded. Please re-check your pricing options.',
          test(options) {
            const totalSpots = this.resolve(ref('totalSpots'))
            const allocatedSpots = reduce(
              (acc, option) => acc + option.spots,
              0,
              options
            )
            const hasAllocatedExcessSpots = allocatedSpots > totalSpots

            return !hasAllocatedExcessSpots
          },
        }),
      })
      .of(
        object({
          name: string()
            .matches(
              NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX,
              'Must not contain: !@#$%^&*~'
            )
            .required('This field is required'),
          description: string()
            .matches(
              NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX,
              'Must not contain: !@#$%^&*~'
            )
            .optional(),
          spots: number()
            .min(1, 'Must be at least 1')
            .required('This field is required'),
          price: number().required('This field is required'),
        })
      ),
  }),
})

const physicalActivitiesSchema = Validator.object({
  availabilities: array()
    .of(
      object({
        contact: object().when('isSelected', {
          is: true,
          then: object({
            name: string().required('This field is required'),
            phoneNumber: string()
              .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
              .required('This field is required'),
            email: string()
              .email('Not a valid email address')
              .required('This field is required'),
          }),
        }),

        pricing: pricingOptionSchema,

        events: array().when('isSelected', {
          is: true,
          then: array().when('hasExistingEvent', {
            is: false,
            then: array().required('Must select a Date & Time'),
          }),
        }),
      })
    )
    .test({
      name: 'Must select one venue',
      message: 'Must select at least one venue',
      test(availabilities) {
        const selectedAvailabilities = filter(({ isSelected }) => isSelected)(
          availabilities
        )

        return isNotEmpty(selectedAvailabilities)
      },
    }),
})

function PhysicalVenue({
  listingType,
  venues,
  isEditing,
  setIsEditing,
  currentTab,
  tabTitle,
  inspectedListing,
  provider,
  newVenues,
  setNewVenues,
  isEditingDisabled,
}) {
  const modalRef = useRef()
  const [isCanceled, setIsCanceled] = useState(false)

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)) {
    return null
  }

  if (currentTab !== tabTitle) {
    return null
  }

  const { title, availabilities } = inspectedListing

  async function handleReset() {
    if (isNotEmpty(newVenues)) {
      await Promise.all(
        newVenues.map(async (venue) => venueModule.deletePhysicalVenue(null, venue))
      )
      setNewVenues([])
    }
  }

  async function handleSubmit({ availabilities }) {
    const mappedAvailabilities = []

    mappedAvailabilities.push(
      ...pipe(
        filter(prop('isSelected')),
        map(({ venue, contact, pricing, events }) => ({
          isOnline: false,
          activity: {
            venueId: venue.id,
            contact,
          },
          pricing,
          events,
        }))
      )(availabilities)
    )

    await listingModule.updatePhysicalAvailabilities(
      null,
      availabilities,
      mappedAvailabilities
    )

    setIsEditing(false)
  }

  return (
    <Formik
      id="venues"
      title="Venues"
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={{
        description: {
          title,
        },

        initialVenues: venues,

        availabilities: getPhysicalAvailabilities(availabilities, provider, venues),
      }}
      validationSchema={physicalActivitiesSchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form
          style={s('flex flex-column flex-1', { overflow: 'hidden' })}
          key={isCanceled}
        >
          <CreateVenueModal
            ref={modalRef}
            newVenues={newVenues}
            setNewVenues={setNewVenues}
          />
          <Root>
            <TitleBar>
              <Label>Physical venues</Label>
              <CreateVenueButton
                onClick={() => modalRef.current.open()}
                disabled={!isEditing}
              >
                Create new venue
              </CreateVenueButton>
            </TitleBar>
            <PhysicalActivitiesForm isEditing={isEditing} />
          </Root>

          <TabEditing
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isCanceled={isCanceled}
            setIsCanceled={setIsCanceled}
            customHandleReset={handleReset}
            formError={errors}
            disabled={isEditingDisabled}
          />
        </Form>
      )}
    </Formik>
  )
}

export { PhysicalVenue }
