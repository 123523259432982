/* eslint-disable react/display-name */
import { forwardRef } from 'react'

import { s } from 'lib/styled'

export const Menu = forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    style={s(
      ' flex flex-row items-center relative py-1 px-4 mb-4 border-0 border-solid border-b-1 border-gray-500 ',
      {
        '& > *': s('inline-block'),
      }
    )}
  />
))
