import { Button as ButtonBase } from 'components/common/Button'
import { styled, s } from 'lib/styled'
import { memo } from 'utils/react'

const Button = styled(ButtonBase)(s('text-base'))

const Memoized = memo(NextButton)

function NextButton({
  disableSubmitButton,
  stepCount,
  currentStepIndex,
  isSubmitting,
}) {
  const isLast = currentStepIndex === stepCount - 1

  return (
    <Button disabled={isSubmitting || disableSubmitButton} type="submit">
      {isLast ? 'Finish' : 'Next'}
    </Button>
  )
}

export { Memoized as NextButton }
