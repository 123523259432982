import { set, getHours, getMinutes, getSeconds, getMilliseconds } from 'date-fns'

function setTimeComponent(timeSource, targetDate) {
  return set(targetDate, {
    hours: getHours(timeSource),
    minutes: getMinutes(timeSource),
    seconds: getSeconds(timeSource),
    milliseconds: getMilliseconds(timeSource),
  })
}

export { setTimeComponent }
