import { memo } from 'react'

import { Col } from './Col'
import { Container } from './Container'
import { Row } from './Row'
import 'antd/es/grid/style/css'

// The grid system is essentially Bootstrap 4's grid system, with more columns and
// configurability.
// Documentation: https://ant.design/components/grid/#API

const MemoContainer = memo(Container)
const MemoRow = memo(Row)
const MemoCol = memo(Col)

export { MemoContainer as Container, MemoRow as Row, MemoCol as Col }
