import 'whatwg-fetch'
import { equals } from '@soltalabs/ramda-extra'
import ky from 'ky'

import { CONFIG } from 'config'
import { authModule } from 'modules/auth'
import { wallet } from 'wallet'

const http = ky.create({
  headers: {
    'content-type': 'application/json',
  },
  prefixUrl: CONFIG.API.URL,
  hooks: {
    beforeRequest: [useAuthorization, useProviderAccessControl],
  },
})

async function useAuthorization(request, options) {
  const skipAuthorization = equals(false, options.authorize)

  if (skipAuthorization) {
    return
  }

  const token = await wallet.getToken()
  request.headers.set('Authorization', `Bearer ${token}`)

  // eslint-disable-next-line no-param-reassign
  delete options.authorize
}

async function useProviderAccessControl(request, options) {
  const skipAccessControl = equals(false, options.rbac)

  if (skipAccessControl) {
    return
  }

  const { currentProvider } = authModule.getState()
  request.headers.set('playz-provider', currentProvider)

  // eslint-disable-next-line
  delete options.authorize
}

export { http }
