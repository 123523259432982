import { path, prop, defaultTo, isNil } from '@soltalabs/ramda-extra'

const communitySpots = 100

function getInitialCommunityActivityObject(provider, currentProviderTimezone) {
  return {
    id: undefined,

    venueId: undefined,

    location: {
      name: '',
      address: undefined,
      hasWheelchairAccess: false,
      timezone: currentProviderTimezone,
      transportNotes: undefined,
      parkingNotes: undefined,
    },

    contact: {
      name: provider.name,
      phoneNumber: provider.phone,
      email: provider.email,
    },

    pricing: {
      options: [
        {
          name: 'Community pricing',
          description: undefined,
          spots: communitySpots,
          price: 0,
        },
      ],
    },

    eventSerieId: undefined,

    noDateRange: true,
    startDate: undefined,
    endDate: undefined,
    note: undefined,
  }
}

function getCommunityAvailability(availabilities = []) {
  const communityActivity = prop(0, availabilities)

  const pricings = defaultTo([])(path(['pricings'], communityActivity))

  const startDate = path(['startDate'], communityActivity)
  const endDate = path(['endDate'], communityActivity)

  return {
    id: path(['id'], communityActivity),

    venueId: path(['venue', 'id'], communityActivity),

    location: {
      name: path(['venue', 'name'], communityActivity),
      address: path(['venue', 'address'], communityActivity),
      hasWheelchairAccess: path(['venue', 'wheelchairAccessible'], communityActivity),
      timezone: path(['venue', 'timezone'], communityActivity),
      transportNotes: path(['venue', 'transports', 0], communityActivity),
      parkingNotes: path(['venue', 'parkings', 0], communityActivity),
    },

    contact: {
      name: path(['contact', 'name'], communityActivity),
      phoneNumber: path(['contact', 'phone'], communityActivity),
      email: path(['contact', 'email'], communityActivity),
    },

    pricing: {
      options: pricings.map((pricing) => ({
        id: pricing.id,
        name: pricing.name,
        description: pricing.description,
        spots: pricing.occupiedSpots,
        price: pricing.price,
      })),
    },

    eventSerieId: path(['serie'], communityActivity),

    noDateRange: !!isNil(path(['endDate'], communityActivity)),
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
    note: path(['note'], communityActivity),
  }
}

export { getCommunityAvailability, getInitialCommunityActivityObject }
