import { connect } from '@soltalabs/stateless'
import { useLayoutEffect, useState } from 'react'

import { DeleteProductModal } from '../common/DeleteProductModal'
import { Table } from '../common/ProductTable'
import { PublishProductModal } from '../common/PublishProductModal'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { styled, s } from 'lib/styled'
import {
  productList$,
  filterQuery$,
  filterStatus$,
  limit$,
  paging$,
  productModule,
} from 'modules/product'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const {
  fetchProducts,
  filterProducts,
  filterStatusProducts,
  inspectProduct,
  turnPage,
  deleteProduct,
  publishProduct,
} = productModule

const ConnectedProductTable = connect(() => ({
  productList: productList$,
  filterQuery: filterQuery$,
  filterStatus: filterStatus$,
  paging: paging$,
  limit: limit$,
}))(ProductsTable)

function ProductsTable({ productList, filterQuery, filterStatus, paging, limit }) {
  useLayoutEffect(() => {
    fetchProducts()
  }, [])

  const columns = Columns()
  const rows = Rows(productList)
  const { startRow, next } = paging

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState(undefined)
  const [publishProductId, setPublishProductId] = useState(undefined)
  const [shouldShowDeleteError, setShouldShowDeleteError] = useState(false)

  function handleOpenDeleteModal(id) {
    setDeleteProductId(id)
    setIsDeleteModalOpen(true)
  }

  function handleOpenPublishModal(id) {
    setPublishProductId(id)
    setIsPublishModalOpen(true)
  }

  async function handleProductDelete() {
    const errorOccured = await deleteProduct(deleteProductId)
    setShouldShowDeleteError(errorOccured)
    if (!errorOccured) setIsDeleteModalOpen(false)
  }

  async function handleProductPublish() {
    await publishProduct(publishProductId)
    setIsPublishModalOpen(false)
  }

  return (
    <Container>
      <Filters
        filterQuery={filterQuery}
        filterProducts={filterProducts}
        filterStatus={filterStatus}
        filterStatusProducts={filterStatusProducts}
      />

      <Table
        domain={'products'}
        columns={columns}
        data={rows}
        startRow={startRow}
        next={next}
        turnPage={turnPage}
        onOpenDeleteModal={handleOpenDeleteModal}
        onOpenPublishModal={handleOpenPublishModal}
        limit={limit}
        onClick={inspectProduct}
      />

      <DeleteProductModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDeleteProduct={handleProductDelete}
        shouldShowError={shouldShowDeleteError}
      />

      <PublishProductModal
        isOpen={isPublishModalOpen}
        setIsOpen={setIsPublishModalOpen}
        onPublishProduct={handleProductPublish}
      />
    </Container>
  )
}

export { ConnectedProductTable as ProductsTable }
