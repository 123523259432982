import { useFormikContext as useFormContext } from 'formik'
import * as Validator from 'yup'

import { Media } from '../common/ProductForms/Media'

import { Wizard } from 'lib/formik-wizard'
import { productModule } from 'modules/product'
import { getMedia } from 'utils/getMedia'
import { getSubmitMedia } from 'utils/getSubmitMedia'

const { array, object } = Validator

const validationSchema = object({
  media: object({
    photos: array().required('Upload at least one photo'),
  }),
})

function MediaStep() {
  const { setFieldValue } = useFormContext()

  async function handleSubmit({ draftId, media }) {
    const draftListing = await productModule.read(draftId)

    const submitMediaList = getSubmitMedia(draftListing, media)

    await productModule.updateProductMedia(null, draftId, submitMediaList)

    const newDraftListing = await productModule.read(draftId)

    setFieldValue('media', getMedia(newDraftListing))
  }

  return (
    <Wizard.Step
      id="media"
      title="Media"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Media isEditing={true} />
    </Wizard.Step>
  )
}

export { MediaStep }
