/* eslint-disable camelcase */
import createAuth0Client from '@auth0/auth0-spa-js'
import Emittery from 'emittery'

const EVENTS = {
  READY: 'ready',
  IDENTITY: {
    REDIRECT_CALLBACK: 'redirect',
  },
}

class Wallet {
  constructor(config) {
    this.config = Object.freeze(config)
    this.identity = undefined
    this.payment = undefined

    this.emitter = new Emittery()
  }

  async initialize() {
    const { identity: identityConfig } = this.config
    const { domain, clientId, redirectUrl, audience } = identityConfig

    this.identity = await createAuth0Client({
      domain,
      client_id: clientId,
      redirect_uri: redirectUrl,
      audience,
    })
    this.payment = {}

    this.emitter.emit(EVENTS.READY)
  }

  on(event, callback) {
    return this.emitter.on(event, callback)
  }

  requestAuthorization({ targetUrl, screenHint = 'login' }) {
    this.identity.loginWithRedirect({ appState: targetUrl, screen_hint: screenHint })
  }

  logout() {
    const { identity: identityConfig } = this.config

    this.identity.logout({
      returnTo: identityConfig.redirectUrl,
    })
  }

  async handleAuthorizationCallback() {
    const { appState } = await this.identity.handleRedirectCallback()
    const user = await this.identity.getUser()

    this.emitter.emit(EVENTS.IDENTITY.REDIRECT_CALLBACK, { user })

    return { appState, user }
  }

  async getUser() {
    return this.identity.getUser()
  }

  async getToken() {
    return this.identity.getTokenSilently()
  }

  async isAuthenticated() {
    return this.identity.isAuthenticated()
  }
}

export { Wallet }
