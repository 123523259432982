import { useState, useEffect } from 'react'

import { breakpoints } from './breakpoints'

const { xl, lg, md, sm } = breakpoints

function Container({ gutter = 16, fluid = false, ...props }) {
  const [width, setWidth] = useState(calculateWidth(gutter, fluid, window.innerWidth))

  function handleWindowResize() {
    setWidth(calculateWidth(gutter, fluid, window.innerWidth))
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const containerStyles = !fluid
    ? {
        boxSizing: 'border-box',
        maxWidth: '100%',
      }
    : {}

  return (
    <div
      style={{
        width,
        marginLeft: 'auto',
        marginRight: 'auto',
        ...containerStyles,
      }}
      {...props}
    />
  )
}

function calculateWidth(gutter, fluid, windowWidth) {
  if (fluid) {
    return windowWidth - 2 * gutter
  }

  for (const breakpoint of [xl, lg, md, sm]) {
    if (windowWidth >= breakpoint) {
      return breakpoint - 2 * gutter
    }
  }

  return windowWidth - 2 * gutter
}

export { Container }
