import { useFormikContext as useFormContext } from 'formik'
import * as Validator from 'yup'

import { PhotoGallery } from 'components/common/PhotoGallery'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { DraftModule } from 'modules/listing/draft'
import { getMedia } from 'utils/getMedia'
import { getSubmitMedia } from 'utils/getSubmitMedia'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)
const Root = styled.div(s('flex flex-column w-full mt-6'))
const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))

const { object, array } = Validator

const validationSchema = object({
  media: object({
    photos: array().required('Upload at least one photo'),
  }),
})

function MediaStep() {
  const { setFieldValue } = useFormContext()

  async function handleSubmit({ draftId, media }) {
    const draftListing = await DraftModule.read(draftId)

    const submitMediaList = getSubmitMedia(draftListing, media)

    await DraftModule.updateListingMedia(null, submitMediaList)

    const newDraftListing = await DraftModule.read(draftId)

    setFieldValue('media', getMedia(newDraftListing))
  }

  return (
    <Wizard.Step
      id="media"
      title="Media"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Scroll>
        <Root>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Label htmlFor="media.photos">Photos (max size: 6MB)</Label>
              <PhotoGallery name="media.photos" />
            </Col>
          </Row>
        </Root>
      </Scroll>
    </Wizard.Step>
  )
}

export { MediaStep }
