import { isNotNil, defaultTo } from '@soltalabs/ramda-extra'
import { startOfToday } from 'date-fns'
import { useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import Modal from 'react-modal'

import { Button } from 'components/common/Button'
import { DateField } from 'components/common/DateField'
import { Form } from 'components/common/Form'
import { SelectField } from 'components/common/SelectField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TimeField } from 'components/common/TimeField'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { EVENT_REOCCURRENCE, BOOKING_WINDOW } from 'modules/listing'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('bg-white rounded-lg p-6'))
const Title = styled.h2(s('text-lg font-semibold mb-2'))

const Forwarded = forwardRef(ReadEventModal)
const Memoized = deepMemo(Forwarded)

function ReadEventModal(_, ref) {
  const eventId = useRef()
  const fieldName = useRef()
  const listingName = useRef()
  const venueName = useRef()
  const selectedDate = useRef(startOfToday())
  const bookingWindowRef = useRef()
  const notesRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  function open({
    id,
    field,
    venueName: venue,
    listingTitle,
    startAt,
    bookingWindow,
    notes,
  }) {
    eventId.current = id
    fieldName.current = field
    venueName.current = venue
    listingName.current = listingTitle
    selectedDate.current = new Date(startAt)
    bookingWindowRef.current = bookingWindow
    notesRef.current = notes

    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      isOpen={isOpen}
    >
      <Form
        initialValues={{
          id: eventId.current,
          eventDate: selectedDate.current,
          startTime: selectedDate.current,
          reoccurrence: EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.NEVER),
          bookingWindow: defaultTo(BOOKING_WINDOW.valueOf(BOOKING_WINDOW.TWELVE_HOURS))(
            bookingWindowRef.current
          ),
          notes: defaultTo('')(notesRef.current),
        }}
      >
        <Container>
          <Row justify="center">
            <Col sm={18} md={16} lg={14} xl={12}>
              <Root>
                <Title>{listingName.current}</Title>
                <Title>{venueName.current}</Title>

                <Row gutter={[16, 16]} style={s('mt-4')}>
                  <Col span={12}>
                    <DateField
                      name="eventDate"
                      label="Event date"
                      autoOk
                      disablePast
                      disabled={true}
                      readOnly={true}
                    />
                  </Col>

                  <Col span={12}>
                    <TimeField name="startTime" label="Start time" readOnly={true} />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <SelectField
                      searchable={false}
                      items={BOOKING_WINDOW.values()}
                      itemToString={(item) =>
                        isNotNil(item)
                          ? BOOKING_WINDOW.propertiesOf(item).readableValue
                          : ''
                      }
                      name="bookingWindow"
                      label="Booking window"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextAreaField
                      minRows={3}
                      characterLimit={200}
                      name="notes"
                      label="Notes"
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row style={s('mt-6 mb-4')}>
                  <Col span={12}>
                    <Button
                      variant="secondary"
                      onClick={close}
                      style={s('bg-gray-200')}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </Root>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  )
}

export { Memoized as ReadEventModal }
