import { isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { TimePicker } from 'antd'
import { useField } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import 'antd/es/time-picker/style/css'

import { RequiredIndicator } from './RequiredIndicator'

import { ReactComponent as ClockIcon } from 'assets/feathers/clock.svg'
import { styled, s } from 'lib/styled'
import { setTimeComponent } from 'utils/date'

const Root = styled.div(s('block'))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(
  s('mb-2 uppercase tracking-wide text-xs text-gray-600 font-light')
)

const TimeFieldContainer = styled.div(
  s(
    'bg-gray-200 text-black border-0 border-b-2 border-solid border-gray-500 rounded-lg py-1'
  ),
  {
    '& .ant-picker': s('py-0 flex flex-row flex-1'),
    '& .ant-picker-input > input': s('text-black text-sm'),
    '& .ant-picker-dropdown': s('bg-gray-200'),
  },
  ({ readOnly }) =>
    readOnly
      ? s('bg-gray-400 border-gray-700', {
          '& .ant-picker-input > input': s('text-gray-800 text-sm', {
            cursor: readOnly && 'default',
          }),
        })
      : {}
)

const ClockIconContainer = styled.div(s('pt-1 text-grey-darker'))

const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

function TimeField({
  name,
  id,
  label,
  readOnly,
  labelPosition,
  containerProps,
  showRequiredIndicator = false,
}) {
  const [touch, setTouch] = useState(false)
  const [
    { value },
    { touched, error },
    { setValue: setFieldValue, setTouched: setFieldTouched },
  ] = useField({
    name,
    id,
  })

  function handleChange(date) {
    setFieldValue(setTimeComponent(date.toDate(), value))
  }

  useEffect(() => {
    if (touch) {
      setFieldTouched(true)
    }
  }, [touch])

  return (
    <Root {...containerProps}>
      <InputContainer labelPosition={labelPosition}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={name || id}
          labelPosition={labelPosition}
        >
          {label}
          <RequiredIndicator showRequiredIndicator={showRequiredIndicator} />
        </Label>
        <TimeFieldContainer readOnly={readOnly}>
          <TimePicker
            onChange={handleChange}
            defaultValue={moment(value)}
            format="h:mm a"
            allowClear={false}
            inputReadOnly
            bordered={false}
            showNow={false}
            minuteStep={15}
            disabled={readOnly}
            onOpenChange={(open) => {
              if (!open) {
                setTouch(true)
              }
            }}
            suffixIcon={
              !readOnly && (
                <ClockIconContainer>
                  <ClockIcon width={18} height={18} stroke="#686868" />
                </ClockIconContainer>
              )
            }
          />
        </TimeFieldContainer>
      </InputContainer>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { TimeField }
