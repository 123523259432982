import QueryString from 'query-string'
import { useLocation } from 'react-router-dom'

function useQueryParams() {
  const location = useLocation()

  return QueryString.parse(location.search)
}

export { useQueryParams }
