import { useIsOpen, Trigger } from '@accessible/accordion'

import { ReactComponent as ChevronDownIcon } from 'assets/feathers/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/feathers/chevron-up.svg'
import { CheckboxField } from 'components/common/CheckboxField'
import { styled, s } from 'lib/styled'
import { VENUE_TYPES } from 'modules/venue'

const Header = styled.div(
  s('flex flex-row pl-2 pr-2 py-1 items-center rounded-lg'),
  { transition: 'background-color 0.2s, border-radius 0.25s' },
  ({ isSelected }) =>
    isSelected
      ? {
          '&:hover': s('bg-gray-300', { cursor: 'pointer' }),
        }
      : {},
  ({ isOpen }) => (isOpen ? s('rounded-b-none') : {})
)
const IconAligner = styled.div(s('flex-1 flex flex-row items-center h-2 pl-1'))
const Title = styled.h2(s('text-base font-semibold m-0'), ({ isSelected }) =>
  !isSelected ? s('text-gray-600') : s('text-gray-900')
)
const Type = styled.span(
  s(
    'ml-4 rounded-full px-3 py-1 text-xs uppercase tracking-wide font-semibold text-gray-900'
  ),
  { transition: 'background-color 0.2s, color 0.2s' },
  ({ isSelected }) =>
    s('text-black', {
      backgroundColor: `#C0C0C0${!isSelected ? '77' : ''}`,
    })
)

function AccordionHeader({ index, title, type, isSelected, isEditing, hasOrders }) {
  const isSectionOpen = useIsOpen()

  const Icon = isSectionOpen ? ChevronUpIcon : ChevronDownIcon

  const { readableValue: readableType } = VENUE_TYPES.propertiesOf(type)

  return (
    <Header isSelected={isSelected} isOpen={isSectionOpen}>
      <CheckboxField
        name={`availabilities[${index}].isSelected`}
        disabled={!isEditing || hasOrders}
      />

      <Trigger>
        <IconAligner>
          <Title isSelected={isSelected}>{title}</Title>
          <Type isSelected={isSelected}>{readableType}</Type>

          {isSelected && <Icon width={20} height={20} style={s('ml-a')} />}
        </IconAligner>
      </Trigger>
    </Header>
  )
}

export { AccordionHeader }
