import moment from 'moment'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'

import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

function BigCalendar({ ...props }) {
  return (
    <Calendar
      localizer={localizer}
      defaultView={Views.MONTH}
      startAccessor="start"
      endAccessor="end"
      events={[]}
      popup
      {...props}
    />
  )
}

export { BigCalendar, Views }
