import { equals, pickThenRename, isNotNil } from '@soltalabs/ramda-extra'
import { format, startOfToday } from 'date-fns'
import RRule from 'rrule'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { authModule } from 'modules/auth'
import { EVENT_REOCCURRENCE } from 'modules/listing/constants'
import { wallet } from 'wallet'

const service = http.extend({
  prefixUrl: CONFIG.API.URL,
  hooks: {
    beforeRequest: [
      async (request, options) => {
        const skipAuthorization = equals(false, options.authorize)
        if (skipAuthorization) {
          return
        }

        const token = await wallet.getToken()
        const { currentProvider } = authModule.getState()

        request.headers.set('Authorization', `Bearer ${token}`)
        request.headers.set('playz-provider', currentProvider)

        // eslint-disable-next-line
        delete options.authorize
      },
    ],
  },
})

const AvailabilityService = {
  async list() {
    const searchParams = {
      limit: 999,
    }

    const config = { searchParams }

    return service.get('availabilities', config).json()
  },

  async read(listingId) {
    const searchParams = {
      listing: listingId,
    }

    const config = { searchParams }
    return service.get('availabilities', config).json()
  },

  async create(data) {
    const payload = pickThenRename(
      ['listingId', 'venueId', 'totalSpots', 'contact'],
      { listingId: 'listing', venueId: 'venue', totalSpots: 'spots' },
      data
    )

    const config = { json: payload }

    return service.post('availabilities', config).json()
  },

  async createPricingOption(availabilityId, data) {
    const payload = pickThenRename(
      ['name', 'description', 'spots', 'price'],
      { spots: 'occupiedSpots' },
      data
    )

    const config = { json: payload }

    return service.post(`availabilities/${availabilityId}/pricings`, config).json()
  },

  async createEvent(
    availabilityId,
    { startDate, recurrence, recurrenceEnd, bookingWindow, notes }
  ) {
    const { rruleConfig } = EVENT_REOCCURRENCE.propertiesOf(recurrence)
    const isRecurring =
      isNotNil(recurrence) &&
      EVENT_REOCCURRENCE.symbolOf(recurrence) !== EVENT_REOCCURRENCE.NEVER

    const rrule = new RRule({
      ...rruleConfig,
      dtstart: startDate,
      until: isRecurring ? recurrenceEnd : startDate,
      wkst: RRule.SU,
    })

    const payload = {
      availability: availabilityId,
      schedule: rrule.toString(),
      bookingOffsetInMinutes: bookingWindow,
      note: notes,
    }

    const config = { json: payload }

    return service.post('listing-events', config).json()
  },

  async updateEvent({ id, timespanInMinutes, bookingWindow, notes }) {
    const payload = {
      timespanInMinutes,
      bookingOffsetInMinutes: bookingWindow,
      note: notes,
    }

    const config = { json: payload }

    return service.patch(`listing-events/${id}`, config).json()
  },

  async createCommunityEvents(
    availabilityId,
    { noDateRange, startDate, endDate, note }
  ) {
    const payload = {
      availability: availabilityId,
      startDate: noDateRange
        ? format(startOfToday(), 'yyyy-MM-dd')
        : format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: noDateRange ? undefined : format(new Date(endDate), 'yyyy-MM-dd'),
      note,
    }

    const config = { json: payload }

    return service.post('community-listing-events', config).json()
  },

  async deleteCommunityEvents(serieId) {
    const searchParams = {
      serie: serieId,
    }

    const config = { searchParams }

    return service.delete('listing-events', config).json()
  },

  async update(data) {
    const { availabilityId } = data

    const payload = pickThenRename(
      ['totalSpots', 'contact'],
      { totalSpots: 'spots' },
      data
    )

    const config = { json: payload }

    return service.patch(`availabilities/${availabilityId}`, config).json()
  },

  async delete(availabilityId) {
    return service.delete(`availabilities/${availabilityId}`).json()
  },

  async deletePricings(availabilityId) {
    return service.delete(`availabilities/${availabilityId}/pricings`).json()
  },
}

export { AvailabilityService }
