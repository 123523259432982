import { pipe, pickThenRename } from '@soltalabs/ramda-extra'

import { http } from 'httpClient'
import { omitNilOrEmpty } from 'utils/ramda'

const pickDraftProps = pipe(
  pickThenRename(
    [
      'listingType',
      'title',
      'summary',
      'description',
      'categories',
      'keywords',
      'needToBring',
      'whatToWear',
      'genders',
    ],
    {
      needToBring: 'itemsToBring',
      whatToWear: 'itemsToWear',
    }
  ),
  omitNilOrEmpty
)

const DraftService = {
  async create(data) {
    const payload = pickDraftProps(data)
    const config = { json: payload }

    return http.post('listings', config).json()
  },

  async publish(id) {
    try {
      const res = await http.post(`listings/${id}/public`).json()
      return res
    } catch (e) {
      const eres = await e.response.json()
      return eres
    }
  },
}

export { DraftService }
