import { styled, s } from 'lib/styled'

const Required = styled.span(s('text-red font-bold'), {
  marginLeft: '2px',
})

function RequiredIndicator({ showRequiredIndicator = false }) {
  if (showRequiredIndicator) {
    return <Required>*</Required>
  }
  return null
}

export { RequiredIndicator }
