import 'whatwg-fetch'
import ky from 'ky'

const http = ky.create({
  headers: {
    'content-type': 'application/json',
  },
})

export { http }
