import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { productModule } from './module'

const { state$, effectState$ } = productModule

const productEntities$ = select(state$, (state) => prop('entities', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const filterStatus$ = select(state$, (state) => prop('statuses', state))

const paging$ = select(state$, (state) => prop('paging', state))

const order$ = select(state$, (state) => prop('order', state))

const limit$ = select(state$, (state) => prop('limit', state))

const productList$ = select(order$, productEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const inspectedProduct$ = select(inspectedEntity$, productEntities$, (id, entities) =>
  prop(id, entities)
)

const inspectProductStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectProduct', inspectedEntity, 'status'], effectState)
)

const isInspectingProduct$ = select(
  inspectProductStatus$,
  (status) => status === 'pending'
)

export {
  productList$,
  filterQuery$,
  filterStatus$,
  paging$,
  inspectedProduct$,
  isInspectingProduct$,
  limit$,
}
