import { pick } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { TimezoneField } from './TimezoneField'

import { ReactComponent as AlertIcon } from 'assets/feathers/alert-triangle.svg'
import { AddressField } from 'components/common/AddressField'
import { Button } from 'components/common/Button'
import { CheckboxField } from 'components/common/CheckboxField'
import { Form } from 'components/common/Form'
import { RadioButtonField } from 'components/common/RadioButtonField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TextField } from 'components/common/TextField'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { timezones$ } from 'modules/operatingSystem'
import { currentProviderTimezone$ } from 'modules/provider'
import { VENUE_TYPES, venueModule } from 'modules/venue'

const { string, object, boolean } = Validator

const validationSchema = Validator.object().shape({
  name: string()
    .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
    .required('This field is required'),
  type: string().oneOf(VENUE_TYPES.values()),
  address: object().required('Not a valid address'),
  hasWheelchairAccess: boolean(),
  transportNotes: string().nullable().optional(),
  parkingNotes: string().nullable().optional(),
  timezone: string(),
})

const Root = styled.div(s('bg-white rounded-lg p-6'))
const Title = styled.h2(s('text-lg font-semibold mb-6'))
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)

const MessageBox = styled.div(
  s(
    'w-full flex flex-row p-3 items-center rounded-lg border-0 border-l-4 border-solid border-warning mb-6 text-sm'
  ),
  { backgroundColor: '#F9F6ED' }
)
const Message = styled.span(s('ml-3 text-black text-sm'), { lineHeight: 1.25 })

const Scrollable = styled.div({ maxHeight: '70vh', overflow: 'auto' })

const ConnectedCreateVenueModal = connect(
  () => ({
    currentProviderTimezone: currentProviderTimezone$,
    timezones: timezones$,
  }),
  { forwardRef: true }
)(forwardRef(CreateVenueModal))

function CreateVenueModal({ currentProviderTimezone, newVenues, setNewVenues }, ref) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  async function handleSubmit(formValues) {
    const venue = await venueModule.createPhysicalVenue(
      null,
      pick(
        [
          'name',
          'type',
          'address',
          'timezone',
          'transportNotes',
          'parkingNotes',
          'hasWheelchairAccess',
        ],
        formValues
      )
    )
    setNewVenues([...newVenues, venue])
    close()
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      isOpen={isOpen}
    >
      <Form
        initialValues={{
          name: '',
          type: VENUE_TYPES.valueOf(VENUE_TYPES.FIXED),
          address: undefined,
          hasWheelchairAccess: false,
          transportNotes: undefined,
          parkingNotes: undefined,
          timezone: currentProviderTimezone,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Container>
          <Row justify="center">
            <Col sm={18} md={16} lg={14} xl={12}>
              <Root>
                <Title>Add new venue</Title>

                <Scrollable>
                  <MessageBox>
                    <AlertIcon
                      stroke="#FAD7A6"
                      strokeWidth="2px"
                      style={{ minWidth: 22 }}
                    />

                    <Message>
                      You cannot change a venue&apos;s type after creating it. Ensure
                      that you have selected the correct venue type before continuing.
                    </Message>
                  </MessageBox>

                  <Label>Type</Label>
                  <Row gutter={[8, 16]}>
                    <Col>
                      <RadioButtonField
                        name="type"
                        value={VENUE_TYPES.valueOf(VENUE_TYPES.FIXED)}
                        label={
                          VENUE_TYPES.propertiesOf(VENUE_TYPES.FIXED).readableValue
                        }
                      />
                    </Col>

                    <Col>
                      <RadioButtonField
                        name="type"
                        value={VENUE_TYPES.valueOf(VENUE_TYPES.MOBILE)}
                        label={
                          VENUE_TYPES.propertiesOf(VENUE_TYPES.MOBILE).readableValue
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <TextField characterLimit={150} name="name" label="Name" />
                    </Col>
                  </Row>

                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <AddressField name="address" label="Address" />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <CheckboxField
                        name="hasWheelchairAccess"
                        label="Wheelchair access"
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <TimezoneField />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <TextAreaField
                        name="transportNotes"
                        label="Transport Notes"
                        minRows={3}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <TextAreaField
                        name="parkingNotes"
                        label="Parking Notes"
                        minRows={3}
                      />
                    </Col>
                  </Row>
                </Scrollable>

                <Row style={s('mt-6')}>
                  <Col span={12}>
                    <Button
                      variant="secondary"
                      onClick={close}
                      style={s('bg-gray-100')}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col span={12} style={s('flex flex-row-reverse')}>
                    <Button type="submit">Add</Button>
                  </Col>
                </Row>
              </Root>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  )
}

export { ConnectedCreateVenueModal as CreateVenueModal }
