import CONSTANTS from '@solta/constants'
import { reduce, prop, isNotNilOrEmpty, isNil } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import { useEffect, useState } from 'react'
import * as Validator from 'yup'

import { OnlineActivityForm } from 'components/listings/common/OnlineActivityForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { Wizard } from 'lib/formik-wizard'
import { useWizard } from 'lib/formik-wizard/context'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'
import { DraftModule } from 'modules/listing/draft'
import {
  getOnlineAvailability,
  getInitialOnlineActivityObject,
} from 'utils/getOnlineAvailability'
import { getOnlineVenueError } from 'utils/getOnlineVenueError'

const { object, string, boolean, number, array, ref } = Validator

const pricingOptionSchema = object({
  hasLimitedSpots: boolean(),
  totalSpots: number()
    .min(0, 'Must be a positive number')
    .max(9999, 'Must be less than 9999')
    .optional(),

  options: array()
    .required('Must have at least one pricing')
    .when('hasLimitedSpots', {
      is: true,
      then: array().test({
        name: "Spots don't exceed limit",
        message:
          'Total spot allocation exceeded. Please re-check your pricing options.',
        test(options) {
          const totalSpots = this.resolve(ref('totalSpots'))
          const allocatedSpots = reduce((acc, option) => acc + option.spots, 0, options)
          const hasAllocatedExcessSpots = allocatedSpots > totalSpots

          return !hasAllocatedExcessSpots
        },
      }),
    })
    .of(
      object({
        name: string()
          .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
          .required('This field is required'),
        description: string()
          .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
          .optional(),
        spots: number().min(1, 'Must be at least 1').required('This field is required'),
        price: number().required('This field is required'),
      })
    ),
})

const URLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const onlineActivitySchema = Validator.object({
  onlineActivity: object({
    conference: object().shape({
      link: string()
        .matches(URLRegex, 'Not a valid URL')
        .required('This field is required'),
      passcode: string().optional(),
      timezone: string(),
    }),

    contact: object({
      name: string().required('This field is required'),
      phoneNumber: string()
        .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
        .required('This field is required'),
      email: string()
        .email('Not a valid email address')
        .required('This field is required'),
    }),

    pricing: pricingOptionSchema,

    events: array().when('hasExistingEvent', {
      is: false,
      then: array().required('Must select a Date & Time'),
    }),
  }),
})

const Root = styled.div(s('flex-1 flex flex-column mt-6'), { overflow: 'hidden' })

const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)

function OnlineVenue({ provider }) {
  const { values, errors, submitCount, setFieldValue } = useFormContext()
  const { setSubmitContext, currentStep } = useWizard()

  const { listingType } = values

  const isOnVenueStep = prop('id')(currentStep) === 'venues'
  const [initialSubmitCount, setInitialSubmitCount] = useState(0)

  const hasAttemptedSubmit = submitCount > initialSubmitCount

  useEffect(() => {
    if (isOnVenueStep) {
      setInitialSubmitCount(submitCount)
    }
  }, [isOnVenueStep])

  useEffect(() => {
    if (!isOnVenueStep) {
      setSubmitContext({})
      return
    }

    const errorMessage = getOnlineVenueError(errors)

    if (isNil(errorMessage)) {
      setSubmitContext({})
    }

    if (hasAttemptedSubmit && errorMessage) {
      setSubmitContext({
        status: 'error',
        error: new Error(errorMessage),
      })
    }
  }, [errors, hasAttemptedSubmit, isOnVenueStep])

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)) {
    return null
  }

  async function handleSubmit({ draftId, currentProviderTimezone, onlineActivity }) {
    await DraftModule.createOnlineAvailability(null, onlineActivity)

    const newDraftListing = await DraftModule.read(draftId)

    const availabilities = prop('availabilities')(newDraftListing)

    const newOnlineActivity = isNotNilOrEmpty(availabilities)
      ? getOnlineAvailability(availabilities)
      : getInitialOnlineActivityObject(provider, currentProviderTimezone)

    setFieldValue('onlineActivity', newOnlineActivity)
  }

  return (
    <Wizard.Step
      id="venues"
      title="Venues"
      validationSchema={onlineActivitySchema}
      onSubmit={handleSubmit}
    >
      <Root>
        <Label>Online activity</Label>
        <OnlineActivityForm />
      </Root>
    </Wizard.Step>
  )
}

export { OnlineVenue }
