import { isNil } from '@soltalabs/ramda-extra'

import { ReactComponent as Icon } from 'assets/feathers/search.svg'
import { styled, s } from 'lib/styled'

const Container = styled.div(
  s(
    'flex flex-row items-center px-3 py-1 border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-base'
  )
)
const SearchIcon = styled(Icon)(s('mr-2'))
const Input = styled.input(
  s('w-full mr-2 m-0 p-0 border-0 bg-transparent font-normal text-sm text-black', {
    '&::placeholder': s('text-gray-700'),
  })
)

function SearchInput({
  value,
  onChange,
  placeholder,
  inputProps = {},
  containerProps = {},
}) {
  function handleChange(event) {
    if (isNil(onChange)) {
      return
    }
    onChange(event.target.value)
  }

  return (
    <Container {...containerProps}>
      <SearchIcon width={17} height={17} strokeWidth="2.5px" stroke="#686868" />

      <Input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        {...inputProps}
      />
    </Container>
  )
}

export { SearchInput }
