import { SearchInput } from 'components/common/SearchInput'
import { DateRangePicker } from 'components/common/withTable/DateRangePicker'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-grey-lighter'
  ),
  {
    'z-index': 2,
  }
)

function Filters({ filterQuery, filterOrders, dateRange, filterDate }) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterOrders}
        placeholder="Search by id or client"
      />
      <DateRangePicker
        style={s('ml-3')}
        dateRange={dateRange}
        filterDate={filterDate}
      />
    </Root>
  )
}

export { Filters }
