import { isNotNil, isNotEmpty, head } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { ListingEventService } from './service'

import { getStartOfMonth, getEndOfMonth } from 'utils/getDate'

const INITIAL_STATE = Object.freeze({
  entities: '',
  order: [],
  inspectedEntity: undefined,
  dateRange: {
    from: getStartOfMonth(),
    to: getEndOfMonth(),
  },
})

const fetchEvents = (module) => async () => {
  const { dateRange } = module.getState()
  const { from, to } = dateRange

  const { entities, order } = await ListingEventService.list({
    from,
    to,
  })

  if (isNotEmpty(order)) {
    await module.inspectEvent(head(order))
  }

  module.setState({
    entities,
    order,
  })
}

const inspectEvent = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const event = await ListingEventService.read(id)

  module.setState({
    entities: { [id]: event },
  })
}

const filterDate =
  (module) =>
  ({ from, to }) => {
    module.setState({
      dateRange: {
        from,
        to,
      },
    })
    if (isNotNil(from) && isNotNil(to)) {
      module.fetchEvents()
    }
  }

const listingEventModule = createModule({
  name: 'listingEvent',
  initialState: INITIAL_STATE,
  decorators: {
    fetchEvents,
    inspectEvent,
    filterDate,
  },
})

export { listingEventModule }
