const PROVIDER_TYPES = {
  STANDARD: 'provider_standard',
  PREMIUM: 'provider_premium',
}

const PROVIDER_STATUS = {
  ONBOARDING: 'provider_status_onboarding',
  ACTIVE: 'provider_status_active',
  SUSPENDED: 'provider_status_suspended',
  BANNED: 'provider_status_banned',
}

export { PROVIDER_TYPES, PROVIDER_STATUS }
