import { prop } from '@soltalabs/ramda-extra'
import { addDays, addWeeks, addMonths } from 'date-fns'
import { useFormikContext as useFormContext } from 'formik'
import { useEffect, useState } from 'react'

import { DateField } from 'components/common/DateField'
import { EVENT_REOCCURRENCE } from 'modules/listing'

const addFortnight = (date) => addWeeks(date, 2)

const reoccurrenceMapper = {
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.DAILY)]: addDays,
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.WEEKLY)]: addWeeks,
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.FORTNIGHTLY)]: addFortnight,
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.MONTHLY)]: addMonths,
}

function RecurrenceEndField({ label, name, ...props }) {
  const { values, setFieldValue } = useFormContext()
  const [minDate, setMinDate] = useState(undefined)

  const eventDate = prop('eventDate')(values)
  const reoccurrence = prop('reoccurrence')(values)

  const hasNoReoccurrence =
    reoccurrence === EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.NEVER)

  useEffect(() => {
    if (hasNoReoccurrence) {
      return
    }

    const addDaysFunc = reoccurrenceMapper[reoccurrence]

    setMinDate(addDaysFunc(new Date(eventDate), 1))

    setFieldValue('reoccurrenceEnd', addDaysFunc(new Date(eventDate), 1))
  }, [eventDate, reoccurrence])

  return (
    <DateField
      disabled={hasNoReoccurrence}
      disablePast
      autoOk
      name={name}
      label={label}
      minDate={minDate}
      {...props}
    />
  )
}

export { RecurrenceEndField }
