import './globals'
import { StylesProvider } from '@material-ui/core/styles'
import { LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { ThemeProvider } from 'emotion-theming'
import { createBrowserHistory as createHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from 'App'
import { WalletProvider } from 'lib/wallet-react'
import { setupLogger } from 'logging'
import { breakpoints } from 'styles/breakpoints'
import { wallet } from 'wallet'

const history = createHistory()

function onRedirectCallback(appState) {
  history.push(
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  )
}

setupLogger()

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <WalletProvider instance={wallet} onRedirectCallback={onRedirectCallback}>
        <ThemeProvider theme={{ breakpoints }}>
          <StylesProvider injectFirst>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <App />
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </WalletProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
