import { memo } from 'react'

import { WizardNavigation } from 'components/common/WizardNavigation'

function OnboardingNavigation({ ...props }) {
  return <WizardNavigation {...props} />
}

const Memoized = memo(OnboardingNavigation)

export { Memoized as OnboardingNavigation }
