import { http } from 'httpClient'

const CategoryService = {
  async listAll() {
    const { items } = await http.get('categories/all').json()
    return items
  },
}

export { CategoryService }
