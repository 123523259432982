import { useFormikContext } from 'formik'

import { TextField } from '../../common/TextField'

import { Button } from 'components/common/Button'
import { PhoneField, NUMBER_FORMATS } from 'components/common/PhoneField'
import { Spinner } from 'components/common/Spinner'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.section(
  s('p-4 border-0 border-b-1 border-solid border-grey-lighter my-4')
)
const Title = styled.span(s('tracking-wide text-black text-lg'))
const EditButton = styled(Button)(s('w-5'))

function AccountDetails({
  onClick,
  isEditing,
  setIsEditing,
  isUpdatingProvider,
  isUpdatingAccount,
}) {
  const { resetForm } = useFormikContext()
  const isUpdating = isUpdatingProvider || isUpdatingAccount

  return (
    <Root key={isEditing}>
      <Row>
        <Col span={3}>
          <Title>My Account</Title>
        </Col>

        <Col span={21}>
          <Row style={s('justify-end')}>
            {isUpdating && (
              <Spinner style={s('mr-2')} size={10} thickness={3} color="#FFFFFF" />
            )}
            {isEditing || isUpdating ? (
              <div>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm()
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>
                <EditButton type="submit">Save</EditButton>
              </div>
            ) : (
              <EditButton onClick={() => onClick()}>Edit</EditButton>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="firstName"
                type="text"
                label="First name"
              />
            </Col>

            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="lastName"
                type="text"
                label="Last name"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <PhoneField
                readOnly={!isEditing}
                name="mobile"
                label="Phone Number (*)"
                placeholderNumberType={NUMBER_FORMATS.FIXED_LINE_OR_MOBILE}
              />
            </Col>

            <Col span={12}>
              <TextField readOnly={true} name="email" type="text" label="E-mail" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { AccountDetails }
