import { isEmpty, isNil, isNotNil, isNotEmpty } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useCallback, useState, useEffect } from 'react'

import { ReactComponent as ImageIcon } from 'assets/feathers/image.svg'
import { Spinner } from 'components/common/Spinner'
import { styled, s } from 'lib/styled'
import { providerModule, isUploadingLogo$, uploadedLogoId$ } from 'modules/provider'
import { useFileUpload } from 'utils/hooks'

const Container = styled.div(
  s(
    'relative flex flex-column items-center justify-center w-12 h-12 bg-gray-200 border-2 border-dashed border-gray-500'
  ),
  {
    cursor: 'pointer',
    overflow: 'hidden',
  },
  ({ hideBorder }) => (hideBorder ? s('border-0') : {})
)
const Avatar = styled.img(s('max-h-full max-w-full'), { objectFit: 'contain' })
const OpaqueOverlay = styled.div(
  s('absolute h-full w-full flex items-center justify-center bg-black'),
  {
    backgroundColor: '#00000088',
  }
)

const ReuploadOverlay = styled(OpaqueOverlay)(s('bg-black text-white text-semibold'), {
  opacity: 0,
  '&:hover': { opacity: 1, backgroundColor: '#00000088' },
})

const ConnectedLogoUpload = connect(() => ({
  isUploadingLogo: isUploadingLogo$,
  uploadedLogoId: uploadedLogoId$,
}))(LogoUpload)

// eslint-disable-next-line complexity
function LogoUpload({ isUploadingLogo, uploadedLogoId, logo, ...props }) {
  const { getRootProps, getInputProps, acceptedFiles } = useFileUpload({
    accept: 'image/*',
    noKeyboard: true,
    onDropAccepted: useCallback(upload),
  })
  const [preview, setPreview] = useState(logo)

  function upload([file]) {
    providerModule.uploadLogo(null, file)
  }

  useEffect(() => {
    if (!isUploadingLogo && isNotNil(uploadedLogoId) && isNotEmpty(acceptedFiles)) {
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }

    return () => URL.revokeObjectURL(preview)
  }, [isUploadingLogo, uploadedLogoId, acceptedFiles])

  return (
    <Container
      {...getRootProps()}
      hideBorder={isUploadingLogo || isNotNil(preview)}
      {...props}
    >
      {isUploadingLogo && (
        <OpaqueOverlay>
          <Spinner size={36} thickness={4} />
        </OpaqueOverlay>
      )}

      {isNil(preview) && isEmpty(acceptedFiles) && (
        <ImageIcon width={96} height={96} stroke="#E0E1E0" />
      )}

      {isNotNil(preview) && (
        <>
          <Avatar src={preview} />

          <ReuploadOverlay>
            <span>Upload new logo</span>
          </ReuploadOverlay>
        </>
      )}

      <input name="logo" {...getInputProps()} />
    </Container>
  )
}

export { ConnectedLogoUpload as LogoUpload }
