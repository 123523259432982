import { connect } from '@soltalabs/stateless'
import { useField, Field } from 'formik'
import { useEffect } from 'react'

import { styled, s } from 'lib/styled'
import { allCategoryEntities$, categoryModule } from 'modules/category'

const Root = styled.div(s('flex flex-column flex-wrap'))
const InputContainer = styled.div(s('flex flex-row mt-2 flex-wrap'))
const Title = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))
const Label = styled.label(
  s(
    'w-16 text-center bg-gray-200 text-sm text-black border-0 border-solid border-b-2 border-gray-500 rounded-lg pl-5 pr-2 py-4 mr-6 flex flex-row justify-center mb-4'
  ),
  ({ readOnly }) => (readOnly ? s('text-gray-700 border-gray-200') : {})
)
const Input = styled(Field)({ opacity: 0 })
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const { fetchAllCategories } = categoryModule

const ConnectedCategories = connect(() => ({
  categories: allCategoryEntities$,
}))(Categories)

function Categories({
  name,
  id,
  label,
  className,
  containerProps,
  readOnly,
  categories,
  ...props
}) {
  const [fieldProps, { touched, error }] = useField({ name, id, ...props })
  const { value: fieldValue } = fieldProps

  useEffect(() => {
    fetchAllCategories()
  }, [])

  return (
    <Root className={className} {...containerProps}>
      <Title htmlFor={name || id}>{label}</Title>
      <InputContainer>
        {categories?.map(({ name: category }) => (
          <Label
            key={category}
            style={
              // eslint-disable-next-line no-nested-ternary
              fieldValue.includes(category)
                ? readOnly
                  ? s('border-primary-lightest bg-primary-lightest')
                  : s('border-primary-lighter bg-primary-lightest')
                : null
            }
            readOnly={readOnly}
          >
            {category}
            <Input
              type="checkbox"
              name={name}
              value={category}
              disabled={readOnly}
              {...props}
            />
          </Label>
        ))}
      </InputContainer>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { ConnectedCategories as Categories }
