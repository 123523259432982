import { prop } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { operatingSystemModule } from './module'

const { state$ } = operatingSystemModule

const timezones$ = select(state$, prop('timezones'))

export { timezones$ }
