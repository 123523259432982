import { Paragraph } from './Paragraph'

function ListingPublishedPrompt() {
  return (
    <>
      <Paragraph>Your listing has been published!</Paragraph>
      <Paragraph>Thank you for being a part of the Bula Platform!</Paragraph>
    </>
  )
}

export { ListingPublishedPrompt }
