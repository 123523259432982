import { values, equals, isNil, prop, match } from '@soltalabs/ramda-extra'
import { useState, useLayoutEffect, useCallback } from 'react'
import { Link as LinkBase, useLocation } from 'react-router-dom'

import { NavigationBar } from './NavigationBar'

import { ReactComponent as OrdersIcon } from 'assets/feathers/file-text.svg'
import { ReactComponent as LogoutIcon } from 'assets/feathers/logout.svg'
import { ReactComponent as SettingsIcon } from 'assets/feathers/settings.svg'
import { ReactComponent as ShoppingBagIcon } from 'assets/feathers/shopping-bag.svg'
import { ReactComponent as ProductsIcon } from 'assets/feathers/shopping-cart.svg'
import { ReactComponent as ProfileIcon } from 'assets/feathers/user.svg'
import { styled, s } from 'lib/styled'
import { useWallet } from 'lib/wallet-react'

const Container = styled.div(s('flex-1 flex flex-row h-full', { overflow: 'hidden' }))
const SideBar = styled.div(
  s(
    'relative z-1 bg-white flex flex-column border-0 border-r-1 border-solid border-grey-light',
    {
      overflow: 'hidden',
      transition: '0.25s width ease, 0.25s box-shadow ease',
    }
  ),
  ({ isOpen }) => ({
    width: isOpen ? 226 : 0,
    boxShadow: isOpen ? s('shadow').boxShadow : 'none',
  })
)
const Branding = styled.div(
  s(
    'flex items-center justify-center border-0 py-4 border-b-1 border-solid border-grey-light'
  )
)
const Logo = styled.img(s('h-3'))

const Link = styled(LinkBase)(
  s('text-black tracking-wide flex flex-row items-center text-sm font-medium p-4', {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textDecoration: 'none',
    '&:hover': s('text-black'),
  }),
  ({ path, currentPath }) =>
    equals(path, currentPath)
      ? s(
          'text-black bg-primary-lighter border-solid border-0 border-l-4 border-primary-dark',
          {
            '& svg': s('text-primary-dark'),
          }
        )
      : s('border-solid border-0 border-l-4 border-white', {
          '& svg': s('text-gray-700'),
          '&:hover': s('bg-gray-200 border-gray-600'),
        })
)

const LogoutContainer = styled.div(
  s('flex mt-a py-1 border-0 border-t-1 border-solid border-grey-light')
)

const LogoutButton = styled.button(
  s(
    'flex flex-row flex-1 tracking-wide p-4 text-sm font-medium text-black bg-none border-solid border-0 border-l-4 border-white',
    {
      '& svg': s('text-gray-700'),
      '&:hover': s('bg-gray-200 border-gray-600'),
      '&:active': s('bg-primary-lightest border-primary', {
        '& svg': s('text-primary'),
      }),
    }
  )
)

const Content = styled.div(
  s('flex-1 flex flex-column', {
    '& > *': {
      overflow: 'hidden',
    },
  }),
  ({ isOpen }) => ({
    width: isOpen ? 'calc(100% - 226px)' : '100%',
  })
)

const routes = {
  listings: {
    key: 'listings',
    title: 'Listings',
    path: '/listings',
    Icon: ShoppingBagIcon,
  },
  products: {
    key: 'products',
    title: 'Products',
    path: '/products',
    Icon: ProductsIcon,
  },
  orders: {
    key: 'orders',
    title: 'Orders',
    path: '/orders',
    Icon: OrdersIcon,
  },
  profile: {
    key: 'profile',
    title: 'Profile',
    path: '/profile',
    Icon: ProfileIcon,
  },
  settings: {
    key: 'settings',
    title: 'Settings',
    path: '/settings',
    Icon: SettingsIcon,
  },
}

function withNavigation(WrappedComponent) {
  return function NavigationComponent({ fullName, avatar, ...ownProps }) {
    const [showSidebar, setShowSidebar] = useState(true)
    const [currentRoute, setCurrentRoute] = useState(routes.listings)
    const location = useLocation()

    const { isLoading, logout } = useWallet()

    const currentPath = prop('path')(currentRoute)

    useLayoutEffect(() => {
      const newCurrentRoute = values(routes).find((route) => {
        const matches = match(route.path, location.pathname)

        if (matches.length === 0) {
          return null
        }

        return matches[0]
      })

      if (isNil(newCurrentRoute)) {
        return
      }

      setCurrentRoute(newCurrentRoute)
    }, [routes, location])

    const handleSidebarToggle = useCallback(() => {
      setShowSidebar(!showSidebar)
    })

    return (
      <Container>
        <SideBar isOpen={showSidebar}>
          <Branding>
            <LinkBase to="/">
              <Logo src="/logo.png" />
            </LinkBase>
          </Branding>

          {values(routes).map(({ key, path, title, Icon }) => (
            <Link key={key} to={path} path={path} currentPath={currentPath}>
              <Icon width={18} height={18} strokeWidth={2} style={s('mr-2')} />
              {title}
            </Link>
          ))}

          <LogoutContainer>
            <LogoutButton disabled={isLoading} onClick={logout}>
              <LogoutIcon width={18} height={18} strokeWidth={2} style={s('mr-2')} />
              Logout
            </LogoutButton>
          </LogoutContainer>
        </SideBar>

        <Content isOpen={showSidebar}>
          <NavigationBar
            title={currentRoute.title}
            showSidebar={showSidebar}
            onSidebarToggle={handleSidebarToggle}
            fullName={fullName}
            avatar={avatar}
          />

          <WrappedComponent {...ownProps} />
        </Content>
      </Container>
    )
  }
}

export { withNavigation }
