import { omit } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { Details } from '../common/ProductForms/Details'

import { TabEditing } from 'components/common/TabEditing'
import { s } from 'lib/styled'
import { productModule } from 'modules/product'
import { convertToHtml } from 'utils/convertSlateData'
import { deepMemo } from 'utils/react'

const { object, string, number, array } = Validator

const validationSchema = Validator.object({
  details: object({
    name: string().required('This field is required'),
    status: string(),
    description: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    summary: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    price: number().required('This field is required'),
    categories: array().required('Please select at least one category'),
    keywords: array(),
    address: object(),
    location: object().required('A product must have a location'),
  }),
})

const Memoized = deepMemo(DetailsTab)

function DetailsTab({
  inspectedProduct,
  initialValues,
  isEditing,
  setIsEditing,
  tabTitle,
  currentTab,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)
  const { id } = inspectedProduct

  if (currentTab !== tabTitle) {
    return null
  }

  async function handleSubmit(values) {
    const submitValues = omit(['description', 'summary'], values.details)

    submitValues.description = convertToHtml({
      children: values.details.description.text,
    })

    submitValues.summary = convertToHtml({
      children: values.details.summary.text,
    })

    submitValues.status = values.details.status.value

    await productModule.updateProduct(null, id, submitValues)

    setIsEditing(false)
  }

  return (
    <Formik
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form style={s('flex flex-column', { overflow: 'hidden' })} key={isEditing}>
        <Details isEditing={isEditing} />
        <TabEditing
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCanceled={isCanceled}
          setIsCanceled={setIsCanceled}
          disabled={isEditingDisabled}
        />
      </Form>
    </Formik>
  )
}

export { Memoized as DetailsTab }
