import { createContext, useContext } from 'react'

const WizardContext = createContext({})
const WizardProvider = WizardContext.Provider
const WizardConsumer = WizardContext.Consumer

function useWizard() {
  return useContext(WizardContext)
}

export { WizardProvider, WizardConsumer, useWizard }
