import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { PhotoGallery } from 'components/common/PhotoGallery'
import { TabEditing } from 'components/common/TabEditing'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { listingModule } from 'modules/listing'
import { getMedia } from 'utils/getMedia'
import { getSubmitMedia } from 'utils/getSubmitMedia'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('flex flex-column flex-1 mt-6'))

const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))

const Scroll = styled.div(s('flex flex-row'), {
  overflowX: 'hidden',
  overflowY: 'auto',
})

const { array, object } = Validator

const validationSchema = object({
  media: object({
    photos: array().required('Upload at least one photo'),
  }),
})

const Memoized = deepMemo(MediaTab)

function MediaTab({
  isEditing,
  setIsEditing,
  currentTab,
  tabTitle,
  inspectedListing,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)

  if (currentTab !== tabTitle) {
    return null
  }

  const { id } = inspectedListing

  async function handleSubmit({ media }) {
    const submitMedia = getSubmitMedia(inspectedListing, media)

    await listingModule.updateListingMedia(null, id, submitMedia)

    setIsEditing(false)
  }

  return (
    <Formik
      id="media"
      title="Media"
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={{
        media: getMedia(inspectedListing),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form style={s('flex flex-column', { overflow: 'hidden' })} key={isCanceled}>
        <Scroll>
          <Root>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Label htmlFor="media.photos">Photos (max size: 6MB)</Label>
                <PhotoGallery name="media.photos" disabled={!isEditing} />
              </Col>
            </Row>
          </Root>
        </Scroll>

        <TabEditing
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCanceled={isCanceled}
          setIsCanceled={setIsCanceled}
          disabled={isEditingDisabled}
        />
      </Form>
    </Formik>
  )
}

export { Memoized as MediaTab }
