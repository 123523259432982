import { isNil, prop, path, map, reduce, append } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { listingEventModule } from './module'

const { state$, effectState$ } = listingEventModule

const listingEventEntities$ = select(state$, prop('entities'))

const order$ = select(state$, prop('order'))

const inspectedEventId$ = select(state$, (state) => prop('inspectedEntity', state))

const listingEventList$ = select(listingEventEntities$, order$, (entities, order) =>
  map((id) => prop(id, entities), order)
)

const inspectedEvent$ = select(
  inspectedEventId$,
  listingEventEntities$,
  (id, entities) => prop(id, entities)
)

const isFetchingEvents$ = select(effectState$, (effectState) => {
  const fetchEventsStatus = path(['fetchEvents', 'status'], effectState)
  return fetchEventsStatus === 'pending'
})

const scheduledEvents$ = select(
  listingEventList$,
  reduce((acc, listingEvent) => {
    const venueName = path(['availability', 'venue', 'name'], listingEvent)
    const availabilityId = path(['availability', 'id'])(listingEvent)
    const { id, listing, startAt, bookingOffsetInMinutes, note } = listingEvent

    if (isNil(startAt)) {
      return acc
    }

    const event = {
      id,
      availabilityId,
      type: 'scheduled',
      listingTitle: prop('title')(listing),
      venueName,
      startAt,
      bookingWindow: bookingOffsetInMinutes,
      notes: note,
    }

    return append(event, acc)
  }, [])
)

export {
  isFetchingEvents$,
  scheduledEvents$,
  listingEventList$,
  inspectedEvent$,
  inspectedEventId$,
}
