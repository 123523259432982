import { isNil, isNotNil, isNotEmpty } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useCallback, useState, useEffect } from 'react'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import {
  providerModule,
  isUploadingLogo$,
  uploadedLogoId$,
  isUpdatingProvider$,
} from 'modules/provider'
import { useFileUpload } from 'utils/hooks'

const Root = styled.div(s('flex flex-column'))

const Container = styled.div(
  s('relative flex flex-row items-center justify-center h-12'),
  {
    cursor: 'pointer',
    overflow: 'hidden',
  }
)
const Logo = styled.img(s('max-h-12 max-w-12'), { objectFit: 'contain' })
const Button = styled(ButtonBase)(
  s(
    'mx-2 rounded-lg text-sm bg-gray-200 border-0 border-solid border-b-2 border-gray-500 font-medium text-black'
  ),
  {
    cursor: 'pointer',
    '&:disabled': s('border-gray-200 text-gray-600', {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }),
  }
)
const Label = styled.label(s('uppercase tracking-wide text-xs text-grey-dark'))
const OpaqueOverlay = styled.div(s('flex items-center justify-center'))
const ReuploadOverlay = styled(OpaqueOverlay)(s('flex-column flex-1'))

const ConnectedLogoUpload = connect(() => ({
  isUploadingLogo: isUploadingLogo$,
  uploadedLogoId: uploadedLogoId$,
  isUpdatingProvider: isUpdatingProvider$,
}))(LogoUpload)

function LogoUpload({
  name = 'uploadLogo',
  acceptType,
  maxSize,
  rootProps,
  logo,
  disabled,
  isUploadingLogo,
  uploadedLogoId,
  isUpdatingProvider,
  ...props
}) {
  const [preview, setPreview] = useState(undefined)

  const { getRootProps, getInputProps, acceptedFiles } = useFileUpload({
    accept: acceptType,
    maxSize,
    disabled,
    noKeyboard: true,
    onDropAccepted: useCallback(upload),
  })

  async function upload([file]) {
    providerModule.uploadLogo(null, file)
  }

  function handleReset() {
    providerModule.resetUploadLogo()
    setPreview(logo)
  }

  useEffect(() => {
    if (!isUploadingLogo && isNotNil(uploadedLogoId) && isNotEmpty(acceptedFiles)) {
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }

    return () => URL.revokeObjectURL(preview)
  }, [isUploadingLogo, uploadedLogoId, acceptedFiles])

  useEffect(() => {
    if (!isUpdatingProvider) {
      setPreview(logo)
    }
  }, [isUpdatingProvider])

  return (
    <Root {...rootProps}>
      <Row style={s('justify-between')} gutter={[16, 16]}>
        <Col>
          <Label>Logo</Label>
        </Col>

        <Col>
          <Button disabled={disabled} style={s('p-0')}>
            <label style={s('inline-block px-4 py-2')} htmlFor={name}>
              Upload new logo
            </label>
          </Button>

          <Button disabled={disabled || isNil(uploadedLogoId)} onClick={handleReset}>
            Use original logo
          </Button>
        </Col>
      </Row>
      <Container {...getRootProps()} {...props}>
        {isUploadingLogo ? (
          <OpaqueOverlay>
            <Spinner size={36} thickness={4} />
          </OpaqueOverlay>
        ) : (
          isNotNil(preview) && (
            <ReuploadOverlay>
              <Logo src={preview} />
            </ReuploadOverlay>
          )
        )}

        <input
          id={name}
          {...getInputProps()}
          onClick={(event) => {
            const { target } = event
            target.value = null
          }}
        />
      </Container>
    </Root>
  )
}

export { ConnectedLogoUpload as LogoUpload }
