import {
  omitBy,
  isNilOrEmpty,
  pipe,
  toPairs,
  filter,
  apply,
  fromPairs,
} from '@soltalabs/ramda-extra'

const omitNilOrEmpty = omitBy(isNilOrEmpty)

function filterWithKeys(predicate, obj) {
  return pipe(toPairs, filter(apply(predicate)), fromPairs)(obj)
}

export { omitNilOrEmpty, filterWithKeys }
