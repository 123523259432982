import { Button } from 'components/common/Button'
import { styled, s } from 'lib/styled'
import { useWallet } from 'lib/wallet-react'

const Container = styled.div(s('w-full flex flex-row items-center bg-white shadow-sm'))
const Logo = styled.img(s('ml-4 mr-a my-3 h-2'))

function Header() {
  const { isAuthenticated, logout } = useWallet()

  return (
    <Container>
      <Logo src="/logo.png" />

      {isAuthenticated && (
        <Button onClick={logout} style={s('mr-4')}>
          Logout
        </Button>
      )}
    </Container>
  )
}

export { Header }
