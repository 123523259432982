import { isNotNil, pipe, split, filter, join, trim } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import initializeTelephoneInput from 'intl-tel-input'
import parsePhoneNumber from 'libphonenumber-js'
import { useRef, useState, useLayoutEffect, useCallback } from 'react'

import 'intl-tel-input/build/js/utils'
import { RequiredIndicator } from './RequiredIndicator'

import { styled, s } from 'lib/styled'

import './PhoneField.scss'

const NUMBER_TYPES = window.intlTelInputUtils.numberType
const NUMBER_FORMATS = window.intlTelInputUtils.numberFormat

const Container = styled.div(s('flex flex-column rounded-lg'))
const Label = styled.label(
  s('mb-2 uppercase tracking-wide text-xs text-gray-600 font-light')
)
const InputContainer = styled.div(s('w-full rounded-lg'), ({ readOnly }) =>
  readOnly
    ? {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      }
    : {}
)
const Input = styled.input(
  s(
    ' w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ readOnly }) => (readOnly ? s('text-gray-700') : {})
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

PhoneField.propTypes = {
  name: Props.string,
  id: Props.string,
  type: Props.string,
  label: Props.string,
  className: Props.string,
  containerProps: Props.object,
}

function PhoneField({
  name,
  id,
  type = 'tel',
  label,
  className,
  readOnly,
  containerProps,
  showRequiredIndicator = false,
  ...props
}) {
  const input = useRef()

  const validatePhoneNumberByCountry = useCallback((phoneNumber) => {
    let error
    if (isNotNil(input.current)) {
      if (
        !parsePhoneNumber(
          phoneNumber,
          input.current.selectedCountryData.iso2
        )?.isValid()
      ) {
        error = 'Not a valid phone number'
      }
    }

    return error
  }, [])

  const [
    { value: fieldValue, onChange: onFieldChange, ...fieldProps },
    { touched, error },
  ] = useField({
    name,
    id,
    type,
    validate: validatePhoneNumberByCountry,
    ...props,
  })

  const [value, setValue] = useState()

  useLayoutEffect(() => {
    if (isNotNil(input.current)) {
      input.current = initializeTelephoneInput(input.current, {
        onlyCountries: ['AU', 'NZ', 'FJ'],
        nationalMode: true,
        ...props,
      })
    }

    input.current.setNumber(fieldValue ?? '')

    setValue(input.current.getNumber(NUMBER_FORMATS.NATIONAL))

    return () => input.current.destroy()
  }, [])

  function handleChange(event) {
    const sanitizedValue = pipe(
      split(''),
      filter((char) => char.match(/[0-9\s]/gi)),
      join(''),
      trim
    )(event.target.value)

    setValue(sanitizedValue)
    onFieldChange({ target: { value: input.current.getNumber(), name } })
  }

  return (
    <Container className={className} {...containerProps}>
      <Label htmlFor={name || id}>
        <>
          {label} <RequiredIndicator showRequiredIndicator={showRequiredIndicator} />
        </>
      </Label>

      <InputContainer readOnly={readOnly}>
        <Input
          ref={input}
          value={value}
          onChange={handleChange}
          readOnly={readOnly}
          {...fieldProps}
          {...props}
        />
      </InputContainer>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export { PhoneField, NUMBER_TYPES, NUMBER_FORMATS }
