/* eslint-disable react/jsx-key */

import { SortIcon } from './SortIcon'

import { styled, s } from 'lib/styled'

const Tr = styled.tr(s('flex'), {
  '&:last-child': {
    '& td': {
      borderBottom: 0,
    },
  },
})

const Th = styled.th(s('p-3 my-a'))

const Head = styled.div(
  s('flex flex-row uppercase tracking-wide text-xs text-black font-semibold'),
  {
    '& div': s('text-black font-semibold'),
  }
)

const Root = styled.div(s('sticky rounded-md bg-gray-300'), {
  position: '-webkit-sticky',
  top: 0,
  'z-index': 1,
})

const Thead = styled.thead(s('flex flex-column'))

function TableHeader({ headerGroups, sortBy, sortOrder, onSort }) {
  return (
    <Root>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()} style={{ width: column.width }}>
                <Head>
                  {column.render('Header')}
                  {column.canSort ? (
                    <SortIcon
                      column={column}
                      onSort={onSort}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                    />
                  ) : (
                    ''
                  )}
                </Head>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    </Root>
  )
}

export { TableHeader }
