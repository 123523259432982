import { connect } from '@soltalabs/stateless'
import { useCallback } from 'react'

import { ReactComponent as CheckIcon } from 'assets/feathers/check.svg'
import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, s } from 'lib/styled'
import { authModule, resendVerificationEmailStatus$ } from 'modules/auth'

const Container = styled.div(s('flex flex-row items-center'))
const Button = styled(ButtonBase)(s('text-base mr-3'))
const Status = styled.span(s('text-green-dark text-sm ml-1'))

const ConnectedResendEmailControl = connect(() => ({
  resendVerificationEmailStatus: resendVerificationEmailStatus$,
}))(ResendEmailControl)

ResendEmailControl.propTypes = {
  resendVerificationEmailStatus: Props.string,
}

function ResendEmailControl({ resendVerificationEmailStatus }) {
  const resendVerificationEmail = useCallback(() =>
    authModule.resendVerificationEmail()
  )

  return (
    <Container>
      <Button
        disabled={resendVerificationEmailStatus === 'pending'}
        onClick={resendVerificationEmail}
      >
        Re-send email
      </Button>

      {resendVerificationEmailStatus === 'pending' && (
        <Spinner size={12} thickness={2} />
      )}

      {resendVerificationEmailStatus === 'fulfilled' && (
        <>
          <CheckIcon stroke="#1f9d55" width={20} height={20} />
          <Status>Email sent</Status>
        </>
      )}
    </Container>
  )
}

export { ConnectedResendEmailControl as ResendEmailControl }
