import { pick, defaultTo, head, path } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'
import { schema, normalize } from 'normalizr'

import { ProviderService } from './service'

import { UploadService } from 'modules/upload'
import { transformProvider } from 'utils/transformProvider'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  currentProvider: undefined,
  uploadIds: {
    logo: undefined,
  },
})

const providerSchema = new schema.Entity('providers')

const listProviders = (module) => async () => {
  const { items } = await ProviderService.list()
  const normalized = normalize(items, [providerSchema])
  const { providers } = normalized.entities
  const order = normalized.result

  const { currentProvider } = module.getState()
  const defaultProvider = path([head(order), 'id'], providers)

  module.setState({
    entities: providers,
    order,

    currentProvider: defaultTo(defaultProvider)(currentProvider),
  })
}

const createProvider = (module) => async (_, data) => {
  const {
    uploadIds: { logo },
  } = module.getState()

  const { id } = await ProviderService.create({
    ...pick(
      [
        'facebookUrl',
        'instagramUrl',
        'twitterUrl',
        'name',
        'businessRegistrationNumber',
        'taxNumber',
        'tradingName',
        'bankDetails',
        'website',
        'phoneNumber',
        'email',
        'address',
        'businessSummary',
        'providerType',
      ],
      data
    ),
    logo,
  })

  await module.listProviders()
  module.resetUploadLogo()

  return id
}

const uploadLogo = (module) => async (_, logoFile) => {
  const { id } = await UploadService.uploadFile(logoFile)

  module.setState({
    uploadIds: {
      logo: id,
    },
  })
}

const resetUploadLogo = (module) => () => {
  module.setState({
    uploadIds: {
      logo: undefined,
    },
  })
}

const updateProvider = (module) => async (id, body) => {
  const {
    uploadIds: { logo },
  } = module.getState()

  const payload = {
    ...body,
    uploadLogo: {
      uploadId: logo,
    },
  }

  const provider = await ProviderService.update(id, payload)

  module.setState({
    entities: { [id]: transformProvider(provider) },
  })
  module.resetUploadLogo()
}

const activateProvider = (module) => async (_, id) => {
  await ProviderService.activate(id)
  await module.listProviders()
}

const providerModule = createModule({
  name: 'provider',
  initialState: INITIAL_STATE,
  decorators: {
    listProviders,
    createProvider,
    uploadLogo,
    resetUploadLogo,
    updateProvider,
    activateProvider,
  },
})

export { providerModule }
