import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { wallet } from 'wallet'

const service = http.extend({
  prefixUrl: CONFIG.API.URL,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await wallet.getToken()

        request.headers.set('Authorization', `Bearer ${token}`)
      },
    ],
  },
})

const AuthService = {
  async readAccount() {
    try {
      return await service.get('my-account').json()
    } catch (error) {
      const response = await error.response.json()

      if (response.message.match(/UnverifiedAccount/)) {
        return undefined
      }

      throw error
    }
  },

  async createAccount({ firstName, lastName, phoneNumber, avatar }) {
    const payload = {
      firstName,
      lastName,
      mobile: phoneNumber,
      avatar,
    }

    const config = {
      json: payload,
    }

    return service.post('my-account', config).json()
  },

  async updateAccount({ firstName, lastName, mobile, avatar }) {
    const payload = {
      firstName,
      lastName,
      mobile,
      avatar,
    }
    const config = {
      json: payload,
    }

    return service.patch('my-account', config).json()
  },

  async resendVerificationEmail() {
    const payload = {
      clientId: CONFIG.IDENTITY.CLIENT_ID,
    }

    return service.post('email-verifications', { json: payload }).json()
  },
}

export { AuthService }
