import { styled, s, smaller } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('flex flex-row'))
const Title = styled.div(
  s(
    'mb-2 flex-1 py-4 text-center font-medium text-base border-0 border-solid border-primary'
  ),
  {
    borderBottomWidth: '2px',
  },
  smaller('sm')(s('text-sm')),
  ({ isCurrentStep = false }) =>
    isCurrentStep
      ? s('border-primary text-primary')
      : {
          borderColor: '#DDD',
          color: '#DDD',
        }
)

const Memoized = deepMemo(TabsNavigation)

function TabsNavigation({ tabs, currentTab, setCurrentTab, isEditing, ...props }) {
  function handleClick(title) {
    if (!isEditing) {
      setCurrentTab(title)
    }
  }

  return (
    <Root {...props}>
      {tabs.map(({ title }) => (
        <Title
          key={title}
          isCurrentStep={title === currentTab}
          onClick={() => handleClick(title)}
        >
          {title}
        </Title>
      ))}
    </Root>
  )
}

export { Memoized as TabsNavigation }
