import { path, prop, defaultTo } from '@soltalabs/ramda-extra'

function getInitialOnlineActivityObject(provider, currentProviderTimezone) {
  return {
    id: undefined,

    venueId: undefined,

    hasExistingEvent: false,

    conference: {
      link: undefined,
      passcode: undefined,
      timezone: currentProviderTimezone,
    },

    contact: {
      name: provider.name,
      phoneNumber: provider.phone,
      email: provider.email,
    },

    pricing: {
      hasLimitedSpots: false,
      totalSpots: 10,
      options: [
        {
          name: '',
          description: undefined,
          spots: 1,
          price: 0,
        },
      ],
    },

    events: [],
  }
}

function getOnlineAvailability(availabilities = []) {
  const onlineActivity = prop(0, availabilities)

  const pricings = defaultTo([])(path(['pricings'], onlineActivity))

  return {
    id: path(['id'], onlineActivity),

    venueId: path(['venue', 'id'], onlineActivity),
    name: path(['venue', 'name'], onlineActivity),

    hasExistingEvent: true,

    conference: {
      link: path(['venue', 'address', 'formattedAddress'], onlineActivity),
      passcode: path(['venue', 'passcode'], onlineActivity),
      timezone: path(['venue', 'timezone'], onlineActivity),
    },

    contact: {
      name: path(['contact', 'name'], onlineActivity),
      phoneNumber: path(['contact', 'phone'], onlineActivity),
      email: path(['contact', 'email'], onlineActivity),
    },

    pricing: {
      hasLimitedSpots: false,

      totalSpots: path(['spots'], onlineActivity),

      options: pricings.map((pricing) => ({
        id: pricing.id,
        name: pricing.name,
        description: pricing.description,
        spots: pricing.occupiedSpots,
        price: pricing.price,
      })),
    },

    events: [],
  }
}

export { getOnlineAvailability, getInitialOnlineActivityObject }
