import { prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { format, startOfMonth, endOfMonth, isBefore, startOfToday } from 'date-fns'
import { useFormikContext as useFormContext } from 'formik'
import { useState, useEffect, useMemo } from 'react'

import { BigCalendar, Views } from 'components/common/BigCalendar'
import { s } from 'lib/styled'
import { scheduledEvents$, listingEventModule } from 'modules/listing/event'
import { getStartOfMonth, getEndOfMonth } from 'utils/getDate'
import { getDisplayableEvents } from 'utils/getDisplayableEvents'
import { deepMemo } from 'utils/react'

const Connected = connect(() => ({
  scheduledEvents: scheduledEvents$,
}))(EventCalendar)

const Memoized = deepMemo(Connected)

function EventCalendar({
  isCalenderSelected,
  availability,
  scheduledEvents,
  events = [],
  onCreateEvent = () => {},
  onEditEvent = () => {},
  onReadEvent = () => {},
  isEditing,
  ...props
}) {
  const { isSubmitting } = useFormContext()
  const availabilityId = prop('id')(availability)
  const [displayableEvents, setDisplayableEvents] = useState([])

  function handleNavigate(date) {
    listingEventModule.filterDate({
      from: format(startOfMonth(new Date(date)), 'yyyy-MM-dd'),
      to: format(endOfMonth(new Date(date)), 'yyyy-MM-dd'),
    })
  }

  function handleCreateEvent({ start: selectedStartDate }) {
    if (!isEditing) {
      return
    }

    if (isBefore(new Date(selectedStartDate), startOfToday())) {
      return
    }

    onCreateEvent(selectedStartDate)
  }

  function handleSelectEvent(event) {
    const isEditableEvent = event.isThisAvailability
    if (!isEditableEvent) {
      onReadEvent(event)
      return
    }

    onEditEvent(event)
  }

  const populatedDisplayableEvents = useMemo(
    () => getDisplayableEvents(availabilityId, scheduledEvents, events),
    [scheduledEvents, events]
  )

  useEffect(() => {
    if (!isSubmitting) {
      setDisplayableEvents(populatedDisplayableEvents)
    }
  }, [populatedDisplayableEvents])

  useEffect(() => {
    listingEventModule.filterDate({
      from: getStartOfMonth(),
      to: getEndOfMonth(),
    })
  }, [])

  return (
    <BigCalendar
      key={isCalenderSelected}
      selectable
      events={displayableEvents}
      eventPropGetter={(event) =>
        // eslint-disable-next-line no-nested-ternary
        !event.isThisAvailability
          ? { style: s('bg-gray-600') }
          : event.type !== 'scheduled' || !isEditing
          ? { style: s('bg-blue-light') }
          : { style: s('bg-blue-dark') }
      }
      views={[Views.MONTH]}
      onNavigate={handleNavigate}
      defaultView={Views.MONTH}
      onSelectSlot={handleCreateEvent}
      onSelectEvent={handleSelectEvent}
      {...props}
    />
  )
}

export { Memoized as EventCalendar }
