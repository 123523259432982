/* eslint-disable no-nested-ternary */
import { useState } from 'react'

import { TagInput } from './TagInput'

import { ReactComponent as TrashIcon } from 'assets/feathers/icons8-delete.svg'
import { styled, s } from 'lib/styled'
import { getFilePreview } from 'utils/getFilePreview'
import { deepMemo } from 'utils/react'

const PhotoContainer = styled.div(s('flex flex-column mx-3 my-2 items-center'))
const ImgContainer = styled.div(s('relative rounded-lg'), {
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
})
const IconContainer = styled.span(s('absolute'), {
  top: '-3px',
  right: '-3px',
  cursor: 'pointer',
})
const Preview = styled.img(s('h-5 w-5'), {
  objectFit: 'contain',
})

const Memoized = deepMemo(Photos)

function Photos({
  name,
  photo,
  index,
  remove,
  inspectedPhoto,
  setInspectedPhoto,
  setTouched,
  disabled,
}) {
  const [isHover, setIsHover] = useState(false)
  const canDelete = isHover && !disabled

  return (
    <PhotoContainer>
      <ImgContainer
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {canDelete && (
          <IconContainer
            onMouseDown={() => {
              remove(index)

              if (
                photo.uploadId === inspectedPhoto.uploadId ||
                photo.content === inspectedPhoto.content
              ) {
                setInspectedPhoto({
                  uploadId: undefined,
                  uploadFile: undefined,
                  tag: undefined,
                })
              }

              setTouched(true)
            }}
          >
            <TrashIcon width={20} height={20} />
          </IconContainer>
        )}

        <Preview
          src={getFilePreview(photo.uploadFile, photo)}
          onMouseDown={(event) => {
            event.preventDefault()
            setInspectedPhoto(photo)
          }}
        />
      </ImgContainer>

      <TagInput name={`${name}[${index}].tags`} readOnly={disabled} />
    </PhotoContainer>
  )
}

export { Memoized as Photos }
