import { isNil } from 'ramda'
import { useMemo } from 'react'

import { ReactComponent as TrashCan } from 'assets/feathers/trash-can.svg'
import { PRODUCT_STATUSES } from 'constants/productStatus'
import { styled, s } from 'lib/styled'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      title: null,
      createdAt: null,
      status: null,
    })
  }
}

const statusMapper = {
  [PRODUCT_STATUSES.DRAFT]: 'Draft',
  [PRODUCT_STATUSES.AWAITING_APPROVAL]: 'Awaiting Approval',
  [PRODUCT_STATUSES.LISTED]: 'Approved',
  [PRODUCT_STATUSES.SUSPENDED]: 'Suspended',
  [PRODUCT_STATUSES.REJECTED]: 'Rejected',
}

const StyledTrashCan = styled(TrashCan)(s('mt-1 p-2'), {
  '&:hover': s('rounded-full bg-grey-light', { fill: '#000000' }),
})

const PublishButton = styled.button(
  s(
    'bg-primary text-white font-normal border-0 text-xs uppercase pl-2 py-2 rounded-lg'
  ),
  {
    '&:hover': s('bg-primary-dark', {
      cursor: 'pointer',
    }),
  }
)

export const Rows = (list) =>
  useMemo(() => {
    if (list.length < totalRows) {
      createEmptyRows(list)
    }
    return list.map(({ id, name, createdAt, status }) => ({
      id,
      name,
      createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
      status: statusMapper[status],
      publish:
        statusMapper[status] === 'Draft' ? (
          <PublishButton key="publish">Publish</PublishButton>
        ) : null,
      delete: <StyledTrashCan key="delete" fill="#8795a1" width={30} height={30} />,
      isRowRequiresActiveProvider: statusMapper[status] === 'Draft',
    }))
  }, [list])
