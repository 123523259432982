/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { useMemo } from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

const statusMapper = {
  Draft: 'bg-grey-lighter',
  'Awaiting Approval': 'bg-primary-lightest',
  Approved: 'bg-green-lightest',
  Suspended: 'bg-orange-lightest saturate-2',
  Rejected: 'bg-red-lightest saturate-2',
}

export const Columns = () =>
  useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '27%',
      },
      {
        Header: 'Type',
        accessor: 'listingType',
        disableSortBy: true,
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '27%',
      },
      {
        Header: 'Created on',
        accessor: 'createdAt',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '26%',
      },
      {
        Header: () => <StyledCell value="Status" />,
        accessor: 'status',
        id: 'status',
        width: '15%',
        textAlign: 'left',
        Cell: ({ value }) => (
          <StyledCell
            style={s(`rounded-md inline-block px-2 py-1 ${statusMapper[value]}`)}
            value={value}
          />
        ),
      },
      {
        Header: '',
        accessor: 'delete',
        id: 'toolIcon',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        disableSortBy: true,
        width: '3%',
      },
    ],
    []
  )
