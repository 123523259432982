import { Formik, Form as FormBase } from 'formik'

Form.propTypes = {
  children: Props.node,
  rootProps: Props.object,
}

function Form({ children, rootProps, ...props }) {
  return (
    <Formik {...props}>
      <FormBase {...rootProps}>{children}</FormBase>
    </Formik>
  )
}

export { Form }
