import { prop, filter } from '@soltalabs/ramda-extra'

import { MEDIA_TYPES } from 'modules/listing'

function filterMedia(inspectedListing, type) {
  return filter(
    ({ mediaType }) => mediaType === MEDIA_TYPES.valueOf(type),
    prop('media', inspectedListing) || []
  )
}

function getMedia(inspectedListing) {
  return {
    photos: filterMedia(inspectedListing, MEDIA_TYPES.PHOTO),
    video: prop(0, filterMedia(inspectedListing, MEDIA_TYPES.VIDEO)),
    brochure: prop(0, filterMedia(inspectedListing, MEDIA_TYPES.BROCHURE)),
  }
}

export { getMedia }
