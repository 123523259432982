import Select from 'react-select'

import { s } from 'lib/styled'

const styles = {
  control: (provided, state) => {
    const basicStyle = s(
      'min-h-2 flex flex-row border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-sm'
    )
    const disabledStyle = state.isDisabled
      ? s('bg-gray-200 text-gray-600', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...provided, ...basicStyle, ...disabledStyle }
  },

  option: (provided, { isFocused, isSelected }) => {
    const isSelectedStyle = s('text-white font-bold bg-primary')
    const isFocusedStyle = s('bg-primary-lightest')
    return {
      ...provided,
      ...s('text-black'),
      // eslint-disable-next-line no-nested-ternary
      ...(isSelected ? isSelectedStyle : isFocused ? isFocusedStyle : null),
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? isSelectedStyle : isFocusedStyle,
      },
    }
  },

  dropdownIndicator: () => s('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...s('pr-0'),
  }),

  indicatorSeparator: () => s('hidden'),

  singleValue: (_, state) =>
    state.isDisabled ? s('p-2 text-gray-700') : s('p-2 pr-0 text-black'),
}

function SelectFieldBase({ value, type, onChange, options, isDisabled, ...props }) {
  return (
    <Select
      value={value}
      type={type}
      onChange={onChange}
      options={options}
      menuPlacement="auto"
      styles={styles}
      isDisabled={isDisabled}
      {...props}
    />
  )
}

export { SelectFieldBase }
