import { CONFIG } from 'config'
import { Wallet } from 'lib/wallet'

const { IDENTITY } = CONFIG

const wallet = new Wallet({
  identity: {
    domain: IDENTITY.DOMAIN,
    clientId: IDENTITY.CLIENT_ID,
    redirectUrl: IDENTITY.REDIRECT_URL,
    audience: IDENTITY.AUDIENCE,
  },
})

export { wallet }
