import { connect } from '@soltalabs/stateless'
import { useHistory } from 'react-router-dom'

import { Spinner } from 'components/common/Spinner'
import { WizardNavigation } from 'components/common/WizardNavigation'
import { WizardProgress } from 'components/common/WizardProgress'
import { DescriptionStep } from 'components/listings/common/ListingWizard/DescriptionStep/'
import { MediaStep } from 'components/listings/common/ListingWizard/MediaStep'
import { PublishStep } from 'components/listings/common/ListingWizard/PublishStep'
import { CommunityVenue as CommunityVenueStep } from 'components/listings/common/ListingWizard/VenueStep/CommunityVenue'
import { GENDERS } from 'constants/genders'
import { Wizard } from 'lib/formik-wizard'
import { styled, s } from 'lib/styled'
import { LISTING_STATUS, LISTING_TYPES } from 'modules/listing'
import { draftStatus$, isPublishingDraft$ } from 'modules/listing/draft'
import { currentProviderTimezone$, provider$ } from 'modules/provider'
import { getInitialCommunityActivityObject } from 'utils/getCommunityAvailability'

const Root = styled.div(
  s('flex flex-column bg-white w-full rounded-lg px-6 pt-1 pb-4 shadow-sm')
)

const Connected = connect(() => ({
  currentProviderTimezone: currentProviderTimezone$,
  provider: provider$,
  draftStatus: draftStatus$,
  isPublishingDraft: isPublishingDraft$,
}))(CreateCommunityListingWizard)

function CreateCommunityListingWizard({
  currentProviderTimezone,
  provider,
  draftStatus,
  isPublishingDraft,
}) {
  const history = useHistory()

  const isNotDraft =
    draftStatus === LISTING_STATUS.AWAITING_APPROVAL ||
    draftStatus === LISTING_STATUS.LISTED

  const shouldDisableBackButton = isNotDraft || isPublishingDraft
  function handleSubmit() {
    history.push('/listings')
  }

  return (
    <Root>
      <Wizard
        loadingComponent={<Spinner style={s('mt-12')} />}
        basePath="/listings/new-listing/community"
        formProps={{
          style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          draftId: undefined,
          listingType: LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY),

          currentProviderTimezone,

          // Description step
          description: {
            title: undefined,
            description: { text: undefined, charsCount: undefined },
            categories: [],
            keywords: [],
            needToBring: [],
            whatToWear: [],
            genders: [GENDERS.UNSPECIFIED],
          },

          // Media step
          media: {
            photos: [],
            video: undefined,
            brochure: undefined,
          },

          // Venues step
          communityActivity: getInitialCommunityActivityObject(
            provider,
            currentProviderTimezone
          ),

          // T&C step
          legalDocuments: {
            termsAndConditions: {
              disabled: false,
              type: 'Text',
              text: { text: undefined, charsCount: 0 },
              upload: { uploadId: undefined, uploadFile: undefined },
            },
            cancellationPolicy: {
              disabled: false,
              type: 'Text',
              text: { text: undefined, charsCount: 0 },
              upload: { uploadId: undefined, uploadFile: undefined },
            },
            riskAssessment: {
              disabled: false,
              type: 'Text',
              text: { text: undefined, charsCount: 0 },
              upload: { uploadId: undefined, uploadFile: undefined },
            },
          },
        }}
      >
        <WizardProgress />

        <DescriptionStep />
        <MediaStep />
        <CommunityVenueStep provider={provider} />
        <PublishStep />

        <WizardNavigation
          shouldDisableBackButton={shouldDisableBackButton}
          style={s('mt-6')}
        />
      </Wizard>
    </Root>
  )
}

export { Connected as CreateCommunityListingWizard }
