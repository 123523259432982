import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { Spinner } from 'components/common/Spinner'
import { styled, s } from 'lib/styled'
import { useWallet } from 'lib/wallet-react'

const SpinnerContainer = styled.div(
  s('h-full flex flex-column items-center justify-center')
)

function SignUp() {
  const { isAuthenticated, isLoading, requestAuthorization } = useWallet()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      requestAuthorization({ targetUrl: '/', screenHint: 'signup' })
    }
  }, [isAuthenticated, isLoading, requestAuthorization])

  if (!isAuthenticated || isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return <Redirect to="/" />
}

export { SignUp }
