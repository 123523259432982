import { prop, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { categoryModule } from './module'

const { state$, effectState$ } = categoryModule

const allCategoryEntities$ = select(state$, (state) => prop('allEntities', state))

const fetchCategoryStatus$ = select(effectState$, (clientEntities, effectState) =>
  pathOr('pending', [clientEntities, 'status'], effectState)
)

const isLoadingCategories$ = select(
  fetchCategoryStatus$,
  (status) => status === 'pending'
)

export { isLoadingCategories$, allCategoryEntities$ }
