import { connect } from '@soltalabs/stateless'
import { useState } from 'react'

import { Paragraph } from './Paragraph'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, s } from 'lib/styled'
import { DraftModule, isPublishingDraft$ } from 'modules/listing/draft'

const ActionContainer = styled.div(s('flex flex-row items-center'))
const Button = styled(ButtonBase)(s('text-lg'))

const ErrorMessage = styled.div(s('mt-4'), {
  color: 'red',
})

const Connected = connect(() => ({
  isPublishingDraft: isPublishingDraft$,
}))(GoLivePrompt)

function GoLivePrompt({ isPublishingDraft }) {
  const [errorMessage, setErrorMessage] = useState('')
  const [shouldShowError, setShouldShowError] = useState(false)
  async function handlePublish() {
    const res = await DraftModule.publish()
    if (res.statusCode === 400 || res.statusCode === 404) {
      setShouldShowError(true)
      setErrorMessage(res.message)
    }
  }

  return (
    <>
      <Paragraph>
        Your listing has been created! Would you like to publish it now?
      </Paragraph>

      <ActionContainer>
        {isPublishingDraft && <Spinner size={20} thickness={3} style={s('mr-3')} />}

        <Button disabled={isPublishingDraft} onClick={handlePublish}>
          Go live
        </Button>
      </ActionContainer>
      {shouldShowError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
}

export { Connected as GoLivePrompt }
