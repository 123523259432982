import { connect } from '@soltalabs/stateless'
import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Spinner } from 'components/common/Spinner'
import { Dashboards } from 'components/Dashboards'
import { OnboardingPage } from 'components/onboarding/OnboardingPage'
import { VerifyEmailPage } from 'components/onboarding/VerifyEmailPage'
import { styled, s } from 'lib/styled'
import { useWallet } from 'lib/wallet-react'
import { authModule, isCheckingOnboardingStatus$ } from 'modules/auth'
import { isProviderBanned$ } from 'modules/provider'

import 'styles/index.css'
import 'styles/vendors.scss'

const Container = styled.div(
  s('h-full flex flex-column', {
    backgroundColor: '#F8F8F8',
  }),
  ({ centered }) => (centered ? s('items-center justify-center') : {})
)

const Card = styled.div(s('self-center max-w-24 p-6 shadow-md bg-white rounded-lg'))
const Title = styled.h1(s('text-primary text-3xl font-regular mb-8'))
const Message = styled.p(s('text-base'), { lineHeight: 1.15 })

const SpinnerContainer = styled.div(
  s('h-full flex flex-column items-center justify-center')
)

const ConnectedAppMain = connect(() => ({
  isCheckingOnboardingStatus: isCheckingOnboardingStatus$,
  isProviderBanned: isProviderBanned$,
}))(AppMain)

function AppMain({ isCheckingOnboardingStatus, isProviderBanned }) {
  const { isAuthenticated, isLoading, requestAuthorization, logout } = useWallet()
  const { checkOnboardingStatus } = authModule

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      requestAuthorization({ targetUrl: '/' })
    }

    if (isAuthenticated && !isLoading) {
      checkOnboardingStatus()
    }
  }, [isAuthenticated, isLoading, requestAuthorization, checkOnboardingStatus])

  useEffect(() => {
    if (isProviderBanned) {
      setTimeout(() => {
        logout()
      }, 5000)
    }

    return () => {}
  }, [isProviderBanned])

  if (!isAuthenticated || isLoading || isCheckingOnboardingStatus) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (isProviderBanned) {
    return (
      <Container centered>
        <Card>
          <Title>Banned Account</Title>

          <Message>Please contact a platform administrator to gain access.</Message>

          <Message style={s('mb-0')}>
            <i>Logging out in 5 seconds...</i>
          </Message>
        </Card>
      </Container>
    )
  }

  return (
    <Container>
      <Switch>
        <Route path="/setup" component={OnboardingPage} />
        <Route exact path="/verify" component={VerifyEmailPage} />
        <Route path="/" component={Dashboards} />
      </Switch>
    </Container>
  )
}

export { ConnectedAppMain as AppMain }
