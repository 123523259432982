import { Switch, Route } from 'react-router-dom'

import { AppMain } from 'components/AppMain'
import { SignUp } from 'components/SignUp'

import 'styles/index.css'
import 'styles/vendors.scss'

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/signup" component={SignUp} />
        <Route path="/" component={AppMain} />
      </Switch>
    </>
  )
}

export default App
