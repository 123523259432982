import { useIsOpen } from '@accessible/accordion'
import {
  path,
  pipe,
  filter,
  prop,
  remove,
  map,
  findIndex,
  isNotNilOrEmpty,
  isString,
} from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useFormikContext as useFormContext, FieldArray } from 'formik'
import { useState, useCallback } from 'react'
import Select from 'react-select'
import {
  Tabs,
  TabList as TabListBase,
  Tab as TabBase,
  TabPanel as PanelBase,
} from 'react-tabs'

import { CalendarShowMoreModal } from './CalendarShowMoreModal'
import { TimezoneField } from './TimezoneField'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { ReactComponent as CloseIcon } from 'assets/feathers/x-square.svg'
import { Button } from 'components/common/Button'
import { CheckboxField } from 'components/common/CheckboxField'
import { NumberField } from 'components/common/NumberField'
import { PhoneField } from 'components/common/PhoneField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TextField } from 'components/common/TextField'
import { EventCalendar } from 'components/listings/common/EventCalendar'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { timezones$ } from 'modules/operatingSystem'
import 'react-tabs/style/react-tabs.css'
import { deepMemo } from 'utils/react'

const PRICING_OPTIONS_LIMIT = 5

const Root = styled(Tabs)(
  s('flex flex-column'),
  {
    overflow: 'hidden',
    transition: 'max-height 0.3s, margin 0.25s',
  },
  ({ isSelected, isOpen }) => (isSelected && isOpen ? s('max-h-48') : s('max-h-0'))
)
const Tab = styled(TabBase)(
  s('uppercase tracking-wide text-xs font-semibold px-4 py-2 rounded-t-lg')
)
const TabList = styled(TabListBase)(s('m-0 mt-1 order-gray-500'))
const SectionTitle = styled.h2(s('my-6 text-base font-semibold text-gray-700'))
const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))
const ErrorMessage = styled.div(s('mb-2 text-error text-sm'))
const Panel = styled(PanelBase)(
  s('flex-1 p-4 border-1 border-transparent'),
  {
    overflow: 'hidden',
  },
  ({ readOnly }) =>
    readOnly
      ? {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        }
      : {}
)

const EditButton = styled(Button)(s('font-semibold'), {
  '&:hover': s('bg-gray-300', { transition: 'background-color 0.25s' }),
})
const RemoveOptionButton = styled.button(s('border-0 p-0 m-0 bg-transparent'), {
  cursor: 'pointer',
})
const NewPricingOptionButton = styled(Button)(
  s('flex flex-row items-center border-0 bg-white p-2'),
  {
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': s('bg-gray-400'),
    '&:active': s('bg-gray-200'),
  }
)

const selectStyles = {
  control: () =>
    s(
      'min-w-12 flex flex-row items-center min-h-a border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200 text-sm'
    ),
  option: (provided) => ({
    ...provided,
    '&:hover': s('bg-gray-400'),
  }),
  dropdownIndicator: () => s('p-0 px-2 relative', { top: 1 }),
  valueContainer: (provided) => ({
    ...provided,
    ...s('px-3 py-1'),
  }),
  indicatorSeparator: () => s('hidden'),
  singleValue: () => s('text-black'),
}

Root.tabsRole = 'Tabs'
Tab.tabsRole = 'Tab'
TabList.tabsRole = 'TabList'
Panel.tabsRole = 'TabPanel'

const Connected = connect(() => ({
  timezones: timezones$,
}))(ActivityForm)

const Memoized = deepMemo(Connected)

function ActivityForm({
  index,
  isSelected,
  timezones,
  onEditVenue,
  onCreateEvent,
  onEditEvent,
  onReadEvent,
  isEditing = true,
}) {
  const isSectionOpen = useIsOpen()
  const [isCalenderSelected, setIsCalenderSelected] = useState(false)
  const [isShowMoreModalOpen, setIsShowMoreModalOpen] = useState(false)
  const [dayEvents, setDayEvents] = useState([])

  const { values, errors, setFieldValue } = useFormContext()
  const availabilities = prop('availabilities', values)
  const availability = path(['availabilities', index], values)
  const hasLimitedSpots = path(['pricing', 'hasLimitedSpots'], availability)
  const options = path(['availabilities', index, 'pricing', 'options'], values)
  const events = prop('events', availability)

  const pricingOptionError = path(
    ['availabilities', index, 'pricing', 'options'],
    errors
  )

  const copyablePricingOptions = pipe(
    remove(index, 1),
    filter(prop('isSelected')),
    map(({ venue }) => ({ label: venue.name, value: venue.id }))
  )(availabilities)

  const baseName = `availabilities[${index}]`

  function createAddPricingOptionHandler(push) {
    return () => push({ name: '', description: undefined, spots: 1, price: 0 })
  }

  function handleCopyPricingOptions({ value: targetVenueId }) {
    const targetPricingOptionIndex = findIndex(
      (availability) => availability.venue.id === targetVenueId,
      availabilities
    )

    setFieldValue(
      `availabilities[${index}].pricing`,
      path([targetPricingOptionIndex, 'pricing'], availabilities)
    )
  }

  function handleCreateEvent(selectedStartDate) {
    onCreateEvent({
      title: availability.venue.name,
      field: `${baseName}.events`,
      selectedStartDate,
    })
  }

  function handleShowMoreClick(event) {
    setDayEvents(event)
    setIsShowMoreModalOpen(true)
  }

  function handleEditEvent({ start: startAt, ...rest }) {
    onEditEvent({
      field: `${baseName}.events`,
      startAt,
      ...rest,
    })
  }

  function handleReadEvent({ start: startAt, ...rest }) {
    onReadEvent({
      field: `${baseName}.events`,
      startAt,
      ...rest,
    })
  }

  function handleTimeslotOpen({ start: startAt, ...rest }) {
    if (isEditing && rest.isThisAvailability) {
      handleEditEvent({ startAt, ...rest })
    } else {
      handleReadEvent({ startAt, ...rest })
    }
    setIsShowMoreModalOpen(false)
  }

  function handleSelect(index) {
    if (index === 3) {
      setIsCalenderSelected(true)
      return
    }
    setIsCalenderSelected(false)
  }

  return (
    <Root isSelected={isSelected} isOpen={isSectionOpen} onSelect={handleSelect}>
      <CalendarShowMoreModal
        isOpen={isShowMoreModalOpen}
        setIsOpen={setIsShowMoreModalOpen}
        dayEvents={dayEvents}
        onOpenTimeslot={handleTimeslotOpen}
        isEditing={isEditing}
      />
      <TabList>
        <Tab>Location</Tab>
        <Tab>Contact Information</Tab>
        <Tab>Pricing</Tab>
        <Tab>Date & Time</Tab>
      </TabList>

      <Panel readOnly={!isEditing}>
        <Row>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={10}>
                <TextField
                  readOnly
                  name={`${baseName}.location.address.formattedAddress`}
                  label="Address"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={10}>
                <CheckboxField
                  disabled
                  name={`${baseName}.location.hasWheelchairAccess`}
                  label="Wheelchair access"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={10}>
                <TimezoneField
                  disabled
                  items={timezones}
                  name={`${baseName}.location.timezone`}
                  label="Timezone"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={10}>
                <TextAreaField
                  readOnly
                  name={`${baseName}.location.transportNotes`}
                  label="Transport Notes"
                  placeholder="None specified"
                  minRows={3}
                />
              </Col>
            </Row>

            <Row gutter={[16, 8]}>
              <Col span={10}>
                <TextAreaField
                  readOnly
                  name={`${baseName}.location.parkingNotes`}
                  label="Parking Notes"
                  placeholder="None specified"
                  minRows={3}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10} style={s('flex flex-row-reverse')}>
                <EditButton
                  type="button"
                  variant="secondary"
                  onClick={onEditVenue}
                  style={!isEditing ? s('text-gray-400') : {}}
                >
                  Edit
                </EditButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>

      <Panel readOnly={!isEditing}>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <TextField name={`${baseName}.contact.name`} label="Name" />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={10}>
            <PhoneField name={`${baseName}.contact.phoneNumber`} label="Phone number" />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={10}>
            <TextField name={`${baseName}.contact.email`} label="E-mail" />
          </Col>
        </Row>
      </Panel>

      <Panel readOnly={!isEditing}>
        <Row gutter={[16, 0]}>
          <Col sm={7} md={6} lg={6} xl={5}>
            <NumberField
              disabled={!hasLimitedSpots}
              name={`${baseName}.pricing.totalSpots`}
              label="Total spots"
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={7} md={6} lg={6} xl={5}>
            <CheckboxField
              name={`${baseName}.pricing.hasLimitedSpots`}
              label="Limited spots"
            />
          </Col>
        </Row>

        <SectionTitle>Pricing options</SectionTitle>

        <Row gutter={[16, 32]}>
          <Col span={6}>
            <Select
              onChange={handleCopyPricingOptions}
              placeholder="Copy from other venue"
              options={copyablePricingOptions}
              isSearchable={false}
              styles={selectStyles}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={options.length === 0 ? s('hidden') : {}}>
          <Col span={5}>
            <Label>Name</Label>
          </Col>

          <Col span={11}>
            <Label>Description</Label>
          </Col>

          <Col span={2}>
            <Label>Spots</Label>
          </Col>

          <Col span={3}>
            <Label>Price</Label>
          </Col>
        </Row>

        <FieldArray name={`${baseName}.pricing.options`}>
          {({ push, remove }) => (
            <>
              {options.map((option, optionIndex) => (
                <Row key={index} gutter={[16, 16]}>
                  <Col span={5}>
                    <TextField
                      characterLimit={100}
                      name={`${baseName}.pricing.options[${optionIndex}].name`}
                    />
                  </Col>

                  <Col span={11}>
                    <TextField
                      characterLimit={100}
                      name={`${baseName}.pricing.options[${optionIndex}].description`}
                    />
                  </Col>

                  <Col span={2}>
                    <TextField
                      name={`${baseName}.pricing.options[${optionIndex}].spots`}
                    />
                  </Col>

                  <Col span={3}>
                    <TextField
                      name={`${baseName}.pricing.options[${optionIndex}].price`}
                    />
                  </Col>

                  <Col span={3} style={s('flex flex-row items-center')}>
                    <RemoveOptionButton
                      type="button"
                      onClick={() => remove(optionIndex)}
                    >
                      <CloseIcon width={28} height={28} stroke="#EE6C87" />
                    </RemoveOptionButton>
                  </Col>
                </Row>
              ))}

              {isString(pricingOptionError) && isNotNilOrEmpty(pricingOptionError) && (
                <ErrorMessage>{pricingOptionError}</ErrorMessage>
              )}

              {options.length < PRICING_OPTIONS_LIMIT && (
                <NewPricingOptionButton
                  type="button"
                  onClick={createAddPricingOptionHandler(push)}
                >
                  <PlusIcon width={17} height={17} style={s('mr-2')} />
                  Add pricing option
                </NewPricingOptionButton>
              )}
            </>
          )}
        </FieldArray>
      </Panel>

      <Panel forceRender={isSelected}>
        <EventCalendar
          isCalenderSelected={isCalenderSelected}
          availability={availability}
          events={useCallback(
            map(
              ({ startDate, endDate, shouldUpdateEvent, ...rest }) => ({
                start: startDate,
                end: endDate,
                shouldUpdateEvent,
                type: shouldUpdateEvent ? 'scheduled' : undefined,
                isThisAvailability: true,
                ...rest,
              }),
              events
            ),
            [events]
          )}
          onCreateEvent={handleCreateEvent}
          onEditEvent={handleEditEvent}
          onReadEvent={handleReadEvent}
          onShowMore={handleShowMoreClick}
          style={s('min-h-32')}
          isEditing={isEditing}
        />
      </Panel>
    </Root>
  )
}

export { Memoized as ActivityForm }
