import { Switch, Route, Redirect } from 'react-router-dom'

import { CreateCommunityListingWizard } from './CreateCommunityListingWizard'
import { CreateOnlineListingWizard } from './CreateOnlineListingWizard'
import { CreatePhysicalListingWizard } from './CreatePhysicalListingWizard'
import { EditDraftListingWizard } from './EditDraftListingWizard'
import { ListingDetails } from './ListingDetails'
import { ListingsTable } from './ListingsTable'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

function ListingsDashboard() {
  return (
    <Root>
      <Switch>
        <Route
          path="/listings/new-listing/physical"
          component={CreatePhysicalListingWizard}
        />
        <Route
          path="/listings/new-listing/online"
          component={CreateOnlineListingWizard}
        />
        <Route
          path="/listings/new-listing/community"
          component={CreateCommunityListingWizard}
        />
        <Route path="/listings/draft/:id" component={EditDraftListingWizard} />
        <Route path="/listings/:id" component={ListingDetails} />
        <Route exact path="/listings" component={ListingsTable} />

        <Redirect to="/listings" />
      </Switch>
    </Root>
  )
}

export { ListingsDashboard }
