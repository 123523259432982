import { isEmpty, prop, omit } from '@soltalabs/ramda-extra'
import {
  addDays,
  differenceInDays,
  addWeeks,
  differenceInWeeks,
  addMonths,
  differenceInMonths,
} from 'date-fns'

import { EVENT_REOCCURRENCE } from 'modules/listing'

const reoccurrenceMapper = {
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.DAILY)]: {
    countRepeatEventsFunc: differenceInDays,
    addDaysFunc: addDays,
  },
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.WEEKLY)]: {
    countRepeatEventsFunc: differenceInWeeks,
    addDaysFunc: addWeeks,
  },
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.FORTNIGHTLY)]: {
    countRepeatEventsFunc: differenceInWeeks,
    addDaysFunc: addWeeks,
  },
  [EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.MONTHLY)]: {
    countRepeatEventsFunc: differenceInMonths,
    addDaysFunc: addMonths,
  },
}

// eslint-disable-next-line complexity
function getRepeatEvents(events) {
  if (isEmpty(events)) {
    return []
  }
  const returnEvents = []

  for (let index = 0; index < events.length; index += 1) {
    const event = events[index]
    const reoccurrence = prop('reoccurrence', event)
    const reoccurrenceEnd = prop('reoccurrenceEnd', event)

    if (reoccurrence === EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.NEVER)) {
      returnEvents.push(event)
    } else {
      const repeatEventObject = omit(['start', 'end'], event)
      const start = prop('start', event)
      const end = prop('end', event)

      const { countRepeatEventsFunc, addDaysFunc } = reoccurrenceMapper[reoccurrence]

      const repeatEvents = countRepeatEventsFunc(reoccurrenceEnd, start)

      const incrementNumber =
        reoccurrence === EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.FORTNIGHTLY)
          ? 2
          : 1

      returnEvents.push(event)
      for (let index = 0; index < repeatEvents; index += incrementNumber) {
        returnEvents.push({
          start: addDaysFunc(new Date(start), index + incrementNumber),
          end: addDaysFunc(new Date(end), index + incrementNumber),
          ...repeatEventObject,
        })
      }
    }
  }

  return returnEvents
}

export { getRepeatEvents }
