import { createModule } from '@soltalabs/stateless'

import { CategoryService } from './service'

const INITIAL_STATE = Object.freeze({
  allEntities: null,
})

const fetchAllCategories = (module) => async () => {
  const allEntities = await CategoryService.listAll()
  module.setState({ allEntities })
}

const categoryModule = createModule({
  name: 'category',
  initialState: INITIAL_STATE,
  decorators: { fetchAllCategories },
})

export { categoryModule }
