import { path, isNil } from '@soltalabs/ramda-extra'
import { isBefore, isSameDay, startOfToday, startOfTomorrow, addDays } from 'date-fns'
import { useFormikContext as useFormContext } from 'formik'
import { useEffect } from 'react'

import { TimezoneField } from './TimezoneField'

import { AddressField } from 'components/common/AddressField'
import { CheckboxField } from 'components/common/CheckboxField'
import { DateField } from 'components/common/DateField'
import { PhoneField } from 'components/common/PhoneField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TextField } from 'components/common/TextField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('bg-white rounded-lg px-3 pb-6'), {
  overflow: 'auto',
})

const Container = styled.div(({ readOnly }) =>
  readOnly
    ? {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      }
    : {}
)

const Title = styled.h2(s('text-lg font-semibold mb-4'))

function CommunityActivityForm({ isEditing = true }) {
  const { values, setFieldValue } = useFormContext()

  const noDateRange = path(['communityActivity', 'noDateRange'])(values)
  const startDate = path(['communityActivity', 'startDate'])(values)
  const endDate = path(['communityActivity', 'endDate'])(values)

  useEffect(() => {
    if (isNil(startDate)) {
      setFieldValue('communityActivity.startDate', startOfToday())
    }

    if (isNil(endDate)) {
      setFieldValue('communityActivity.endDate', startOfTomorrow())
    }
  }, [noDateRange])

  useEffect(() => {
    if (isBefore(endDate, startDate) || isSameDay(endDate, startDate)) {
      setFieldValue('communityActivity.endDate', addDays(new Date(startDate), 1))
    }
  }, [startDate])

  return (
    <Root>
      <Container readOnly={!isEditing}>
        <Row
          style={s(
            'py-8 justify-between border-0 border-b-1 border-solid border-gray-300'
          )}
        >
          <Col span={2}>
            <Title>Location</Title>
          </Col>

          <Col sm={24} md={24} lg={24} xl={21}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextField
                  characterLimit={150}
                  name="communityActivity.location.name"
                  label="Name"
                />
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col span={24}>
                <AddressField
                  name="communityActivity.location.address"
                  label="Address"
                  placeholder={path([
                    'communityActivity',
                    'location',
                    'address',
                    'formattedAddress',
                  ])(values)}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CheckboxField
                  name="communityActivity.location.hasWheelchairAccess"
                  label="Wheelchair access"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TimezoneField
                  name="communityActivity.location.timezone"
                  label="Timezone"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextAreaField
                  name="communityActivity.location.transportNotes"
                  label="Transport Notes"
                  minRows={3}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <TextAreaField
                  name="communityActivity.location.parkingNotes"
                  label="Parking Notes"
                  minRows={3}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row
          style={s(
            'py-8 justify-between border-0 border-b-1 border-solid border-gray-300'
          )}
        >
          <Col span={2}>
            <Title>Contact</Title>
          </Col>

          <Col sm={24} md={24} lg={24} xl={21}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextField name="communityActivity.contact.name" label="Name" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <PhoneField
                  name="communityActivity.contact.phoneNumber"
                  label="Phone number"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextField name="communityActivity.contact.email" label="E-mail" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={s('py-8 justify-between')}>
          <Col span={2}>
            <Title>Availability</Title>
          </Col>

          <Col sm={24} md={24} lg={24} xl={21}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CheckboxField
                  name="communityActivity.noDateRange"
                  label="No date range for listing"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DateField
                  name="communityActivity.startDate"
                  label="Start date"
                  autoOk
                  disablePast
                  disabled={noDateRange}
                />
              </Col>

              <Col span={12}>
                <DateField
                  name="communityActivity.endDate"
                  label="End date"
                  autoOk
                  disablePast
                  minDate={addDays(new Date(startDate), 1)}
                  disabled={noDateRange}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <TextAreaField
                  name="communityActivity.note"
                  label="Hour Notes"
                  minRows={3}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Root>
  )
}

export { CommunityActivityForm }
