import { isNotNil } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { getPhysicalVenueError } from 'utils/getPhysicalVenueError'
import { deepMemo } from 'utils/react'

const Root = styled.div('flex flex-row')
const Button = styled(ButtonBase)(s('w-5 ml-a'))
const ErrorMessage = styled.div(s('mb-1 text-error text-sm text-right'))

const Memoized = deepMemo(TabEditing)

function TabEditing({
  isEditing,
  setIsEditing,
  isCanceled,
  setIsCanceled,
  customHandleReset = undefined,
  formError,
  disabled,
  ...props
}) {
  const { values, isSubmitting, resetForm, submitCount } = useFormContext()

  const { availabilities } = values

  const errorMessage = getPhysicalVenueError(formError, availabilities)

  const hasAttemptedSubmit = submitCount !== 0

  return (
    <Root {...props}>
      <Row style={s('items-center justify-end m-5')}>
        {hasAttemptedSubmit && (
          <Col>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </Col>
        )}
        <Col style={s('ml-1 flex flex-row-reverse items-center')}>
          {isEditing ? (
            <Row>
              <Col span={12}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={async () => {
                    if (isNotNil(customHandleReset)) {
                      await customHandleReset()
                    }

                    resetForm()

                    setIsCanceled(!isCanceled)
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>
              </Col>

              <Col span={12} style={s('text-right')}>
                <Button type="submit">Save</Button>
              </Col>
            </Row>
          ) : (
            <Button
              type="button"
              disabled={disabled}
              onClick={() => {
                setIsEditing(true)
              }}
            >
              Edit
            </Button>
          )}

          {isSubmitting && <Spinner size={20} thickness={3} style={s('mr-3')} />}
        </Col>
      </Row>
    </Root>
  )
}

export { Memoized as TabEditing }
