import { isNil, path } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { MediaTab } from './MediaTab'
import { OptionsVariantsTab } from './OptionsVariantsTab'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabsNavigation } from 'components/common/TabsNavigation'
import { DetailsTab } from 'components/products/ProductDetails/DetailsTab'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { inspectedProduct$, isInspectingProduct$ } from 'modules/product'
import { provider$, isProviderSuspended$ } from 'modules/provider'
import { getProductFormInitialValues } from 'utils/getProductFormInitialValues'

const Root = styled.div(
  s('p-2 flex-1 flex flex-column bg-white rounded-lg shadow-sm w-full')
)

const ConnectedProductDetails = connect(() => ({
  inspectedProduct: inspectedProduct$,
  isInspectingProduct: isInspectingProduct$,
  provider: provider$,
  isProviderSuspended: isProviderSuspended$,
}))(ProductDetails)

const tabs = [{ title: 'Details' }, { title: 'Media' }, { title: 'Options & Variants' }]

function ProductDetails({
  inspectedProduct,
  isInspectingProduct,
  isProviderSuspended,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(path([0, 'title'], tabs))

  const initialValues = getProductFormInitialValues(inspectedProduct)

  if (isNil(inspectedProduct)) {
    return <Redirect to="/products" />
  }

  return (
    <Root>
      <Loader
        loading={isInspectingProduct}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <TabsNavigation
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          isEditing={isEditing}
        />

        <Row
          style={s('justify-between flex-1', {
            overflow: 'hidden',
          })}
        >
          <Col
            span={24}
            style={s('flex flex-column h-full', {
              overflow: 'hidden',
            })}
          >
            <DetailsTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedProduct={inspectedProduct}
              initialValues={initialValues}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Details"
            />
            <MediaTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedProduct={inspectedProduct}
              initialValues={initialValues}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Media"
            />
            <OptionsVariantsTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentTab={currentTab}
              inspectedProduct={inspectedProduct}
              initialValues={initialValues}
              isEditingDisabled={isProviderSuspended}
              tabTitle="Options & Variants"
            />
          </Col>
        </Row>
      </Loader>
    </Root>
  )
}

export { ConnectedProductDetails as ProductDetails }
