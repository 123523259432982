import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import { TextAreaField } from '../../common/TextAreaField'
import { TextField } from '../../common/TextField'

import { LogoUpload } from './LogoUpload'

import { ReactComponent as FacebookIcon } from 'assets/img/facebook-round-filled.svg'
import { ReactComponent as InstagramIcon } from 'assets/img/instagram-outline.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/twitter-filled.svg'
import { AddressField } from 'components/common/AddressField'
import { CheckboxField } from 'components/common/CheckboxField'
import { PhoneField, NUMBER_FORMATS } from 'components/common/PhoneField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.section(s('p-4 my-4'))
const Title = styled.span(s('tracking-wide text-black text-lg'))
const Label = styled.label(s('mb-4 uppercase tracking-wide text-xs text-grey-dark'))

function BusinessDetails({ logo, isEditing }) {
  const { values } = useFormContext()

  return (
    <Root key={isEditing}>
      <Row>
        <Col span={3}>
          <Title>Business Details</Title>
        </Col>

        <Col span={21} style={s('mt-8')}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <LogoUpload
                disabled={!isEditing}
                acceptType="image/*"
                maxSize={3e7}
                logo={logo}
              />
            </Col>

            <Col span={12}>
              <Row gutter={[16, 16]}>
                <Col span={24} style={s('pl-0')}>
                  <Label>Social Networks</Label>
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly={!isEditing}
                    name="facebook"
                    labelPosition="inline"
                    label={<FacebookIcon width={28} height={28} />}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly={!isEditing}
                    name="instagram"
                    labelPosition="inline"
                    label={<InstagramIcon width={28} height={28} />}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <TextField
                    readOnly={!isEditing}
                    name="twitter"
                    labelPosition="inline"
                    label={<TwitterIcon width={28} height={28} />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="name"
                label={'Registered Company Name (*)'}
                characterLimit={150}
                tooltip="We need this information to provide invoices on your behalf"
              />
            </Col>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="businessRegistrationNumber"
                label="Company Number (NZBN/ABN)"
                characterLimit={15}
              />
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="tradingName"
                label="Trading as"
                characterLimit={150}
              />
            </Col>

            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="taxNumber"
                label="GST Number (*)"
                characterLimit={32}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12} />

            <Col span={12}>
              <CheckboxField
                disabled={!isEditing}
                name="notGSTRegistered"
                label="Not GST Registered"
                style={s('text-xs text-gray-600')}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="bankDetails.accountName"
                label="Account name"
                tooltip="This is the account name for the bank account you wish to receive funds into"
              />
            </Col>

            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="bankDetails.accountNumber"
                label="Account number"
                tooltip="This is the account number of the account you would like to receive payments into"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="bankDetails.bsbNumber"
                label="BSB number"
                tooltip="This is the BSB number associated with the bank branch you would like to receive payments into"
              />
            </Col>

            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="bankDetails.bankName"
                label="Bank name"
                tooltip="This is the name of the bank associated with the account you would like to receive payments into"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextField
                readOnly={!isEditing}
                name="businessEmail"
                label="Contact E-mail (*)"
                tooltip="This is the email address we will provide to people wanting to enquire about your listing"
              />
            </Col>

            <Col span={12}>
              <TextField readOnly={!isEditing} name="website" label="Website" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <PhoneField
                readOnly={!isEditing}
                name="phone"
                label="Phone Number (*)"
                placeholderNumberType={NUMBER_FORMATS.FIXED_LINE_OR_MOBILE}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <AddressField
                readOnly={!isEditing}
                name="address"
                label="Service Location (*)"
                placeholder={path(['provider', 'address', 'formattedAddress'], values)}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextAreaField
                readOnly={!isEditing}
                name="businessSummary"
                minRows={5}
                label="Business Summary"
                characterLimit={300}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Root>
  )
}

export { BusinessDetails }
