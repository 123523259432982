import { isNotNil, defaultTo, prop, omit } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'
import * as Validator from 'yup'

import { http } from 'httpClient'
import { createAddressObject } from 'utils/createAddressObject'

const { number, array, string } = Validator

const validationSchema = Validator.object().shape({
  type: string().required(),
  coordinates: array().of(number()).required(),
})

async function parseGeoJSON(inputLocation, inputAddress) {
  if (inputLocation && !(await validationSchema.isValid(inputLocation))) {
    const address = createAddressObject(inputLocation)
    const location = {
      type: 'Point',
      coordinates: [
        inputLocation?.geometry?.location?.lng(),
        inputLocation?.geometry?.location?.lat(),
      ],
    }
    return { address, location }
  }
  return { location: inputLocation, address: inputAddress }
}

const productEntity = new schema.Entity('products')

const ProductService = {
  async list({ query = '', limit = 10, next, sortBy, sortOrder, statuses }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        statuses,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await http.get('products', config).json()
    const normalized = normalize(items, [productEntity])
    const entities = defaultTo({}, prop('products', normalized.entities))
    const order = defaultTo([], normalized.result)
    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },

  async read(id) {
    return http.get(`products/${id}`).json()
  },

  async update(id, payload) {
    const payloadToSend = { ...omit(['location', 'address'], payload) }
    Object.assign(payloadToSend, await parseGeoJSON(payload.location, payload.address))
    const config = {
      authorize: true,
      json: payloadToSend,
    }
    return http.patch(`products/${id}`, config).json()
  },

  async updateOptionsVariants(id, optionsVariants) {
    const config = {
      authorize: true,
      json: optionsVariants,
    }
    return http.patch(`products/${id}/optionsVariants`, config).json()
  },

  async createMedia(id, body) {
    const config = {
      authorize: true,
      json: body,
    }
    return http.post(`products/${id}/media/`, config).json()
  },

  async updateMedia(id, { id: mediaId, tags, htmlContent, mediaType }) {
    const config = {
      authorize: true,
      json: { tags, htmlContent, mediaType },
    }

    return http.patch(`products/${id}/media/${mediaId}`, config).json()
  },

  async deleteMedia(id, { id: mediaId }) {
    const config = {
      authorize: true,
    }

    return http.delete(`products/${id}/media/${mediaId}`, config).json()
  },

  async delete(id) {
    const config = {
      authorize: true,
    }
    const res = await http.delete(`products/${id}`, config).json()
    return res
  },

  async publish(id) {
    const config = {
      authorize: true,
    }
    const res = await http.post(`products/${id}/public`, config).json()
    return res
  },
}

export { ProductService }
