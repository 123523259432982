import { isNotNilOrEmpty, defaultTo } from '@soltalabs/ramda-extra'
import { Field as SlowField, FastField } from 'formik'
import { memo } from 'react'

import { RequiredIndicator } from './RequiredIndicator'
import { Tooltip } from './Tooltip'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('block'))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(
  s('inline-flex uppercase tracking-wide text-xs text-gray-600 font-light'),
  ({ isVisible }) => (!isVisible ? s('hidden') : {}),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('mr-2') : s('mb-2'))
)
const Input = styled.input(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ readOnly }) => (readOnly ? s('text-gray-700') : {})
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))
const CharacterLimit = styled.span(s('ml-a text-gray-500'))

const Memoized = memo(TextField)

function TextField({
  fast = true,
  readOnly,
  name,
  id,
  type = 'text',
  label,
  labelPosition = 'above',
  characterLimit = 0,
  tooltip,
  className,
  containerProps,
  showRequiredIndicator = false,
  ...props
}) {
  const Field = fast ? FastField : SlowField
  const useCharacterLimit = characterLimit > 0

  return (
    <Field name={name} id={id} type={type} {...props}>
      {/* eslint-disable-next-line complexity */}
      {({ field, meta }) => {
        const { value, onChange: onFieldChange, ...fieldProps } = field
        const { touched, error } = meta

        function handleChange(event) {
          if (useCharacterLimit && event.target.value.length > characterLimit) {
            return
          }

          onFieldChange(event)
        }

        return (
          <Root className={className} {...containerProps}>
            <InputContainer labelPosition={labelPosition}>
              <Label
                isVisible={isNotNilOrEmpty(label)}
                htmlFor={name || id}
                labelPosition={labelPosition}
              >
                {label}

                <RequiredIndicator showRequiredIndicator={showRequiredIndicator} />

                {tooltip && <Tooltip name={name} tooltip={tooltip} />}

                {useCharacterLimit && (
                  <CharacterLimit>
                    {characterLimit - defaultTo('')(value).length}
                  </CharacterLimit>
                )}
              </Label>

              <Input
                readOnly={readOnly}
                value={value}
                onChange={handleChange}
                {...fieldProps}
                {...props}
              />
            </InputContainer>

            {touched && error && <ErrorMessage>{error}</ErrorMessage>}
          </Root>
        )
      }}
    </Field>
  )
}

export { Memoized as TextField }
