import { omit, isNil } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import * as Validator from 'yup'

import { FullDescription } from 'components/common/FullDescription'
import { TagInput } from 'components/common/TagInput'
import { TextField } from 'components/common/TextField'
import { Categories } from 'components/listings/common/Categories'
import { GenderField } from 'components/listings/common/GenderField'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { DraftModule } from 'modules/listing/draft'
import { convertToHtml } from 'utils/convertSlateData'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)
const Root = styled.div(s('flex flex-column mt-6'))

const { object, string, array, number } = Validator
const noSpecialCharacters = string()
  .trim()
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special character')

const validationSchema = Validator.object({
  description: object({
    title: string().required('This field is required'),
    description: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    categories: array().required('Select at least one'),
    keywords: array().of(noSpecialCharacters),
    needToBring: array().of(noSpecialCharacters),
    whatToWear: array().of(noSpecialCharacters),
  }),
})

function DescriptionStep() {
  const { setFieldValue } = useFormContext()

  async function handleSubmit({ draftId, listingType, description }) {
    const data = omit(['description'], description)
    data.description = convertToHtml({ children: description.description.text })

    if (isNil(draftId)) {
      const id = await DraftModule.create(null, { listingType, ...data })
      setFieldValue('draftId', id)
      return
    }

    await DraftModule.update(null, draftId, data)
  }

  return (
    <Wizard.Step
      id="description"
      title="Description"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Scroll>
        <Root>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextField name="description.title" label="Listing title" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FullDescription
                name="description.description"
                label="Full description"
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Categories name="description.categories" label="Categories" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TagInput name="description.keywords" label="Keywords" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col>
              <GenderField name="description.genders" label="Gender" />
            </Col>
          </Row>
        </Root>
      </Scroll>
    </Wizard.Step>
  )
}

export { DescriptionStep }
