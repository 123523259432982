import { map, concat, filter, isEmpty, includes, reject } from '@soltalabs/ramda-extra'
import { format, addMinutes } from 'date-fns'

import { getRepeatEvents } from 'utils/getRepeatEvents'

function formatScheduledEvents(availabilityId, scheduledEvents) {
  return map(({ availabilityId: eventAvailabilityId, startAt, venueName, ...rest }) => {
    const start = new Date(startAt)

    const end = addMinutes(start, 0)

    const isThisAvailability = eventAvailabilityId === availabilityId

    return {
      title: `${venueName} - ${format(start, 'h:mm a').toLowerCase()}`,
      venueName,
      start,
      end,
      isThisAvailability,
      ...rest,
    }
  }, scheduledEvents)
}

function removeEditedEventsFromScheduledEvents(scheduledEvents, events) {
  const eventsNeedUpdate = filter(({ shouldUpdateEvent }) => shouldUpdateEvent)(events)

  if (isEmpty(eventsNeedUpdate)) {
    return scheduledEvents
  }

  const eventsIdNeedUpdate = map(({ id }) => id)(eventsNeedUpdate)
  const isIncludedInUpdateList = ({ id }) => includes(id)(eventsIdNeedUpdate)

  const filteredScheduledEvents = reject(isIncludedInUpdateList, scheduledEvents)

  return filteredScheduledEvents
}

function getDisplayableEvents(availabilityId, scheduledEvents, newEvents) {
  newEvents.forEach((event) => {
    // eslint-disable-next-line no-param-reassign
    event.end = event.start
  })
  const formattedScheduledEvents = formatScheduledEvents(
    availabilityId,
    scheduledEvents
  )

  const newEventsWithRepeatEvents = getRepeatEvents(newEvents)

  const scheduledEventsWithoutEditedEvents = removeEditedEventsFromScheduledEvents(
    formattedScheduledEvents,
    newEventsWithRepeatEvents
  )

  return map(
    (event) => ({ ...event, allDay: false }),
    concat(scheduledEventsWithoutEditedEvents, newEventsWithRepeatEvents)
  )
}

export { getDisplayableEvents }
