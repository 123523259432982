import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { CONFIG } from 'config'

const setupLogger = () => {
  Sentry.init({
    dsn: CONFIG.LOGGING.SENTRY_API_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      const { level } = event
      if (level === 'error') {
        return event
      }
      return null
    },
  })
}

export { setupLogger }
