const VENUE_TYPES = new Enum({
  FIXED: {
    value: 'venue_physical_fixed',
    readableValue: 'Fixed',
  },
  MOBILE: {
    value: 'venue_physical_mobile',
    readableValue: 'Mobile',
  },
  COMMUNITY: {
    value: 'venue_physical_community',
    readableValue: 'Community',
  },
  ONLINE: {
    value: 'venue_online',
    readableValue: 'Online',
  },
})

export { VENUE_TYPES }
