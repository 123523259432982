import {
  path,
  isNil,
  pipe,
  split,
  filter,
  join,
  isNotNilOrEmpty,
} from '@soltalabs/ramda-extra'
import { useField } from 'formik'

import { RequiredIndicator } from './RequiredIndicator'

import { styled, s } from 'lib/styled'

const CHAR_CODES = {
  ZERO: 48,
  NINE: 57,
}

const Root = styled.div(s('block'))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(
  s('uppercase tracking-wide text-xs text-gray-600 font-light'),
  ({ isVisible }) => (!isVisible ? s('hidden') : {}),
  ({ labelPosition }) => (labelPosition === 'inline' ? s('mr-2') : s('mb-2'))
)
const Input = styled.input(
  s(
    'w-full bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ disabled }) => (disabled ? s('text-gray-700') : {})
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

function NumberField({
  name,
  id,
  type = 'text',
  label,
  className,
  containerProps,
  showRequiredIndicator = false,
  ...props
}) {
  const [{ onChange, ...fieldProps }, { touched, error }] = useField({
    name,
    id,
    type,
    ...props,
  })

  function handleChange(event) {
    const value = path(['target', 'value'], event)

    if (isNil(value)) {
      return
    }

    const numbersOnly = pipe(
      split(''),
      filter((char) => {
        const charCode = char.charCodeAt(0)
        return charCode >= CHAR_CODES.ZERO && charCode <= CHAR_CODES.NINE
      }),
      join('')
    )(value)

    onChange({ target: { value: numbersOnly, name } })
  }

  return (
    <Root className={className} {...containerProps}>
      <InputContainer>
        <Label isVisible={isNotNilOrEmpty(label)} htmlFor={name || id}>
          {label} <RequiredIndicator showRequiredIndicator={showRequiredIndicator} />
        </Label>

        <Input onChange={handleChange} type={type} {...fieldProps} {...props} />
      </InputContainer>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { NumberField }
