/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { styled, s } from 'lib/styled'

const statusMapper = {
  Draft: 'bg-grey-lighter',
  'Awaiting Approval': 'bg-primary-lightest',
  Approved: 'bg-green-lightest',
  Suspended: 'bg-orange-lightest saturate-2',
  Rejected: 'bg-red-lightest saturate-2',
}

const StatusCell = styled(StyledCell)(
  s('rounded-md inline-block px-2 py-1'),
  ({ status }) => s(`${statusMapper[status]}`)
)

export const Columns = () =>
  useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'name',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '27%',
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '27%',
      },
      {
        Header: () => <StyledCell value="Status" />,
        accessor: 'status',
        id: 'status',
        width: '15%',
        textAlign: 'left',
        Cell: ({ value }) => <StatusCell status={value} value={value} />,
      },
      {
        Header: '',
        accessor: 'publish',
        id: 'pubButton',
        disableSortBy: true,
        width: '15%',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
      },
      {
        Header: '',
        accessor: 'delete',
        id: 'toolIcon',
        disableSortBy: true,
        width: '3%',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
      },
    ],
    []
  )
