import { prop, noop } from '@soltalabs/ramda-extra'
import { useLayoutEffect, useCallback } from 'react'

import { useWizard } from './context'

function Step({ id, title, validate, validationSchema, onSubmit = noop, children }) {
  const { currentStep, register } = useWizard()
  const handleSubmit = useCallback(onSubmit, [])

  useLayoutEffect(() => {
    register({ id, title, validate, validationSchema, onSubmit: handleSubmit })
  }, [id, title, validate, validationSchema, handleSubmit])

  if (prop('id', currentStep) !== id) {
    return null
  }

  return children
}

export { Step }
