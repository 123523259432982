import { useFormikContext as useFormContext } from 'formik'

import { TimezoneSelectField } from './TimezoneSelectField'

import { Button } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { TextField } from 'components/common/TextField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.section(s('p-6 bg-white rounded-lg shadow-sm'))
const Title = styled.span(s('tracking-wide text-black text-lg'))
const EditButton = styled(Button)(s('w-5'))

function AccountSettings({ isEditing, setIsEditing, isUpdatingProvider, timezones }) {
  const { resetForm } = useFormContext()

  const isUpdating = isUpdatingProvider

  return (
    <Root key={isEditing}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Title>Account Settings</Title>
        </Col>

        <Col span={12}>
          <Row style={s('justify-end')}>
            {isUpdating && (
              <Spinner style={s('mr-2')} size={10} thickness={3} color="#FFFFFF" />
            )}

            {isEditing || isUpdating ? (
              <div>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    resetForm()
                    setIsEditing(false)
                  }}
                >
                  Cancel
                </Button>

                <EditButton type="submit">Save</EditButton>
              </div>
            ) : (
              <EditButton
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                Edit
              </EditButton>
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TextField readOnly name="taxCountry" label="Country" />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TextField readOnly name="language" type="text" label="Language" />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <TimezoneSelectField
            readOnly={!isEditing}
            name="timezone"
            label="Timezone"
            timezones={timezones}
          />
        </Col>
      </Row>
    </Root>
  )
}

export { AccountSettings }
