/* eslint-disable complexity */
import { createTheme, ThemeProvider } from '@material-ui/core'
import TextInput from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { isNotNilOrEmpty, noop, omit } from '@soltalabs/ramda-extra'
import { format } from 'date-fns'
import { useField } from 'formik'

import { ReactComponent as CalendarIcon } from 'assets/feathers/calendar.svg'
import { styled, s } from 'lib/styled'
import { setTimeComponent } from 'utils/date/setTimeComponent'

const Root = styled.div(s(''))
const InputContainer = styled.div(s('flex'), ({ labelPosition }) =>
  labelPosition === 'inline' ? s('flex-row items-center') : s('flex-column')
)
const Label = styled.label(s('mb-2 uppercase tracking-wide text-xs text-gray-600'))
const Input = styled(TextInput)(
  s(
    'bg-gray-200 text-base text-black border-0 border-b-2 border-solid border-gray-500 rounded-lg px-3 py-2'
  ),
  ({ disabled }) => (disabled ? s('bg-gray-400 border-gray-700 text-gray-600') : {}),
  ({ readOnly }) =>
    readOnly
      ? s('bg-gray-400 border-gray-700', {
          '& .MuiInputBase-input': s('text-gray-800'),
        })
      : {},

  {
    '& .MuiFormHelperText-root': s('hidden'),
    '& .MuiInputBase-root::before': s('hidden'),
    '& .MuiInputBase-root::after': s('hidden'),
    '& .MuiInputBase-input': s('p-0 text-sm h-a'),
    '& *': {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }
)

const datePickerTheme = createTheme({
  typography: {
    fontFamily: ['Open Sans'],
  },
  overrides: {
    MuiPickersDay: {
      day: {
        '&:focus': s('bg-primary text-white'),
      },
      daySelected: {
        ...s('bg-primary text-white'),
        '&:hover': {
          backgroundColor: '#9E2AA4',
        },
      },
      current: s('bg-primary text-white'),
    },
  },
})

const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

function DateField({
  disabled,
  readOnly,
  name,
  id,
  label,
  labelPosition,
  containerProps,
  pickerProps,
  minDate = undefined,
  ...props
}) {
  const [{ value }, { touched, error }, { setValue: setFieldValue }] = useField({
    name,
    id,
  })

  function handleChange(date) {
    setFieldValue(preservePreviousTimeOfDay(date))
  }

  function preservePreviousTimeOfDay(date) {
    return setTimeComponent(value, date)
  }

  return (
    <Root {...containerProps}>
      <InputContainer labelPosition={labelPosition}>
        <Label
          isVisible={isNotNilOrEmpty(label)}
          htmlFor={name || id}
          labelPosition={labelPosition}
        >
          {label}
        </Label>

        <ThemeProvider theme={datePickerTheme}>
          <DatePicker
            disabled={disabled || readOnly}
            value={value}
            minDate={minDate}
            inputFormat="dd/MM/yyyy"
            onAccept={handleChange}
            onChange={noop}
            renderInput={(props) => {
              const inputProps = omit(['inputProps'])(props)
              inputProps.inputProps = { readOnly: true, type: 'tel' }

              return (
                <Input
                  name={name}
                  id={id}
                  readOnly={readOnly}
                  value={format(new Date(value), 'dd/MM/yyyy')}
                  {...inputProps}
                />
              )
            }}
            openPickerIcon={
              !readOnly && <CalendarIcon width={18} height={18} stroke="#686868" />
            }
            {...props}
          />
        </ThemeProvider>
      </InputContainer>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { DateField }
