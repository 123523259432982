import { equals, isNotNilOrEmpty, isNotNil } from '@soltalabs/ramda-extra'

import { VENUE_TYPES } from './constants'

import { CONFIG } from 'config'
import { http } from 'lib/http-client'
import { authModule } from 'modules/auth'
import { createAddressObject } from 'utils/createAddressObject'
import { wallet } from 'wallet'

const service = http.extend({
  prefixUrl: CONFIG.API.URL,
  hooks: {
    beforeRequest: [
      async (request, options) => {
        const requireAuthorization = equals(false, options.authorize)
        if (requireAuthorization) {
          return
        }

        const token = await wallet.getToken()
        const { currentProvider } = authModule.getState()

        request.headers.set('Authorization', `Bearer ${token}`)
        request.headers.set('playz-provider', currentProvider)

        // eslint-disable-next-line
        delete options.authorize
      },
    ],
  },
})

const VenueService = {
  async list() {
    const config = {
      searchParams: {
        limit: 999,
      },
    }
    return service.get('venues', config).json()
  },

  async createPhysical({
    name,
    type,
    address: addressEntity,
    timezone,
    hasWheelchairAccess,
    transportNotes,
    parkingNotes,
  }) {
    const payload = {
      name,
      venueType: type,
      address: createAddressObject(addressEntity),
      addressLocation: {
        type: 'Point',
        coordinates: [
          addressEntity?.geometry?.location?.lng(),
          addressEntity?.geometry?.location?.lat(),
        ],
      },
      timezone,
      wheelchairAccessible: hasWheelchairAccess,
    }

    if (isNotNilOrEmpty(transportNotes)) {
      payload.transports = [transportNotes]
    }

    if (isNotNilOrEmpty(parkingNotes)) {
      payload.parkings = [parkingNotes]
    }

    const config = {
      json: payload,
    }

    return service.post('venues', config).json()
  },

  async updatePhysical(
    id,
    {
      name,
      address: addressEntity,
      timezone,
      hasWheelchairAccess,
      transportNotes,
      parkingNotes,
    }
  ) {
    const payload = {
      name,
      timezone,
      wheelchairAccessible: hasWheelchairAccess,
      transports: isNotNilOrEmpty(transportNotes) ? [transportNotes] : [],
      parkings: isNotNilOrEmpty(parkingNotes) ? [parkingNotes] : [],
    }

    if (isNotNil(addressEntity)) {
      payload.address = createAddressObject(addressEntity)
      payload.addressLocation = {
        type: 'Point',
        coordinates: [
          addressEntity?.geometry?.location?.lng(),
          addressEntity?.geometry?.location?.lat(),
        ],
      }
    }

    const config = {
      json: payload,
    }

    return service.patch(`venues/${id}`, config).json()
  },

  async createOnline({ link, passcode, timezone }) {
    const placeholderName = `Online activity - ${Date.now()}`
    const payload = {
      name: placeholderName,
      venueType: VENUE_TYPES.valueOf(VENUE_TYPES.ONLINE),
      address: {
        formattedAddress: link,
      },
      timezone,
    }

    if (isNotNilOrEmpty(passcode)) {
      payload.passcode = passcode
    }

    const config = {
      json: payload,
    }

    return service.post('venues', config).json()
  },

  async updateOnline(onlineVenueId, { link, passcode, timezone }) {
    const placeholderName = `Online activity - ${Date.now()}`
    const payload = {
      name: placeholderName,
      venueType: VENUE_TYPES.valueOf(VENUE_TYPES.ONLINE),
      address: {
        formattedAddress: link,
      },
      timezone,
    }

    if (isNotNilOrEmpty(passcode)) {
      payload.passcode = passcode
    }

    const config = {
      json: payload,
    }

    return service.patch(`venues/${onlineVenueId}`, config).json()
  },

  async deletePhysical(id) {
    const config = {
      authorize: true,
    }

    return service.delete(`venues/${id}`, config).json()
  },
}

export { VenueService }
