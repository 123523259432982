import { connect } from '@soltalabs/stateless'

import { AwaitingApprovalPrompt } from './AwaitingApprovalPrompt'
import { GoLivePrompt } from './GoLivePrompt'
import { ListingPublishedPrompt } from './ListingPublishedPrompt'

import { styled, s } from 'lib/styled'
import { LISTING_STATUS } from 'modules/listing'
import { draftStatus$ } from 'modules/listing/draft'

const Root = styled.div(s('flex flex-column flex-1 w-full items-center pt-24'))
const Icon = styled.span(s('mb-12'), { fontSize: 120 })

const Connected = connect(() => ({
  status: draftStatus$,
}))(View)

function View({ status }) {
  const isDraft = status === LISTING_STATUS.DRAFT
  const isAwaitingApproval = status === LISTING_STATUS.AWAITING_APPROVAL
  const isListed = status === LISTING_STATUS.LISTED

  return (
    <Root>
      <Icon>🎉</Icon>

      {isDraft && <GoLivePrompt />}
      {isAwaitingApproval && <AwaitingApprovalPrompt />}
      {isListed && <ListingPublishedPrompt />}
    </Root>
  )
}

export { Connected as View }
