/* eslint-disable complexity */
import {
  prop,
  isNotNil,
  partition,
  has,
  isNotEmpty,
  isNil,
} from '@soltalabs/ramda-extra'

import { MEDIA_TYPES } from 'modules/listing'
import { getMedia } from 'utils/getMedia'

function findPhotosNeedUpdateOrCreate(initialPhotos) {
  return function (submitPhoto) {
    return (
      initialPhotos.filter(
        (initialPhoto) =>
          isNotNil(initialPhoto.content) && initialPhoto.tags === submitPhoto.tags
      ).length === 0
    )
  }
}

function findPhotosNeedDelete(submitPhotos) {
  return function (initialPhoto) {
    return (
      submitPhotos.filter((submitPhoto) => submitPhoto.content === initialPhoto.content)
        .length === 0
    )
  }
}

function getDeleteList(media, initialMedia) {
  const deleteMediaList = []
  const { photos, video, brochure } = media
  const {
    photos: initialPhotos,
    video: initialVideo,
    brochure: initialBrochure,
  } = initialMedia

  const photosNeedDelete = initialPhotos.filter(findPhotosNeedDelete(photos))

  if (isNotNil(initialVideo) && isNil(prop('content', video))) {
    deleteMediaList.push(initialVideo)
  }

  if (isNotNil(initialBrochure) && isNil(prop('content', brochure))) {
    deleteMediaList.push(initialBrochure)
  }

  return isNotEmpty(photosNeedDelete)
    ? [...deleteMediaList, ...photosNeedDelete]
    : deleteMediaList
}

function getCreateList(photos, video, brochure) {
  const createList = []

  if (isNotEmpty(photos)) {
    createList.push(
      ...photos.map((photo) => ({
        mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.PHOTO),
        content: photo.uploadId,
        tags: isNotEmpty(photo.tags) ? photo.tags : undefined,
      }))
    )
  }

  if (isNotNil(prop('uploadId', video))) {
    createList.push({
      mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.VIDEO),
      content: video.uploadId,
    })
  }

  if (isNotNil(prop('uploadId', brochure))) {
    createList.push({
      mediaType: MEDIA_TYPES.valueOf(MEDIA_TYPES.BROCHURE),
      content: brochure.uploadId,
    })
  }

  return createList
}

function getSubmitMedia(listing, submitMedia) {
  const { photos, video, brochure } = submitMedia

  const initialMedia = getMedia(listing)

  const deleteList = getDeleteList(submitMedia, initialMedia)

  const photosNeedUpdateOrCreate = photos.filter(
    findPhotosNeedUpdateOrCreate(initialMedia.photos)
  )

  const [updateList, photosNeedCreate] = partition(
    has('content'),
    photosNeedUpdateOrCreate
  )

  const createList = getCreateList(photosNeedCreate, video, brochure)

  return { updateList, createList, deleteList }
}

export { getSubmitMedia }
