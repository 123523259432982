import {
  prop,
  defaultTo,
  isNotNil,
  pipe,
  pick,
  pickThenRename,
  omitBy,
  isNilOrEmpty,
  isNotEmpty,
  head,
} from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { LISTING_TYPES } from './constants'

import { http } from 'httpClient'

const listingEntity = new schema.Entity('listings')

const ListingService = {
  async list({ query = '', limit = 10, next, sortBy, sortOrder, statuses, from, to }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        statuses,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    if (isNotNil(from)) {
      config.searchParams.from = from
    }

    if (isNotNil(to)) {
      config.searchParams.to = to
    }

    const { items, paging } = await http.get('listings', config).json()

    const normalized = normalize(items, [listingEntity])
    const entities = defaultTo({}, prop('listings', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },

  async read(id) {
    const listing = await http.get(`listings/${id}`).json()

    const listingType = prop('listingType')(listing)
    const availabilities = prop('availabilities')(listing)

    if (
      listingType === LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY) &&
      isNotEmpty(availabilities)
    ) {
      const availabilityId = prop('id')(head(availabilities))
      const { serie, startDate, endDate, note } =
        await ListingService.listCommunityEvents(availabilityId)

      listing.availabilities = [
        { serie, startDate, endDate, note, ...head(availabilities) },
      ]
    }

    return listing
  },

  async attachMedia(id, data) {
    const payload = pipe(
      pick(['mediaType', 'content', 'htmlContent', 'tags']),
      omitBy(isNilOrEmpty)
    )(data)

    const config = {
      json: payload,
    }

    return http.post(`listings/${id}/media`, config).json()
  },

  async publish(id) {
    return http.post(`listings/${id}/public`).json()
  },

  async update(id, body) {
    const payload = pipe(
      pickThenRename(
        [
          'title',
          'description',
          'categories',
          'keywords',
          'needToBring',
          'whatToWear',
          'genders',
        ],
        {
          needToBring: 'itemsToBring',
          whatToWear: 'itemsToWear',
        }
      )
    )(body)

    const config = {
      authorize: true,
      json: payload,
    }
    return http.patch(`listings/${id}`, config).json()
  },

  async createMedia(id, body) {
    const config = {
      authorize: true,
      json: body,
    }

    return http.post(`listings/${id}/media/`, config).json()
  },

  async updateMedia(id, { id: mediaId, tags, htmlContent, mediaType }) {
    const config = {
      authorize: true,
      json: { tags, htmlContent, mediaType },
    }

    return http.patch(`listings/${id}/media/${mediaId}`, config).json()
  },

  async deleteMedia(id, { id: mediaId }) {
    const config = {
      authorize: true,
    }

    return http.delete(`listings/${id}/media/${mediaId}`, config).json()
  },

  async deleteDraftListing(id) {
    const config = {
      authorize: true,
    }

    return http.delete(`listings/${id}`, config).json()
  },

  async listCommunityEvents(availabilityId) {
    const searchParams = {
      availability: availabilityId,
    }

    const config = { searchParams }

    return http.get('community-listing-events', config).json()
  },
}

export { ListingService }
