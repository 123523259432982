import { map, pick, find, propEq, isNotNil, defaultTo } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'
import { schema, normalize } from 'normalizr'

import { AvailabilityService } from './service'

import { listingEventModule as ListingEventModule } from 'modules/listing/event'

const INITIAL_STATE = Object.freeze({
  entities: '',
  order: [],
})

const availabilitySchema = new schema.Entity('availabilities')

const fetchAvailabilities = (module) => async () => {
  const { items } = await AvailabilityService.list()
  const normalized = normalize(items, [availabilitySchema])
  const { availabilities } = normalized.entities
  const order = normalized.result

  module.setState({
    entities: availabilities,
    order,
  })
}

const readAvailabilities = () => async (_, listingId) =>
  AvailabilityService.read(listingId)

const createOrUpdateAvailability = () => async (_, data) => {
  const { listingId, venueId } = data

  const { items: availabilities } = await AvailabilityService.read(listingId)

  const availability = find(propEq('venue', venueId))(availabilities)

  if (isNotNil(availability)) {
    const payload = pick(['totalSpots', 'contact'], data)
    payload.availabilityId = availability.id

    return AvailabilityService.update(payload)
  }
  const payload = pick(['listingId', 'venueId', 'totalSpots', 'contact'], data)

  return AvailabilityService.create(payload)
}

const updateAvailabilityWithPricingOptions =
  () => async (availabilityId, pricingOptions) => {
    await AvailabilityService.deletePricings(availabilityId)

    await Promise.all(
      map(
        async (pricingOption) =>
          AvailabilityService.createPricingOption(
            availabilityId,
            pick(['name', 'description', 'spots', 'price'], pricingOption)
          ),
        pricingOptions
      )
    )
  }

const updateAvailabilityWithEvents = () => async (availabilityId, events) => {
  async function persistEvent(event) {
    if (defaultTo(false)(event.shouldUpdateEvent)) {
      return AvailabilityService.updateEvent(
        pick(['id', 'timespanInMinutes', 'bookingWindow', 'notes'], event)
      )
    }
    return AvailabilityService.createEvent(
      availabilityId,
      pick(
        [
          'startDate',
          'endDate',
          'recurrence',
          'recurrenceEnd',
          'bookingWindow',
          'notes',
        ],
        event
      )
    )
  }

  await Promise.all(map(persistEvent, events))
  await ListingEventModule.fetchEvents()
}

const updateCommunityAvailabilityWithEvents =
  () =>
  async (availabilityId, { eventSerieId, noDateRange, startDate, endDate, note }) => {
    if (eventSerieId) {
      await AvailabilityService.deleteCommunityEvents(eventSerieId)
    }

    await AvailabilityService.createCommunityEvents(availabilityId, {
      noDateRange,
      startDate,
      endDate,
      note,
    })
  }

const deleteAvailabilities = () => async (_, availabilities) => {
  await Promise.all(
    map(async ({ id: availabilityId }) => AvailabilityService.delete(availabilityId))(
      availabilities
    )
  )
}

const availabilityModule = createModule({
  name: 'availability',
  initialState: INITIAL_STATE,
  decorators: {
    fetchAvailabilities,
    readAvailabilities,
    createOrUpdateAvailability,
    deleteAvailabilities,
    updateAvailabilityWithPricingOptions,
    updateAvailabilityWithEvents,
    updateCommunityAvailabilityWithEvents,
  },
})

export { availabilityModule }
