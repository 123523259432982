import { Paragraph } from './Paragraph'

function AwaitingApprovalPrompt() {
  return (
    <>
      <Paragraph>Your listing has been submitted for approval.</Paragraph>
      <Paragraph>
        This process can take up to 48 hours. You will receive an email once your
        listing goes live.
      </Paragraph>
      <Paragraph>Thank you for being a part of the Bula Platform!</Paragraph>
    </>
  )
}

export { AwaitingApprovalPrompt }
