import { pluck } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import { WithContext as ReactTags } from 'react-tag-input'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('block w-full'))
const InputContainer = styled.div(
  s(
    'py-1 px-2 border-0 border-solid border-b-2 border-gray-500 rounded-lg bg-gray-200'
  ),
  {
    minHeight: '40px',
    '& .ReactTags__selected': s('w-full flex flex-row flex-wrap'),
    '& .ReactTags__tagInput': s('flex-1'),
    '& .ReactTags__tagInputField': s(
      'w-full rounded-lg h-2 m-0 border-0 ml-2 bg-gray-200'
    ),
    '& .ReactTags__tags': s('relative'),
    '& .ReactTags__tag': s(
      'inline-flex flex-row items-center self-center pl-2 border-0 rounded-full mr-3 bg-white font-semibold bg-primary-lightest'
    ),
    '& .ReactTags__remove': s(
      'py-1 px-2 text-gray-800 font-bold border-0 rounded-full bg-primary-lightest',
      {
        cursor: 'pointer',
      }
    ),
  },
  ({ readOnly }) =>
    readOnly
      ? s('text-gray-700', {
          '& .ReactTags__tag': s('my-1 pr-2 py-1'),
        })
      : {}
)
const Label = styled.label(
  s('inline-block uppercase tracking-wide text-xs text-gray-600 mb-2')
)
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const Keys = {
  TAB: 9,
  ENTER: 13,
  COMMA: 188,
}

function TagInput({ name, id, label, readOnly, ...props }) {
  const [fieldProps, { touched, error }] = useField({ name, id, ...props })
  const { value, onChange } = fieldProps

  const tags = value ? value.map((tag) => ({ id: tag, text: tag })) : []
  function handleDelete(i) {
    const newTag = tags.filter((tag, index) => index !== i)
    const event = { target: { value: pluck('text', newTag), name } }
    onChange(event)
  }
  function handleAdd(tag) {
    if (tags.length === 10) {
      return
    }
    const newTag = [...tags, tag]
    const event = { target: { value: pluck('text', newTag), name } }
    onChange(event)
  }
  return (
    <Root>
      <Label>{label} (limit 10 tags)</Label>
      <InputContainer readOnly={readOnly}>
        <ReactTags
          tags={tags}
          autofocus={false}
          maxLength={20}
          allowDragDrop={false}
          handleDelete={(i) => handleDelete(i)}
          handleAddition={(tag) => handleAdd(tag)}
          delimiters={[Keys.TAB, Keys.ENTER, Keys.COMMA]}
          readOnly={readOnly}
        />
      </InputContainer>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { TagInput }
