import {
  pick,
  map,
  pickThenRename,
  filter,
  isNotEmpty,
  isNil,
  isNotNil,
} from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { ListingService } from '../service'

import { DraftService } from './service'

import { availabilityModule as AvailabilityModule } from 'modules/availability'
import { VENUE_TYPES, venueModule as VenueModule } from 'modules/venue'

const INITIAL_STATE = Object.freeze({
  entity: undefined,
  draftId: undefined,
})

const pickDraftProps = pick([
  'listingType',
  'title',
  'description',
  'categories',
  'keywords',
  'needToBring',
  'whatToWear',
  'genders',
])

const read = () => async (id) => ListingService.read(id)

const create = (module) => async (_, data) => {
  const listing = await DraftService.create(pickDraftProps(data))

  module.setState({
    entity: listing,
    draftId: listing.id,
  })

  return listing.id
}

const update = (module) => async (_, id, data) => {
  const listing = await ListingService.update(id, data)

  module.setState({
    entity: listing,
    draftId: listing.id,
  })
}

const updateListingMedia =
  (module) =>
  async (_, { updateList, createList, deleteList }) => {
    const { draftId } = module.getState()

    await Promise.all([
      ...map(
        (media) =>
          ListingService.updateMedia(
            draftId,
            pick(['id', 'tags', 'htmlContent', 'mediaType'], media)
          ),
        updateList
      ),
      ...map(
        (media) =>
          ListingService.createMedia(
            draftId,
            pick(['mediaType', 'content', 'tags', 'htmlContent'], media)
          ),
        createList
      ),
      ...map(
        (media) => ListingService.deleteMedia(draftId, pick(['id'], media)),
        deleteList
      ),
    ])
  }

const createOnlineAvailability = (module) => async (_, onlineActivity) => {
  const { draftId } = module.getState()

  const venueId = await resolveVenueId(onlineActivity)

  const { pricing, contact, events } = onlineActivity

  const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
    listingId: draftId,
    venueId,
    totalSpots: pricing.totalSpots,
    contact: pickThenRename(
      ['name', 'phoneNumber', 'email'],
      { phoneNumber: 'phone' },
      contact
    ),
  })

  await Promise.all([
    AvailabilityModule.updateAvailabilityWithPricingOptions(
      availability.id,
      pricing.options
    ),

    AvailabilityModule.updateAvailabilityWithEvents(
      availability.id,
      map(
        pickThenRename(
          [
            'id',
            'startDate',
            'endDate',
            'reoccurrence',
            'reoccurrenceEnd',
            'bookingWindow',
            'notes',
            'shouldUpdateEvent',
            'timespanInMinutes',
          ],
          {
            reoccurrence: 'recurrence',
            reoccurrenceEnd: 'recurrenceEnd',
          }
        ),
        events
      )
    ),
  ])

  async function resolveVenueId(onlineActivity) {
    const { venueId, conference } = pick(['venueId', 'conference'])(onlineActivity)

    if (isNil(venueId)) {
      const onlineVenue = await VenueModule.createOnlineVenue(null, conference)
      return onlineVenue.id
    }

    await VenueModule.updateOnlineVenue(null, venueId, conference)
    return venueId
  }
}

const createCommunityAvailability = (module) => async (_, communityActivity) => {
  const { draftId } = module.getState()

  const venueId = await resolveVenueId(communityActivity)

  const { pricing, contact, eventSerieId, noDateRange, startDate, endDate, note } =
    communityActivity

  const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
    listingId: draftId,
    venueId,
    contact: pickThenRename(
      ['name', 'phoneNumber', 'email'],
      { phoneNumber: 'phone' },
      contact
    ),
  })
  await Promise.all([
    AvailabilityModule.updateAvailabilityWithPricingOptions(
      availability.id,
      pricing.options
    ),
    AvailabilityModule.updateCommunityAvailabilityWithEvents(availability.id, {
      eventSerieId,
      noDateRange,
      startDate,
      endDate,
      note,
    }),
  ])

  async function resolveVenueId(communityActivity) {
    const { venueId, location } = pick(['venueId', 'location'])(communityActivity)

    if (isNil(venueId)) {
      const communityVenue = await VenueModule.createPhysicalVenue(
        null,
        {
          type: VENUE_TYPES.valueOf(VENUE_TYPES.COMMUNITY),
          ...location,
        },
        { fetchNewVenues: false }
      )

      return communityVenue.id
    }

    await VenueModule.updatePhysicalVenue(null, venueId, location, {
      fetchNewVenues: false,
    })
    return venueId
  }
}

const createPhysicalAvailabilities =
  (module) =>
  async (_, availabilities = [], mappedAvailabilities) => {
    const { draftId } = module.getState()

    // delete unselected availability
    const mappedAvailabilitiesNeedDelete = filter(
      ({ isSelected, id }) => !isSelected && isNotNil(id)
    )(availabilities)

    if (isNotEmpty(mappedAvailabilitiesNeedDelete)) {
      await AvailabilityModule.deleteAvailabilities(
        null,
        mappedAvailabilitiesNeedDelete
      )
    }

    await Promise.all(
      mappedAvailabilities.map(persistAvailability, mappedAvailabilities)
    )

    async function persistAvailability({ activity, pricing, events }) {
      const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
        listingId: draftId,
        venueId: activity.venueId,
        totalSpots: pricing.totalSpots,
        contact: pickThenRename(
          ['name', 'phoneNumber', 'email'],
          { phoneNumber: 'phone' },
          activity.contact
        ),
      })

      await Promise.all([
        AvailabilityModule.updateAvailabilityWithPricingOptions(
          availability.id,
          pricing.options
        ),

        AvailabilityModule.updateAvailabilityWithEvents(
          availability.id,
          map(
            pickThenRename(
              [
                'id',
                'startDate',
                'endDate',
                'reoccurrence',
                'reoccurrenceEnd',
                'bookingWindow',
                'notes',
                'shouldUpdateEvent',
                'timespanInMinutes',
              ],
              {
                reoccurrence: 'recurrence',
                reoccurrenceEnd: 'recurrenceEnd',
              }
            ),
            events
          )
        ),
      ])
    }
  }

const createLegalDocuments = (module) => async (_, documents) => {
  const { draftId } = module.getState()

  await Promise.all(
    map((document) => DraftService.createMedia(draftId, document), documents)
  )
}

const publish = (module) => async () => {
  const { draftId } = module.getState()

  const res = await DraftService.publish(draftId)
  const listing = await ListingService.read(draftId)

  module.setState({
    entity: listing,
  })
  return res
}

const DraftModule = createModule({
  name: 'listing/draft',
  initialState: INITIAL_STATE,
  decorators: {
    read,
    create,
    update,
    updateListingMedia,
    createOnlineAvailability,
    createCommunityAvailability,
    createPhysicalAvailabilities,
    createLegalDocuments,
    publish,
  },
})

export { DraftModule }
