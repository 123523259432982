import { omit, isNil, values } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import * as Validator from 'yup'

import { Details } from '../common/ProductForms/Details'

import { Wizard } from 'lib/formik-wizard'
import { PRODUCT_STATUSES, PRODUCT_STATUS_LABELS } from 'modules/product'
import { ProductDraftModule } from 'modules/product/draft'
import { convertToHtml } from 'utils/convertSlateData'

const { object, string, number, array } = Validator

const noSpecialCharacters = string()
  .trim()
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special character')

const validationSchema = Validator.object({
  details: object({
    name: string().required('This field is required'),
    description: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    address: object(),
    location: object().required('This field is required'),
    summary: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    price: number().required('This field is required'),
    keywords: array().of(noSpecialCharacters),
    categories: array().required('Please select at least one category'),
    status: object().shape({
      label: string()
        .required('This field is required')
        .oneOf(values(PRODUCT_STATUS_LABELS)),
      value: string()
        .required('This field is required')
        .oneOf(values(PRODUCT_STATUSES)),
    }),
  }),
})

function DetailsStep() {
  const { setFieldValue } = useFormContext()

  async function handleSubmit({ draftId, details }) {
    const data = omit(['description', 'summary'], details)
    data.description = convertToHtml({ children: details.description.text })
    data.summary = convertToHtml({ children: details.description.text })
    if (isNil(draftId)) {
      const id = await ProductDraftModule.create(null, data)
      setFieldValue('draftId', id)
      return
    }

    await ProductDraftModule.update(null, draftId, data)
  }

  return (
    <Wizard.Step
      id="details"
      title="Details"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Details isEditing={true} />
    </Wizard.Step>
  )
}

export { DetailsStep }
