import { pathOr, path, prop, and } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { authModule } from './module'

import { hasOnboardedProvider$ } from 'modules/provider'

const { state$, effectState$ } = authModule

const isCheckingOnboardingStatus$ = select(effectState$, (state) => {
  const status = pathOr('pending', ['checkOnboardingStatus', 'status'], state)

  return status === 'pending'
})

const resendVerificationEmailStatus$ = select(effectState$, (state) =>
  path(['resendVerificationEmail', 'status'], state)
)

const isSocialAccount$ = select(effectState$, (state) =>
  path(['account', 'isSocial'], state)
)

const hasVerifiedEmail$ = select(state$, (state) =>
  path(['user', 'email_verified'], state)
)

const hasPlayzAccount$ = select(state$, path(['account', 'hasPlayzAccount']))
const hasOnboarded$ = select(hasPlayzAccount$, hasOnboardedProvider$, and)

const emailAddress$ = select(state$, (state) => path(['user', 'email'], state))
const account$ = select(state$, (state) => prop('account', state))
const firstName$ = select(account$, (account) => prop('firstName', account) || '')
const lastName$ = select(account$, (account) => prop('lastName', account) || '')
const phoneNumber$ = select(account$, (account) => prop('mobile', account) || '')
const accountEmail$ = select(account$, (account) => prop('email', account) || '')
const fullName$ = select(account$, (account) => prop('fullName', account) || '')
const avatar$ = select(account$, (state) => prop('avatar', state))
const isUploadingAvatar$ = select(effectState$, (state) => {
  const status = path(['uploadAvatar', 'status'], state)

  return status === 'pending'
})
const uploadedAvatarId$ = select(state$, (state) => path(['uploads', 'avatar'], state))
const isUpdatingAccount$ = select(effectState$, (effectState) => {
  const updateAccountStatus = path(['updateAccount', 'status'], effectState)
  return updateAccountStatus === 'pending'
})

export {
  isCheckingOnboardingStatus$,
  resendVerificationEmailStatus$,
  isSocialAccount$,
  hasOnboarded$,
  hasVerifiedEmail$,
  emailAddress$,
  firstName$,
  lastName$,
  fullName$,
  phoneNumber$,
  avatar$,
  isUploadingAvatar$,
  uploadedAvatarId$,
  account$,
  isUpdatingAccount$,
  accountEmail$,
  hasPlayzAccount$,
}
