import { RichTextField } from 'components/common/withRichText'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex flex-column w-full'))
const Label = styled.label(s('mb-2 uppercase tracking-wide text-xs text-gray-600'))

function FullDescription({ name, id, label, ...props }) {
  return (
    <Root>
      <Label htmlFor={name || id}>{label}</Label>
      <RichTextField
        name={name}
        id={id}
        {...props}
        editableStyle={s('mx-6 min-h-12 text-base')}
      />
    </Root>
  )
}

export { FullDescription }
