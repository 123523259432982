import Modal from 'react-modal'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('text-black bg-white rounded-lg p-6 pb-2'))

const Title = styled.h2(s('text-xl font-semibold mt-2 mb-4 px-2'))

const Message = styled.div(s('text-base py-1 px-2'))

const ErrorMessage = styled.div(s('text-base py-1 px-2 text-center'), { color: 'red' })

const CancelButton = styled(ButtonBase)(s('w-5 mx-1 bg-gray-300'))

const ConfirmButton = styled(ButtonBase)(
  s('w-5 mr-1 bg-red-lightest border-red-lighter'),
  {
    '&:active': s('bg-red-lighter border-red-light'),
  }
)

Modal.setAppElement('#root')

function DeleteProductModal({
  isOpen,
  setIsOpen,
  onDeleteProduct,
  isDeletingProduct,
  shouldShowError,
}) {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Container>
        <Row justify="center">
          <Col>
            <Root>
              <Title>Delete Product</Title>
              <Message>{'Are you sure you want to delete this product?'}</Message>

              <Row gutter={[16, 16]} style={s('mt-5')}>
                <Col span={12}>
                  <CancelButton
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      setIsOpen(false)
                    }}
                  >
                    Cancel
                  </CancelButton>
                </Col>

                <Col span={12} style={s('text-right')}>
                  <Row style={s('justify-end')}>
                    {isDeletingProduct && (
                      <Spinner size={18} thickness={3} style={s('mr-1')} />
                    )}

                    <ConfirmButton type="button" onClick={onDeleteProduct}>
                      Delete
                    </ConfirmButton>
                  </Row>
                </Col>
              </Row>
              {shouldShowError && (
                <>
                  <ErrorMessage>
                    {'Unable to delete product at this time.'}
                  </ErrorMessage>
                  <ErrorMessage>{'Please try again later.'}</ErrorMessage>
                </>
              )}
            </Root>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export { DeleteProductModal }
