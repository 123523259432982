import { path } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext, FieldArray } from 'formik'
import { useEffect, useState } from 'react'

import { ReactComponent as TrashCan } from 'assets/feathers/trash-can.svg'
import { TextField } from 'components/common/TextField'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const FlexDiv = styled.div(s('flex flex-column mt-2 mb-2 ml-2 mr-2'))

const Input = styled.input(
  s(
    'bg-gray-200 text-sm text-black border-0 border-b-2 border-gray-500 rounded-lg px-3 py-2 mr-a mt-2 mb-4'
  )
)
const ErrorMessage = styled.span(s('text-red mr-a ml-a'))

function ProductOptionsValues({ option, optionIndex, isEditing }) {
  const { values, errors } = useFormContext()
  const [lastValueField, setLastValueField] = useState(null)
  const optValError = path(
    ['optionsAndVariants', 'options', optionIndex, 'values'],
    errors
  )

  useEffect(() => {
    lastValueField?.focus()
  }, [lastValueField])

  function setValueFieldRefCallback(element, index) {
    if (index === values.optionsAndVariants.options[optionIndex].values.length - 1) {
      setLastValueField(element)
    }
  }

  return (
    <FieldArray
      name={`optionsAndVariants.options[${optionIndex}].values`}
      render={(optionValueArrayHelpers) => (
        <FlexDiv>
          {option.values.map((value, index) => (
            <Row gutter={[16, 16]} key={index}>
              <Col>
                <TextField
                  name={`optionsAndVariants.options[${optionIndex}].values[${index}]`}
                  label="Option Value"
                  fast={false}
                  readOnly={!isEditing}
                  innerRef={(e) => setValueFieldRefCallback(e, index)}
                />
              </Col>
              <Col>
                {isEditing && (
                  <TrashCan
                    onClick={() => optionValueArrayHelpers.remove(index)}
                    width={28}
                    height={28}
                    style={s('mt-5', { cursor: 'pointer' })}
                  />
                )}
              </Col>
            </Row>
          ))}
          <Row>
            <Col>
              <Input
                key={values.optionsAndVariants.options[optionIndex].values.length}
                placeholder="New Option Value"
                onChange={(e) => optionValueArrayHelpers.push(e.target.value)}
                disabled={!isEditing}
              />
            </Col>
          </Row>
          {optValError && <ErrorMessage>{optValError}</ErrorMessage>}
        </FlexDiv>
      )}
    />
  )
}

export { ProductOptionsValues }
