import { createModule } from '@soltalabs/stateless'

import { ProductService } from '../service'

import { ProductDraftService } from './service'

const INITIAL_STATE = Object.freeze({
  entity: undefined,
  draftId: undefined,
})

const read = () => async (id) => ProductService.read(id)

const create = (module) => async (_, data) => {
  const product = await ProductDraftService.create({
    ...data,
    status: data.status.value,
  })

  module.setState({
    entity: product,
    draftId: product.id,
  })

  return product.id
}

const update = (module) => async (_, id, data) => {
  const product = await ProductService.update(id, {
    ...data,
    status: data.status.value,
  })

  module.setState({
    entity: product,
    draftId: product.id,
  })
}

const updateProductOptionsVariants =
  (module) => async (_, draftId, optionsAndVariants) => {
    const updatedProduct = await ProductService.updateOptionsVariants(
      draftId,
      optionsAndVariants
    )

    module.setState({
      entity: updatedProduct,
      draftId: updatedProduct.id,
    })
  }

const ProductDraftModule = createModule({
  name: 'product/draft',
  initialState: INITIAL_STATE,
  decorators: {
    read,
    create,
    update,
    updateProductOptionsVariants,
  },
})

export { ProductDraftModule }
