import { isNil, pick } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { AccountSettings } from './AccountSettings'

import { COUNTRIES } from 'constants/countries'
import { LANGUAGE } from 'constants/language'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { timezones$ } from 'modules/operatingSystem'
import { provider$, isUpdatingProvider$, providerModule } from 'modules/provider'

const Root = styled.div(s('flex-1 flex flex-row py-6 px-2'))

const Container = styled.div(
  s('flex-1 flex flex-column ', {
    overflow: 'auto',
    '& input': {
      lineHeight: 'unset',
    },
  })
)

const Details = styled(Form)(s('flex-1 flex flex-column'))
const Scrollable = styled.div(
  s('flex flex-column pb-6', {
    minHeight: 'min-content',
  })
)

const { string } = Validator

const languageMapper = {
  [LANGUAGE.ENGLISH]: 'English',
}

const validationSchema = Validator.object({
  timezone: string().required('Please select a valid timezone from the list'),
})

const ConnectedSettingDetails = connect(() => ({
  isUpdatingProvider: isUpdatingProvider$,
  provider: provider$,
  timezones: timezones$,
}))(SettingDetails)

function SettingDetails({ provider, isUpdatingProvider, timezones }) {
  const [isEditing, setIsEditing] = useState(false)

  if (isNil(provider)) {
    return <div />
  }
  const { id, taxCountry, language, timezone } = provider

  async function handleSubmit(values) {
    await providerModule.updateProvider(id, pick(['timezone'], values))

    setIsEditing(false)
  }

  return (
    <Root>
      <Container>
        <Scrollable>
          <FormProvider
            enableReinitialize
            initialValues={{
              taxCountry: COUNTRIES[taxCountry],
              language: languageMapper[language],
              timezone,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Details>
              <Row justify="center">
                <Col md={24} lg={12} style={s('px-4 pb-4')}>
                  <AccountSettings
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isUpdatingProvider={isUpdatingProvider}
                    timezones={timezones}
                  />
                </Col>
              </Row>
            </Details>
          </FormProvider>
        </Scrollable>
      </Container>
    </Root>
  )
}

export { ConnectedSettingDetails as SettingDetails }
