import { map, prop, path, pathOr } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { listingModule } from './module'

const { state$, effectState$ } = listingModule

const listingEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const listingList$ = select(order$, listingEntities$, (order, enities) =>
  map((id) => path([id], enities), order)
)
const inspectedListing$ = select(inspectedEntity$, listingEntities$, (id, entities) =>
  prop(id, entities)
)

const sortBy$ = select(state$, (state) => prop('sortBy', state))

const sortOrder$ = select(state$, (state) => prop('sortOrder', state))

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const filterStatus$ = select(state$, (state) => prop('statuses', state))

const paging$ = select(state$, (state) => prop('paging', state))

const dateRange$ = select(state$, (state) => prop('dateRange', state))

const limit$ = select(state$, (state) => prop('limit', state))

const fetchListingsStatus$ = select(
  listingEntities$,
  effectState$,
  (listingEntities, effectState) =>
    pathOr('pending', [listingEntities, 'status'], effectState)
)

const inspectListingStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectListing', inspectedEntity, 'status'], effectState)
)

const isLoadingListings$ = select(
  fetchListingsStatus$,
  (status) => status === 'pending'
)

const isInspectingListing$ = select(
  inspectListingStatus$,
  (status) => status === 'pending'
)

const isUploadingFile$ = select(effectState$, (state) => {
  const status = path(['uploadFile', 'status'], state)
  return status === 'pending'
})

const uploadedFileIds$ = select(state$, prop('uploadIds'))

const isDeletingDraftListing$ = select(effectState$, (effectState) => {
  const updateProviderStatus = path(['deleteDraftListing', 'status'], effectState)
  return updateProviderStatus === 'pending'
})

export {
  listingEntities$,
  listingList$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  dateRange$,
  isLoadingListings$,
  isUploadingFile$,
  uploadedFileIds$,
  inspectedEntity$,
  inspectedListing$,
  isInspectingListing$,
  isDeletingDraftListing$,
}
