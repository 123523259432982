import { isNotNil, defaultTo, isNil } from '@soltalabs/ramda-extra'
import { isBefore, format, startOfToday, differenceInMinutes } from 'date-fns'
import { useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { Button } from 'components/common/Button'
import { DateField } from 'components/common/DateField'
import { Form } from 'components/common/Form'
import { SelectField } from 'components/common/SelectField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TimeField } from 'components/common/TimeField'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { EVENT_REOCCURRENCE, BOOKING_WINDOW } from 'modules/listing'
import { setTimeComponent } from 'utils/date'
import { deepMemo } from 'utils/react'

const { date, string, number, ref } = Validator

const validationSchema = Validator.object().shape({
  startDate: date('Must be a date'),
  startTime: date('Must be a time').test({
    name: 'Start date must be before current time',
    message: 'Must be after current time',
    test(startTime) {
      const eventDate = this.resolve(ref('eventDate'))
      const startDate = setTimeComponent(startTime, eventDate)
      const now = new Date()
      const isStartDateBeforeNow = isBefore(startDate, now)

      return !isStartDateBeforeNow
    },
  }),
  bookingWindow: number().oneOf(BOOKING_WINDOW.values()),
  notes: string(),
})

const Root = styled.div(s('bg-white rounded-lg p-6'))
const Title = styled.h2(s('text-lg font-semibold mb-2'))

const Forwarded = forwardRef(EditEventModal)
const Memoized = deepMemo(Forwarded)

function EditEventModal({ onEventEdited = () => {}, isEditing }, ref) {
  const eventId = useRef()
  const fieldName = useRef()
  const listingName = useRef()
  const venueName = useRef()
  const selectedDate = useRef(startOfToday())
  const bookingWindowRef = useRef()
  const notesRef = useRef()
  const initialStartDateRef = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  function open({
    id,
    field,
    venueName: venue,
    listingTitle,
    startAt,
    bookingWindow,
    notes,
    initialStartDate,
  }) {
    eventId.current = id
    fieldName.current = field
    venueName.current = venue
    listingName.current = listingTitle
    selectedDate.current = new Date(startAt)
    bookingWindowRef.current = bookingWindow
    notesRef.current = notes
    initialStartDateRef.current = isNil(initialStartDate)
      ? new Date(startAt)
      : initialStartDate

    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  async function handleSubmit({ eventDate, startTime, ...formValues }) {
    const startDate = setTimeComponent(startTime, eventDate)

    const timespanInMinutes = differenceInMinutes(
      startDate,
      initialStartDateRef.current
    )

    /**
     * 23-07-2022: This is the shortest route to completion. To edit a listingEvent without
     * having to submit it first, we check if the event being edited has an id.
     * If it has no id, we haven't submitted it yet and should not try to update it server side,
     * thus shouldUpdateEvent : false. This prevents any attempts to edit events
     * using an undefined Id, and instead defaults them to fresh creation on the backend.
     */

    const event = {
      title: `${venueName.current} - ${format(startTime, 'h:mm a').toLowerCase()}`,
      startDate,
      initialStartDate: initialStartDateRef.current,
      timespanInMinutes,
      shouldUpdateEvent: !!eventId.current,
      venueName: venueName.current,
      ...formValues,
    }

    onEventEdited(fieldName.current, event)
    close()
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      isOpen={isOpen}
    >
      <Form
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          id: eventId.current,
          eventDate: selectedDate.current,
          startTime: selectedDate.current,
          reoccurrence: EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.NEVER),
          bookingWindow: defaultTo(BOOKING_WINDOW.valueOf(BOOKING_WINDOW.TWELVE_HOURS))(
            bookingWindowRef.current
          ),
          notes: defaultTo('')(notesRef.current),
        }}
      >
        <Container>
          <Row justify="center">
            <Col sm={18} md={16} lg={14} xl={12}>
              <Root>
                <Title>{listingName.current}</Title>
                <Title>{venueName.current}</Title>

                <Row gutter={[16, 16]} style={s('mt-4')}>
                  <Col span={12}>
                    <DateField
                      name="eventDate"
                      label="Event date"
                      autoOk
                      disablePast
                      readOnly={!isEditing}
                    />
                  </Col>

                  <Col span={12}>
                    <TimeField
                      name="startTime"
                      label="Start time"
                      readOnly={!isEditing}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <SelectField
                      searchable={false}
                      items={BOOKING_WINDOW.values()}
                      itemToString={(item) =>
                        isNotNil(item)
                          ? BOOKING_WINDOW.propertiesOf(item).readableValue
                          : ''
                      }
                      name="bookingWindow"
                      label="Booking window"
                      disabled={!isEditing}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextAreaField
                      minRows={3}
                      characterLimit={200}
                      name="notes"
                      label="Notes"
                      disabled={!isEditing}
                    />
                  </Col>
                </Row>

                <Row style={s('mt-6 mb-4')}>
                  <Col span={12}>
                    <Button
                      variant="secondary"
                      onClick={close}
                      style={s('bg-gray-200')}
                    >
                      {isEditing ? 'Cancel' : 'Back'}
                    </Button>
                  </Col>

                  {isEditing && (
                    <Col span={12} style={s('flex flex-row-reverse')}>
                      <Button type="submit">Save</Button>
                    </Col>
                  )}
                </Row>
              </Root>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  )
}

export { Memoized as EditEventModal }
