import { connect } from '@soltalabs/stateless'
import { useLayoutEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ListingsDashboard } from './listings/ListingsDashboard'
import { OrdersDashboard } from './orders/OrdersDashboard'
import { ProductDashboard } from './products/ProductDashboard'
import { ProfileDetails } from './profile'
import { SettingDetails } from './settings'

import { withNavigation } from 'components/common/withNavigation'
import { hasVerifiedEmail$, hasOnboarded$, fullName$, avatar$ } from 'modules/auth'
import { operatingSystemModule } from 'modules/operatingSystem'

const ConnectedDashboards = connect(() => ({
  hasVerifiedEmail: hasVerifiedEmail$,
  hasOnboarded: hasOnboarded$,
  fullName: fullName$,
  avatar: avatar$,
}))(withNavigation(Dashboards))

Dashboards.propTypes = {
  hasVerifiedEmail: Props.bool,
  hasOnboarded: Props.bool,
}

function Dashboards({ hasVerifiedEmail, hasOnboarded }) {
  useLayoutEffect(() => {
    operatingSystemModule.updateTimezones()
  }, [])

  if (!hasVerifiedEmail) {
    return <Redirect to="/verify" />
  }

  if (!hasOnboarded) {
    return <Redirect to="/setup" />
  }

  return (
    <Switch>
      <Route path="/listings" component={ListingsDashboard} />
      <Route path="/profile" component={ProfileDetails} />
      <Route path="/products" component={ProductDashboard} />
      <Route path="/orders" component={OrdersDashboard} />
      <Route path="/settings" component={SettingDetails} />
      <Redirect to="/listings" />
    </Switch>
  )
}

export { ConnectedDashboards as Dashboards }
