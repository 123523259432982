import { getEnvVariable } from 'utils/getEnvVariable'

const CONFIG = {
  API: {
    URL: getEnvVariable('API_URL'),
  },
  IDENTITY: {
    DOMAIN: getEnvVariable('IDENTITY_DOMAIN'),
    CLIENT_ID: getEnvVariable('IDENTITY_CLIENT_ID'),
    AUDIENCE: getEnvVariable('IDENTITY_AUDIENCE'),
    REDIRECT_URL: getEnvVariable('IDENTITY_REDIRECT_URL'),
  },
  MAPBOX: {
    API_URL: getEnvVariable('MAPBOX_API_URL'),
    ACCESS_TOKEN: getEnvVariable('MAPBOX_ACCESS_TOKEN'),
  },
  LOGGING: {
    SENTRY_API_KEY: getEnvVariable('SENTRY_KEY'),
  },
}

export { CONFIG }
