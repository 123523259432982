import { View } from './View'

import { Wizard } from 'lib/formik-wizard'

function PublishStep() {
  return (
    <Wizard.Step id="finish" title="Finish">
      <View />
    </Wizard.Step>
  )
}

export { PublishStep }
