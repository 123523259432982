import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'
import { isNotNil, prop, isEmpty } from '@soltalabs/ramda-extra'

import { http } from 'httpClient'
import { createAddressObject } from 'utils/createAddressObject'
import { omitNilOrEmpty } from 'utils/ramda'

const defaultTradingName = (companyName, tradingName) =>
  isEmpty(tradingName) ? companyName : tradingName

const defaultTaxNumber = (taxNumber) => (isEmpty(taxNumber) ? undefined : taxNumber)

const ProviderService = {
  async list() {
    return http.get('my/providers').json()
  },

  async create({
    facebookUrl,
    instagramUrl,
    twitterUrl,
    name,
    businessRegistrationNumber,
    taxNumber,
    tradingName,
    bankDetails,
    website,
    phoneNumber,
    email,
    address: addressEntity,
    businessSummary,
    providerType,
    logo,
  }) {
    const timezone = getFuzzyLocalTimeFromPoint(Date.now(), [
      addressEntity?.geometry?.location?.lng(),
      addressEntity?.geometry?.location?.lat(),
    ]).tz()

    const payload = {
      logo,
      name,
      businessRegistrationNumber,
      taxNumber: defaultTaxNumber(taxNumber),
      tradingName: defaultTradingName(name, tradingName),
      bankDetails,
      website,
      socialNetworks: omitNilOrEmpty({
        facebook: facebookUrl,
        instagram: instagramUrl,
        twitter: twitterUrl,
      }),
      phone: phoneNumber,
      email,
      address: createAddressObject(addressEntity),
      addressLocation: {
        type: 'Point',
        coordinates: [
          addressEntity?.geometry?.location?.lng(),
          addressEntity?.geometry?.location?.lat(),
        ],
      },
      timezone,
      businessSummary,
      providerType,
      bankAccountNumber: String(Date.now()),
    }

    const config = {
      json: omitNilOrEmpty(payload),
    }

    return http.post('my/providers', config).json()
  },
  // eslint-disable-next-line complexity
  async update(
    id,
    {
      name,
      businessRegistrationNumber,
      taxNumber,
      tradingName,
      website,
      phone,
      businessSummary,
      address: addressEntity,
      instagram,
      twitter,
      facebook,
      bankDetails,
      businessEmail: email,
      uploadLogo,
      timezone,
    }
  ) {
    const payload = {
      name,
      businessRegistrationNumber,
      taxNumber,
      tradingName: defaultTradingName(name, tradingName),
      website,
      socialNetworks: omitNilOrEmpty({
        facebook,
        instagram,
        twitter,
      }),
      phone,
      businessSummary,
      bankDetails,
      email,
      timezone,
    }

    const logo = prop('uploadId', uploadLogo)

    if (isNotNil(addressEntity)) {
      payload.address = createAddressObject(addressEntity)
      payload.addressLocation = {
        type: 'Point',
        coordinates: [
          addressEntity?.geometry?.location?.lng(),
          addressEntity?.geometry?.location?.lat(),
        ],
      }
    }

    if (isNotNil(logo)) {
      payload.logo = logo
    }

    const config = {
      json: payload,
    }

    return http.patch(`my/providers/${id}`, config).json()
  },

  async activate(id) {
    return http.post(`my/providers/${id}/onboarding`).json()
  },
}

export { ProviderService }
