/* eslint-disable no-nested-ternary */
import { isNotNil } from '@soltalabs/ramda-extra'
import moment from 'moment'
import { useState } from 'react'
import { DateRangePicker as DateRangePickerBase } from 'react-dates'

import { ReactComponent as ClearIcon } from 'assets/feathers/x.svg'
import { styled, s } from 'lib/styled'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const Container = styled.div(
  s(
    'flex flex-row items-center border-solid border-0 border-b-2 border-gray-500 rounded-lg bg-gray-200'
  ),
  {
    '& .DateRangePickerInput__withBorder': s('border-0 flex items-center bg-none'),
    '& .DateInput': s('bg-none'),
    '& .DateInput_input': s(
      'p-0 py-1 text-sm text-center bg-none font-medium border-0 rounded-lg',
      {
        '&:hover': s('bg-gray-400'),
        lineHeight: 1,
        cursor: 'pointer',
      }
    ),
    '& .DateRangePickerInput_arrow_svg': s('mx-1', { width: 17, height: 17 }),
  }
)

const ClearButton = styled.div(
  s('border-0 bg-transparent d-flex flex-column pr-1 pl-1 rounded-r-lg', {
    '&:hover': s('bg-gray-400'),
    cursor: 'pointer',
    overflow: 'hidden',
  })
)

function DateRangePicker({ dateRange, filterDate, ...props }) {
  const [focusedInput, setFocusedInput] = useState(null)
  const { from, to } = dateRange
  const momentFrom = isNotNil(from) ? moment(from) : from
  const momentTo = isNotNil(to) ? moment(to) : to

  function handleSelect({ startDate, endDate }) {
    filterDate({
      from: isNotNil(startDate) ? startDate.format('YYYY-MM-DD') : undefined,
      to: isNotNil(endDate) ? endDate.format('YYYY-MM-DD') : undefined,
    })
  }

  return (
    <Container {...props}>
      <DateRangePickerBase
        startDate={momentFrom}
        startDateId={'startDate'}
        endDate={momentTo}
        endDateId={'endDate'}
        onDatesChange={handleSelect}
        focusedInput={focusedInput}
        onFocusChange={(value) => setFocusedInput(value)}
        displayFormat={() => 'DD MMM YYYY'}
        isOutsideRange={() => false}
        hideKeyboardShortcutsPanel={true}
      />
      <ClearButton onClick={() => handleSelect({})}>
        <ClearIcon />
      </ClearButton>
    </Container>
  )
}

export { DateRangePicker }
