import { useSlate } from 'slate-react'

import { Button } from './Button'
import { isMarkActive, toggleMark } from './helpers'

import { styled } from 'lib/styled'

const Icon = styled.span({ '& svg': { verticalAlign: 'text-bottom' } })

export function MarkButton({ format, icon }) {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
