import { map, prop, pathOr, filter } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { VENUE_TYPES } from './constants'
import { venueModule } from './module'

const { state$, effectState$ } = venueModule

const venueEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const venueList$ = select(order$, venueEntities$, (order, entities) =>
  map((id) => prop(id, entities), order)
)

const physicalVenueList$ = select(
  venueList$,
  filter(
    (venue) =>
      venue.venueType === VENUE_TYPES.valueOf(VENUE_TYPES.FIXED) ||
      venue.venueType === VENUE_TYPES.valueOf(VENUE_TYPES.MOBILE)
  )
)

const fetchVenuesStatus$ = select(
  venueEntities$,
  effectState$,
  (venueEntities, effectState) =>
    pathOr('pending', ['fetchVenues', 'status'], effectState)
)

const isLoadingVenues$ = select(fetchVenuesStatus$, (status) => status === 'pending')

export { venueList$, physicalVenueList$, isLoadingVenues$ }
