import { isEmpty, isNotEmpty, isNil, isNotNil } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useCallback, useState, useEffect } from 'react'

import { ReactComponent as UserIcon } from 'assets/feathers/user.svg'
import { Spinner } from 'components/common/Spinner'
import { styled, s, smaller } from 'lib/styled'
import {
  authModule,
  isUploadingAvatar$,
  uploadedAvatarId$,
  avatar$,
} from 'modules/auth'
import { useFileUpload } from 'utils/hooks'

const Container = styled.div(
  s(
    'relative flex flex-column items-center justify-center w-12 h-12 rounded-full bg-gray-200 border-1 border-dashed border-gray-400'
  ),
  {
    cursor: 'pointer',
    overflow: 'hidden',
  },
  smaller('sm')(s('w-10 h-10')),
  ({ hideBorder }) => (hideBorder ? s('border-0') : {})
)
const Avatar = styled.img(s('h-full w-a'), { objectFit: 'contain' })
const OpaqueOverlay = styled.div(
  s('absolute h-full w-full flex items-center justify-center bg-black'),
  {
    backgroundColor: '#000000AA',
  }
)

const ReuploadOverlay = styled(OpaqueOverlay)(s('bg-black text-white text-semibold'), {
  opacity: 0,
  '&:hover': { opacity: 1, backgroundColor: '#00000088' },
})

const ConnectedAvatarUpload = connect(() => ({
  isUploadingAvatar: isUploadingAvatar$,
  uploadedAvatarId: uploadedAvatarId$,
  avatar: avatar$,
}))(AvatarUpload)

AvatarUpload.propTypes = {
  isUploadingAvatar: Props.bool,
  uploadedAvatarId: Props.string,
  avatar: Props.string,
}

function AvatarUpload({ isUploadingAvatar, uploadedAvatarId, avatar, ...props }) {
  const { getRootProps, getInputProps, acceptedFiles } = useFileUpload({
    accept: 'image/*',
    noDrag: true,
    noKeyboard: true,
    onDropAccepted: useCallback(uploadAvatar),
  })
  const [preview, setPreview] = useState(avatar)

  function uploadAvatar([file]) {
    authModule.uploadAvatar(null, file)
  }

  useEffect(() => {
    if (!isUploadingAvatar && isNotNil(uploadedAvatarId) && isNotEmpty(acceptedFiles)) {
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }

    return () => URL.revokeObjectURL(preview)
  }, [isUploadingAvatar, uploadedAvatarId, acceptedFiles])

  return (
    <Container {...getRootProps()} hideBorder={isNotNil(preview)} {...props}>
      {isUploadingAvatar && (
        <OpaqueOverlay>
          <Spinner size={36} thickness={4} />
        </OpaqueOverlay>
      )}

      {isNil(preview) && isEmpty(acceptedFiles) && (
        <UserIcon width={96} height={96} stroke="#FFF" />
      )}

      {isNotNil(preview) && (
        <>
          <Avatar src={preview} />

          <ReuploadOverlay>
            <span>Upload new avatar</span>
          </ReuploadOverlay>
        </>
      )}

      <input {...getInputProps()} />
    </Container>
  )
}

export { ConnectedAvatarUpload as AvatarUpload }
