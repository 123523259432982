import { prop, path } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { DraftModule } from './module'

const { state$, effectState$ } = DraftModule

const draft$ = select(state$, prop('entity'))
const draftStatus$ = select(draft$, prop('status'))

const isPublishingDraft$ = select(effectState$, (state) => {
  const publishStatus = path(['publish', 'status'], state)

  return publishStatus === 'pending'
})

export { draftStatus$, isPublishingDraft$ }
