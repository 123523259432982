import { useTable, useSortBy } from 'react-table'

import { CustomPagination } from './CustomPagination'
import { TableBase } from './TableBase'

import { styled, s } from 'lib/styled'

const Root = styled.div(s('flex flex-column flex-1 m-4'), {
  overflow: 'auto',
})

function Table({
  columns,
  data,
  onClick,
  sortBy,
  sortOrder,
  startRow,
  next,
  limit,
  onSort,
  turnPage,
  domain,
  ...props
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  function goToPreviousPage() {
    turnPage({ turnNext: false })
  }
  function goToNextPage() {
    turnPage({ turnNext: true })
  }

  return (
    <Root>
      <TableBase
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        rows={rows}
        onClick={onClick}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onSort={onSort}
        domain={domain}
        {...props}
      />
      <CustomPagination
        next={next}
        startRow={startRow}
        limit={limit}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
      />
    </Root>
  )
}

export { Table }
