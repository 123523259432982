import { useEffect } from 'react'
import Modal from 'react-modal'

import { Button as ButtonBase } from 'components/common/Button'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('text-black bg-white rounded-lg p-6 pb-2'))

const Title = styled.h2(s('text-xl font-semibold mt-2 mb-4 px-2'))

const EventTitle = styled.div(s('mr-a'))

function CalendarShowMoreModal({
  isOpen,
  setIsOpen,
  onOpenTimeslot,
  dayEvents,
  isEditing,
}) {
  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      shouldCloseOnOverlayClick={true}
    >
      <Container>
        <Row justify="center" wrap={false}>
          <Root>
            <Row>
              <Col>
                <Title>Full Day Schedule</Title>
              </Col>
            </Row>
            {dayEvents.map((element, index) => (
              <Row key={index} align="middle" style={{ marginTop: '8px' }}>
                <EventTitle>{element.title}</EventTitle>
                {element.isThisAvailability && isEditing ? (
                  <ButtonBase
                    type="button"
                    onClick={() => onOpenTimeslot({ start: element.start, ...element })}
                  >
                    Edit
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    variant="secondary"
                    type="button"
                    style={s('bg-gray-200 ml-8')}
                    onClick={() => onOpenTimeslot({ start: element.start, ...element })}
                  >
                    Read
                  </ButtonBase>
                )}
              </Row>
            ))}
            <Row>
              <Col>
                <ButtonBase
                  variant="secondary"
                  onClick={() => setIsOpen(false)}
                  style={s('bg-gray-200 mt-2 mb-2')}
                >
                  Back
                </ButtonBase>
              </Col>
            </Row>
          </Root>
        </Row>
      </Container>
    </Modal>
  )
}

export { CalendarShowMoreModal }
