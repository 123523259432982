import { path, prop, pathOr, isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { PROVIDER_STATUS } from './constants'
import { providerModule } from './module'

const { state$, effectState$ } = providerModule

const providerEntities$ = select(state$, prop('entities'))
const hasCreatedProvider$ = select(providerEntities$, isNotNilOrEmpty)

const currentProvider$ = select(state$, prop('currentProvider'))
const currentProviderTimezone$ = select(
  currentProvider$,
  providerEntities$,
  (currentProvider, entities) => path([currentProvider, 'timezone'], entities)
)

const isUploadingLogo$ = select(effectState$, (state) => {
  const status = path(['uploadLogo', 'status'], state)

  return status === 'pending'
})

const provider$ = select(currentProvider$, providerEntities$, prop)

const hasOnboardedProvider$ = select(provider$, (provider) => {
  const status = pathOr(PROVIDER_STATUS.ONBOARDING)(['status'])(provider)

  return status !== PROVIDER_STATUS.ONBOARDING
})

const isProviderBanned$ = select(
  provider$,
  (provider) => prop('status')(provider) === PROVIDER_STATUS.BANNED
)

const isProviderSuspended$ = select(
  provider$,
  (provider) => prop('status')(provider) === PROVIDER_STATUS.SUSPENDED
)

const isUpdatingProvider$ = select(
  currentProvider$,
  effectState$,
  (id, effectState) => {
    const updateProviderStatus = path(['updateProvider', id, 'status'], effectState)
    return updateProviderStatus === 'pending'
  }
)

const uploadedLogoId$ = select(state$, path(['uploadIds', 'logo']))

export {
  isUploadingLogo$,
  uploadedLogoId$,
  hasCreatedProvider$,
  provider$,
  hasOnboardedProvider$,
  isProviderBanned$,
  isProviderSuspended$,
  isUpdatingProvider$,
  currentProviderTimezone$,
}
