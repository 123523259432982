import {
  isNil,
  isNotNil,
  map,
  pick,
  pickThenRename,
  filter,
  isNotEmpty,
} from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'

import { ListingService } from './service'

import { availabilityModule as AvailabilityModule } from 'modules/availability'
import { UploadService } from 'modules/upload'
import { venueModule as VenueModule } from 'modules/venue'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { debounce } from 'utils/debounce'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  statuses: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
  dateRange: {
    from: undefined,
    to: undefined,
  },
  uploadIds: {
    termsAndConditions: undefined,
    cancellationPolicy: undefined,
    riskAssessment: undefined,
    brochure: undefined,
    video: undefined,
  },
  uploadPhotos: [],
  listingDraftId: undefined,
})

const fetchListings =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, paging, statuses, limit, dateRange, sortBy, sortOrder } =
      module.getState()

    const next = calcNext(turnPage, turnNext, paging, limit)
    const { from, to } = dateRange

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await ListingService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      statuses,
      limit,
      from,
      to,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const debouncedFetchListings = (module) => debounce(module.fetchListings, 350)

const filterDate =
  (module) =>
  ({ from, to }) => {
    module.setState({
      dateRange: {
        from,
        to,
      },
      paging: {
        startRow: undefined,
        next: undefined,
      },
    })

    module.debouncedFetchListings(null, { turnPage: false })
  }

const uploadFile = (module) => async (_, file, type) => {
  if (isNil(file)) {
    module.setState({
      uploadIds: {
        [type]: undefined,
      },
    })
  }
  const { id } = await UploadService.uploadFile(file)
  module.setState({
    uploadIds: {
      [type]: id,
    },
  })
}

const sortListings = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.debouncedFetchListings(null, { turnPage: false })
}

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchListings(null, { turnPage: true, turnNext })
  }

const filterStatusListings = (module) => (statuses) => {
  module.setState({
    statuses,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.debouncedFetchListings(null, { turnPage: false })
}

const filterListings = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.debouncedFetchListings(null, { turnPage: false })
}

const inspectListing = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const listing = await ListingService.read(id)

  module.setState({
    entities: { [id]: listing },
  })
}

const deleteDraftListing = (module) => async (_, id) => {
  await ListingService.deleteDraftListing(id)

  module.fetchListings(null, { turnPage: false })
}

const updateListingDescription = (module) => async (_, id, descriptionList) => {
  const listing = await ListingService.update(id, descriptionList)

  module.setState({
    entities: { [id]: listing },
  })
}

const updateListingMedia =
  (module) =>
  async (_, id, { updateList, createList, deleteList }) => {
    await Promise.all([
      ...map(
        (media) =>
          ListingService.updateMedia(
            id,
            pick(['id', 'tags', 'htmlContent', 'mediaType'], media)
          ),
        updateList
      ),
      ...map(
        (media) =>
          ListingService.createMedia(
            id,
            pick(['mediaType', 'content', 'tags', 'htmlContent'], media)
          ),
        createList
      ),
      ...map(
        (media) => ListingService.deleteMedia(id, pick(['id'], media)),
        deleteList
      ),
    ])

    const Listing = await ListingService.read(id)

    module.setState({
      entities: { [id]: Listing },
    })
  }

const updateOnlineAvailability = (module) => async (_, onlineActivity) => {
  const { inspectedEntity: listingId } = module.getState()

  const { venueId, conference, pricing, contact, events } = onlineActivity

  await VenueModule.updateOnlineVenue(null, venueId, conference)

  const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
    listingId,
    venueId,
    totalSpots: pricing.totalSpots,
    contact: pickThenRename(
      ['name', 'phoneNumber', 'email'],
      { phoneNumber: 'phone' },
      contact
    ),
  })

  await Promise.all([
    AvailabilityModule.updateAvailabilityWithPricingOptions(
      availability.id,
      pricing.options
    ),

    AvailabilityModule.updateAvailabilityWithEvents(
      availability.id,
      map(
        pickThenRename(
          [
            'id',
            'startDate',
            'endDate',
            'reoccurrence',
            'reoccurrenceEnd',
            'bookingWindow',
            'notes',
            'shouldUpdateEvent',
            'timespanInMinutes',
          ],
          {
            reoccurrence: 'recurrence',
            reoccurrenceEnd: 'recurrenceEnd',
          }
        ),
        events
      )
    ),
  ])

  const Listing = await ListingService.read(listingId)

  module.setState({
    entities: { [listingId]: Listing },
  })
}

const updateCommunityAvailability = (module) => async (_, communityActivity) => {
  const { inspectedEntity: listingId } = module.getState()

  const {
    venueId,
    location,
    pricing,
    contact,
    eventSerieId,
    noDateRange,
    startDate,
    endDate,
    note,
  } = communityActivity

  await VenueModule.updatePhysicalVenue(null, venueId, location, {
    fetchNewVenues: false,
  })

  const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
    listingId,
    venueId,
    contact: pickThenRename(
      ['name', 'phoneNumber', 'email'],
      { phoneNumber: 'phone' },
      contact
    ),
  })

  await Promise.all([
    AvailabilityModule.updateAvailabilityWithPricingOptions(
      availability.id,
      pricing.options
    ),
    AvailabilityModule.updateCommunityAvailabilityWithEvents(availability.id, {
      eventSerieId,
      noDateRange,
      startDate,
      endDate,
      note,
    }),
  ])

  const Listing = await ListingService.read(listingId)

  module.setState({
    entities: { [listingId]: Listing },
  })
}

const updatePhysicalAvailabilities =
  (module) =>
  async (_, availabilities = [], mappedAvailabilities) => {
    const { inspectedEntity: listingId } = module.getState()

    // delete unSelected physical availabilities
    const mappedAvailabilitiesNeedDelete = filter(
      ({ isSelected, id }) => !isSelected && isNotNil(id)
    )(availabilities)

    if (isNotEmpty(mappedAvailabilitiesNeedDelete)) {
      await AvailabilityModule.deleteAvailabilities(
        null,
        mappedAvailabilitiesNeedDelete
      )
    }

    // update and create availabilities
    await Promise.all(
      mappedAvailabilities.map(persistAvailability, mappedAvailabilities)
    )

    async function persistAvailability({ activity, pricing, events }) {
      const availability = await AvailabilityModule.createOrUpdateAvailability(null, {
        listingId,
        venueId: activity.venueId,
        totalSpots: pricing.totalSpots,
        contact: pickThenRename(
          ['name', 'phoneNumber', 'email'],
          { phoneNumber: 'phone' },
          activity.contact
        ),
      })

      await Promise.all([
        AvailabilityModule.updateAvailabilityWithPricingOptions(
          availability.id,
          pricing.options
        ),

        AvailabilityModule.updateAvailabilityWithEvents(
          availability.id,
          map(
            pickThenRename(
              [
                'id',
                'startDate',
                'endDate',
                'reoccurrence',
                'reoccurrenceEnd',
                'bookingWindow',
                'notes',
                'shouldUpdateEvent',
                'timespanInMinutes',
              ],
              {
                reoccurrence: 'recurrence',
                reoccurrenceEnd: 'recurrenceEnd',
              }
            ),
            events
          )
        ),
      ])
    }

    const Listing = await ListingService.read(listingId)

    module.setState({
      entities: { [listingId]: Listing },
    })
  }

const listingModule = createModule({
  name: 'listing',
  initialState: INITIAL_STATE,
  decorators: {
    fetchListings,
    debouncedFetchListings,
    uploadFile,
    filterListings,
    filterStatusListings,
    sortListings,
    inspectListing,
    deleteDraftListing,
    turnPage,
    filterDate,
    updateListingDescription,
    updateListingMedia,
    updateOnlineAvailability,
    updateCommunityAvailability,
    updatePhysicalAvailabilities,
  },
})

export { listingModule }
