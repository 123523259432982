/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { useMemo } from 'react'

import { StyledCell } from '../../common/withTable/StyledCell'

import { s } from 'lib/styled'

const statusMapper = {
  Confirmed: 'bg-green-lightest',
  Cancelled: 'bg-orange-lightest saturate-2',
}

export const Columns = () =>
  useMemo(
    () => [
      {
        Header: 'Booking Number',
        accessor: 'reference',
        disableSortBy: true,
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '15%',
      },
      {
        Header: 'Title',
        accessor: 'title',
        disableSortBy: true,
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '20%',
      },
      {
        Header: 'Purchased on',
        accessor: 'createdAt',
        disableSortBy: true,
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '15%',
      },
      {
        Header: 'Client',
        accessor: 'client',
        id: 'accountDetails.name',
        Cell: ({ value }) => <StyledCell style={s('text-left')} value={value} />,
        width: '20%',
      },
      {
        Header: () => <StyledCell value="Status" />,
        accessor: 'status',
        disableSortBy: true,
        width: '20%',
        textAlign: 'left',
        Cell: ({ value }) => (
          <StyledCell
            style={s(`rounded-md inline-block px-2 py-1 ${statusMapper[value]}`)}
            value={value}
          />
        ),
      },
      {
        Header: () => <StyledCell style={s('ml-a')} value="Amount" />,
        accessor: 'totalAmount',
        disableSortBy: true,
        Cell: ({ value }) => <StyledCell style={s('text-right')} value={value} />,
        width: '10%',
      },
    ],
    []
  )
