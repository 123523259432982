import { styled, s } from 'lib/styled'

const Container = styled.button(
  s(
    'tracking-wide rounded-lg px-4 py-2 text-xs bg-primary-lighter border-0 border-solid border-b-2 border-primary-light font-medium text-black',
    {
      '&:not(:disabled)': { cursor: 'pointer' },
      '&:active': s('bg-primary-light border-primary'),
      '&:disabled': s('bg-gray-200 border-gray-500 text-black'),
    }
  ),
  ({ variant = 'primary' }) =>
    variant === 'secondary' ? s('border-0 bg-transparent text-primary') : {}
)

function Button({ variant, ...props }) {
  return <Container type="button" variant={variant} {...props} />
}

export { Button }
