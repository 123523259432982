import { isEmpty } from '@soltalabs/ramda-extra'
import throttle from 'p-throttle'
import { useState } from 'react'

const service = new google.maps.places.AutocompleteService()

const GeocodingService = {
  forward: throttle((query) => service.getPlacePredictions({ input: query }), 5, 1000),
}

function useGeocoding() {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState([])

  async function forwardGeocode(query) {
    GeocodingService.forward.abort()

    if (isEmpty(query)) {
      setResults([])
      return
    }

    setIsLoading(true)

    try {
      const featureCollection = await GeocodingService.forward(query)

      setResults(featureCollection.predictions)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function resetResults() {
    setResults([])
  }

  return {
    isLoading,
    results,

    forwardGeocode,
    resetResults,
  }
}

export { useGeocoding }
