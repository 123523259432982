import { memo } from 'react'
import ReactTooltip from 'react-tooltip'

import { ReactComponent as QuestionIcon } from 'assets/feathers/question.svg'
import { s } from 'lib/styled'

const Memoized = memo(Tooltip)

function Tooltip({ name, tooltip }) {
  return (
    <div>
      <a data-tip data-for={name} style={s('ml-2 block', { height: '12px' })}>
        <QuestionIcon width={13} height={13} fill="#969696" />
      </a>
      <ReactTooltip id={name} delayHide={500} effect="solid" backgroundColor="#F0F0F0">
        <span style={s('normal-case text-xs text-black')}>{tooltip}</span>
      </ReactTooltip>
    </div>
  )
}

export { Memoized as Tooltip }
