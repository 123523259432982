const debounce = (func, timeThreshold, ...args) => {
  let lastReceivedArgs
  let rollingCallTimestamp
  let runningTimer

  if (typeof func !== 'function') {
    throw new TypeError(
      `Expected Argument 1 to be of type function, got ${typeof func}`
    )
  }

  if (typeof timeThreshold !== 'number') {
    throw new TypeError(
      `Expected Argument 2 to be of type number, got ${typeof timeThreshold}`
    )
  }

  function pulseExpired() {
    if (
      (rollingCallTimestamp === undefined ||
        Date.now() - rollingCallTimestamp >= timeThreshold) &&
      lastReceivedArgs !== undefined
    ) {
      runningTimer = undefined
      return func.apply(lastReceivedArgs)
    }
    runningTimer = setTimeout(pulseExpired, Date.now() - rollingCallTimestamp)
    return null
  }

  function debounced() {
    lastReceivedArgs = args
    rollingCallTimestamp = Date.now()
    if (runningTimer === undefined) {
      runningTimer = setTimeout(pulseExpired, timeThreshold)
    }
  }
  return debounced
}

export { debounce }
