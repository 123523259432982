import { isNil, prop } from '@soltalabs/ramda-extra'
import { useMemo } from 'react'

import { ORDER_STATUSES } from 'constants/orderStatus'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      reference: null,
      listing: null,
      createdAt: null,
      accountDetails: null,
      total: null,
    })
  }
}

const statusMapper = {
  [ORDER_STATUSES.CONFIRMED]: 'Confirmed',
  [ORDER_STATUSES.CANCELLED]: 'Cancelled',
}

export const Rows = (list) =>
  useMemo(() => {
    const ordersList = list.slice()
    if (ordersList.length < totalRows) {
      createEmptyRows(ordersList)
    }
    return ordersList.map(
      ({
        id,
        reference,
        listing,
        product,
        createdAt,
        accountDetails,
        total,
        status,
      }) => ({
        id,
        reference,
        title: prop('title', listing) ?? prop('name', product),
        createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
        client: prop('name', accountDetails),
        totalAmount: total?.toFixed(2),
        status: statusMapper[status],
      })
    )
  }, [list])
