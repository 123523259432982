import { isNotNil } from '@soltalabs/ramda-extra'
import { startOfDay, isBefore, format } from 'date-fns'
import { useRef, forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { RecurrenceEndField } from './RecurrenceEndField'

import { Button } from 'components/common/Button'
import { DateField } from 'components/common/DateField'
import { Form } from 'components/common/Form'
import { SelectField } from 'components/common/SelectField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TimeField } from 'components/common/TimeField'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { EVENT_REOCCURRENCE, BOOKING_WINDOW } from 'modules/listing'
import { setTimeComponent } from 'utils/date'
import { deepMemo } from 'utils/react'

const { date, string, number, ref } = Validator

const validationSchema = Validator.object().shape({
  startDate: date('Must be a date'),
  startTime: date('Must be a time').test({
    name: 'Start date must be before current time',
    message: 'Must be after current time',
    test(startTime) {
      const eventDate = this.resolve(ref('eventDate'))
      const startDate = setTimeComponent(startTime, eventDate)
      const now = new Date()
      const isStartDateBeforeNow = isBefore(startDate, now)

      return !isStartDateBeforeNow
    },
  }),
  reoccurrence: string().oneOf(EVENT_REOCCURRENCE.values(), 'Not a valid value'),
  reoccurrenceEnd: date('Must be a date'),
  bookingWindow: number().oneOf(BOOKING_WINDOW.values()),
  notes: string(),
})

const Root = styled.div(s('bg-white rounded-lg p-6'))
const Title = styled.h2(s('text-lg font-semibold mb-6'))

const Forwarded = forwardRef(AddEventModal)
const Memoized = deepMemo(Forwarded)

function AddEventModal({ onEventCreated = () => {} }, ref) {
  const fieldName = useRef()
  const venueName = useRef()
  const selectedDate = useRef()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  function open({ title, field, selectedStartDate }) {
    venueName.current = title
    fieldName.current = field
    selectedDate.current = new Date(selectedStartDate)

    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  async function handleSubmit({
    eventDate,
    startTime,
    reoccurrenceEnd: reoccurrenceEndDate,
    ...formValues
  }) {
    const startDate = setTimeComponent(startTime, eventDate)
    const reoccurrenceEnd = setTimeComponent(startTime, reoccurrenceEndDate)

    const event = {
      title: `${venueName.current} - ${format(startTime, 'h:mm a').toLowerCase()}`,
      startDate,
      venueName: venueName.current,
      reoccurrenceEnd,
      ...formValues,
    }

    onEventCreated(fieldName.current, event)
    close()
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      isOpen={isOpen}
    >
      <Form
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          eventDate: selectedDate.current,
          startTime: startOfDay(selectedDate.current),
          reoccurrence: EVENT_REOCCURRENCE.valueOf(EVENT_REOCCURRENCE.NEVER),
          reoccurrenceEnd: selectedDate.current,
          bookingWindow: BOOKING_WINDOW.valueOf(BOOKING_WINDOW.TWELVE_HOURS),
          notes: '',
        }}
      >
        <Container>
          <Row justify="center">
            <Col sm={18} md={16} lg={14} xl={12}>
              <Root>
                <Title>Add new event</Title>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <DateField name="eventDate" label="Event date" autoOk disablePast />
                  </Col>

                  <Col span={12}>
                    <TimeField name="startTime" label="Start time" />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <SelectField
                      searchable={false}
                      items={BOOKING_WINDOW.values()}
                      itemToString={(item) =>
                        isNotNil(item)
                          ? BOOKING_WINDOW.propertiesOf(item).readableValue
                          : ''
                      }
                      name="bookingWindow"
                      label="Booking window"
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <SelectField
                      searchable={false}
                      items={EVENT_REOCCURRENCE.values()}
                      itemToString={(item) =>
                        isNotNil(item)
                          ? EVENT_REOCCURRENCE.propertiesOf(item).readableValue
                          : ''
                      }
                      name="reoccurrence"
                      label="Repeats"
                    />
                  </Col>

                  <Col span={12}>
                    <RecurrenceEndField name="reoccurrenceEnd" label="Repeat until" />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextAreaField
                      minRows={3}
                      characterLimit={200}
                      name="notes"
                      label="Notes"
                    />
                  </Col>
                </Row>

                <Row style={s('mt-6')}>
                  <Col span={12}>
                    <Button
                      variant="secondary"
                      onClick={close}
                      style={s('bg-gray-100')}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col span={12} style={s('flex flex-row-reverse')}>
                    <Button type="submit">Add</Button>
                  </Col>
                </Row>
              </Root>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  )
}

export { Memoized as AddEventModal }
