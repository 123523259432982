import {
  filter,
  prop,
  map,
  isNotNil,
  defaultTo,
  path,
  keys,
  head,
  isString,
} from '@soltalabs/ramda-extra'

const fieldsNameMapper = {
  conference: 'Conference Details',
  contact: 'Contact Information',
  pricing: 'Pricing',
  events: 'Date & Time',
}

function getPhysicalVenueError(errors, availabilities = []) {
  const formErrors = prop('availabilities')(errors)

  if (isString(formErrors)) {
    return formErrors
  }

  const FormErrorsWithVenueName = filter((error) => isNotNil(error))(
    defaultTo([], formErrors).map((formError, index) =>
      isNotNil(formError)
        ? {
            venue: path([index, 'venue', 'name'])(availabilities),
            ...formError,
          }
        : null
    )
  )

  const errorMessages = FormErrorsWithVenueName.map(({ venue, ...formErrors }) => {
    const firstFieldNeedChange = head(
      map((field) => fieldsNameMapper[field])(keys(formErrors))
    )
    return `There are some issues with ${venue}'s ${firstFieldNeedChange}`
  })

  return prop(0, errorMessages)
}

export { getPhysicalVenueError }
