import { Button as ButtonBase } from 'components/common/Button'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Button = styled(ButtonBase)(s('bg-transparent border-0', { color: '#B465B8' }))

const Memoized = deepMemo(BackButton)

function BackButton({ currentStepIndex, disabled, previous }) {
  const isFirst = currentStepIndex === 0

  if (isFirst) {
    return null
  }

  return (
    <Button disabled={disabled} onClick={previous}>
      Back
    </Button>
  )
}

export { Memoized as BackButton }
