import { prop, defaultTo, isNotNil } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { http } from 'httpClient'
import { ORDER_KIND } from 'utils/constants'

const orderEntity = new schema.Entity('orders')

const fetchOrderForOrderKind = async (orderKind, config) => {
  const { items, paging } = await http
    .get('orders', {
      authorize: config.authorize,
      searchParams: { ...config.searchParams, orderKind },
    })
    .json()

  const normalized = normalize(items, [orderEntity])
  const entities = defaultTo({}, prop('orders', normalized.entities))
  const order = defaultTo([], normalized.result)

  return { entities, order, paging }
}

const fetchListingAndProductOrder = async (config) => {
  const {
    entities: productEntities,
    order: productOrder,
    paging: productPaging,
  } = await fetchOrderForOrderKind(ORDER_KIND.PRODUCT, config)
  const { entities: listingEntities, order: listingOrder } =
    await fetchOrderForOrderKind(ORDER_KIND.LISTING, config)
  return {
    entities: { ...listingEntities, ...productEntities },
    order: productOrder.concat(listingOrder),
    paging: productPaging,
  }
}

const OrderService = {
  async list({ query = '', limit = 10, next, sortBy, sortOrder, status, from, to }) {
    const config = {
      authorize: true,
      searchParams: {
        q: query,
        limit,
        sortBy,
        sortOrder,
        status,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    if (isNotNil(from)) {
      config.searchParams.from = from
    }

    if (isNotNil(to)) {
      config.searchParams.to = to
    }

    const { entities, paging, order } = await fetchListingAndProductOrder(config)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },

  async read(id) {
    const config = {
      authorize: true,
    }
    return http.get(`orders/${id}`, config).json()
  },
}

export { OrderService }
