import { isNil } from '@soltalabs/ramda-extra'
import { format } from 'date-fns'

export function safelyParse(dateOfBirth, { includeTime = false } = {}) {
  if (isNil(dateOfBirth)) {
    return 'Unspecified'
  }
  return includeTime
    ? format(new Date(dateOfBirth), 'dd MMM yyyy h:mm aaaa')
    : format(new Date(dateOfBirth), 'dd MMM yyyy')
}
