import { connect } from '@soltalabs/stateless'
import { useLayoutEffect, useState } from 'react'

import { Table } from '../../common/withTable'

import { Columns } from './Columns'
import { Filters } from './Filters'
import { Rows } from './Rows'

import { DeleteDraftModal } from 'components/listings/common/DeleteDraftModal'
import { SelectListingTypeModal } from 'components/listings/common/SelectListingTypeModal'
import { SuspendMessageModal } from 'components/listings/common/SuspendMessageModal'
import { styled, s } from 'lib/styled'
import {
  listingModule,
  listingList$,
  isLoadingListings$,
  inspectedListing$,
  filterQuery$,
  filterStatus$,
  sortBy$,
  sortOrder$,
  paging$,
  limit$,
  dateRange$,
  isDeletingDraftListing$,
} from 'modules/listing'
import { isProviderSuspended$ } from 'modules/provider'

const Container = styled.div(s('flex-1 flex flex-column bg-white rounded-lg shadow-sm'))

const ConnectedListingsTable = connect(() => ({
  isProviderSuspended: isProviderSuspended$,
  listingList: listingList$,
  isLoadingListings: isLoadingListings$,
  filterQuery: filterQuery$,
  inspectedListing: inspectedListing$,
  filterStatus: filterStatus$,
  sortBy: sortBy$,
  sortOrder: sortOrder$,
  paging: paging$,
  limit: limit$,
  dateRange: dateRange$,
  isDeletingDraftListing: isDeletingDraftListing$,
}))(ListingsTable)

const {
  fetchListings,
  inspectListing,
  filterListings,
  filterStatusListings,
  sortListings,
  turnPage,
  filterDate,
  deleteDraftListing,
} = listingModule

function ListingsTable({
  isProviderSuspended,
  listingList,
  filterQuery,
  filterStatus,
  sortBy,
  sortOrder,
  paging,
  limit,
  dateRange,
  isDeletingDraftListing,
}) {
  const [isSelectListingTypeModalOpen, setIsSelectListingTypeModalOpen] =
    useState(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteDraftId, setDeleteDraftId] = useState(undefined)

  const [isSuspendMessageModalOpen, setIsSuspendMessageModalOpen] = useState(false)
  const [forbidActionMessage, setForbidActionMessage] = useState(undefined)

  function handleOpenDeleteModal(id) {
    setDeleteDraftId(id)
    setIsDeleteModalOpen(true)
  }

  function handleOpenSuspendMessageModal(forbidActionMessage) {
    setForbidActionMessage(forbidActionMessage)
    setIsSuspendMessageModalOpen(true)
  }

  async function handleDeleteDraft() {
    await deleteDraftListing(null, deleteDraftId)
    setIsDeleteModalOpen(false)
  }

  useLayoutEffect(() => {
    fetchListings()
  }, [])

  const columns = Columns()
  const rows = Rows(listingList)
  const { startRow, next } = paging

  return (
    <Container>
      <Filters
        filterQuery={filterQuery}
        filterListings={filterListings}
        filterStatus={filterStatus}
        filterStatusListings={filterStatusListings}
        dateRange={dateRange}
        filterDate={filterDate}
        onOpenSuspendMessageModal={handleOpenSuspendMessageModal}
        onOpenSelectListingTypeModal={setIsSelectListingTypeModalOpen}
        isProviderSuspended={isProviderSuspended}
      />
      <Table
        columns={columns}
        data={rows}
        onClick={inspectListing}
        sortBy={sortBy}
        sortOrder={sortOrder}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={sortListings}
        turnPage={turnPage}
        domain={'listings'}
        onOpenDeleteModal={handleOpenDeleteModal}
        onOpenSuspendMessageModal={handleOpenSuspendMessageModal}
        isProviderSuspended={isProviderSuspended}
      />

      <SelectListingTypeModal
        isOpen={isSelectListingTypeModalOpen}
        setIsOpen={setIsSelectListingTypeModalOpen}
      />

      <DeleteDraftModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onDeleteDraft={handleDeleteDraft}
        isDeletingDraftListing={isDeletingDraftListing}
      />

      <SuspendMessageModal
        isOpen={isSuspendMessageModalOpen}
        setIsOpen={setIsSuspendMessageModalOpen}
        forbidActionMessage={forbidActionMessage}
      />
    </Container>
  )
}

export { ConnectedListingsTable as ListingsTable }
