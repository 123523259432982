import { isEmpty, omit } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { FullDescription } from 'components/common/FullDescription'
import { TabEditing } from 'components/common/TabEditing'
import { TagInput } from 'components/common/TagInput'
import { TextField } from 'components/common/TextField'
import { Categories } from 'components/listings/common/Categories'
import { GenderField } from 'components/listings/common/GenderField'
import { GENDERS } from 'constants/genders'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { listingModule } from 'modules/listing'
import { convertToHtml, convertFromHtml, calcCharscount } from 'utils/convertSlateData'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('flex flex-column flex-1 mt-4'))

const Scroll = styled.div(s('flex flex-row'), {
  overflowX: 'hidden',
  overflowY: 'auto',
})

const { object, string, array, number } = Validator
const noSpecialCharacters = string()
  .trim()
  .required('This field is required')
  .matches(/^[a-zA-Z0-9 _-]+$/g, 'No special character')

const validationSchema = Validator.object({
  description: object({
    title: string().required('This field is required'),
    description: object().shape({
      charsCount: number().max(3000, '3000 characters limit'),
    }),
    categories: array().required('Select at least one'),
    keywords: array().of(noSpecialCharacters),
    needToBring: array().of(noSpecialCharacters),
    whatToWear: array().of(noSpecialCharacters),
  }),
})

const Memoized = deepMemo(DescriptionTab)

function DescriptionTab({
  inspectedListing,
  isEditing,
  setIsEditing,
  tabTitle,
  currentTab,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)

  if (currentTab !== tabTitle) {
    return null
  }
  const {
    id,
    title,
    categories,
    keywords,
    itemsToBring,
    itemsToWear,
    genders,
    description,
  } = inspectedListing

  async function handleSubmit({ description }) {
    const descriptionList = omit(['description'], description)
    descriptionList.description = convertToHtml({
      children: description.description.text,
    })

    await listingModule.updateListingDescription(null, id, descriptionList)

    setIsEditing(false)
  }

  return (
    <Formik
      id="description"
      title="description"
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={{
        description: {
          title,
          description: {
            text: convertFromHtml(description),
            charsCount: calcCharscount(convertFromHtml(description)),
            new: false,
          },
          categories,
          keywords,
          needToBring: itemsToBring,
          whatToWear: itemsToWear,
          genders: isEmpty(genders) ? [GENDERS.UNSPECIFIED] : genders,
        },
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form style={s('flex flex-column', { overflow: 'hidden' })} key={isCanceled}>
        <Scroll>
          <Root>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextField
                  readOnly={!isEditing}
                  name="description.title"
                  label="Listing title"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <FullDescription
                  disabled={!isEditing}
                  name="description.description"
                  label="Full description"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Categories
                  readOnly={!isEditing}
                  name="description.categories"
                  label="Categories"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TagInput
                  readOnly={!isEditing}
                  name="description.keywords"
                  label="Keywords"
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col>
                <GenderField
                  readOnly={!isEditing}
                  name="description.genders"
                  label="Gender"
                />
              </Col>
            </Row>
          </Root>
        </Scroll>

        <TabEditing
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCanceled={isCanceled}
          setIsCanceled={setIsCanceled}
          disabled={isEditingDisabled}
        />
      </Form>
    </Formik>
  )
}

export { Memoized as DescriptionTab }
