import { ReactComponent as MenuIcon } from 'assets/feathers/menu.svg'
import { Avatar } from 'components/common/Avatar'
import { styled, s } from 'lib/styled'

const Container = styled.div(
  s(
    'relative flex flex-row items-center justify-center bg-white px-4 py-2 h-3 border-0 border-solid border-b-1 border-grey-light'
  )
)

const Title = styled.h1(
  s('text-black text-xl font-normal tracking-wide m-0 ml-2', { marginRight: 'auto' })
)

const FullName = styled.div(s('text-sm text-black font-normal mr-2'))

function NavigationBar({ title, onSidebarToggle, fullName, avatar }) {
  return (
    <Container>
      <MenuIcon
        stroke="#222"
        strokeWidth={1.5}
        onClick={onSidebarToggle}
        style={s('p-1 mr-1', { cursor: 'pointer' })}
      />
      <Title>{title}</Title>

      <span style={s('mr-1')}>Welcome back,</span>
      <FullName>{fullName}!</FullName>
      <Avatar src={avatar} size={30} placeholderSize={30} style={s('mr-1')} />
    </Container>
  )
}

export { NavigationBar }
