import CONSTANTS from '@solta/constants'
import { reduce } from '@soltalabs/ramda-extra'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { TabEditing } from './TabEditing'

import { OnlineActivityForm } from 'components/listings/common/OnlineActivityForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { styled, s } from 'lib/styled'
import { listingModule, LISTING_TYPES } from 'modules/listing'
import { getOnlineAvailability } from 'utils/getOnlineAvailability'

const Root = styled.div(s('flex-1 flex flex-column w-full mt-6'), {
  overflow: 'hidden',
})
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)

const { object, string, boolean, number, array, ref } = Validator

const pricingOptionSchema = object({
  hasLimitedSpots: boolean(),
  totalSpots: number()
    .min(0, 'Must be a positive number')
    .max(9999, 'Must be less than 9999')
    .optional(),

  options: array()
    .required('Must have at least one pricing')
    .when('hasLimitedSpots', {
      is: true,
      then: array().test({
        name: "Spots don't exceed limit",
        message:
          'Total spot allocation exceeded. Please re-check your pricing options.',
        test(options) {
          const totalSpots = this.resolve(ref('totalSpots'))
          const allocatedSpots = reduce((acc, option) => acc + option.spots, 0, options)
          const hasAllocatedExcessSpots = allocatedSpots > totalSpots

          return !hasAllocatedExcessSpots
        },
      }),
    })
    .of(
      object({
        name: string()
          .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
          .required('This field is required'),
        description: string()
          .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
          .optional(),
        spots: number().min(1, 'Must be at least 1').required('This field is required'),
        price: number().required('This field is required'),
      })
    ),
})

const URLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const onlineActivitySchema = Validator.object({
  onlineActivity: object({
    conference: object().shape({
      link: string()
        .matches(URLRegex, 'Not a valid URL')
        .required('This field is required'),
      passcode: string().optional(),
      timezone: string(),
    }),

    contact: object({
      name: string().required('This field is required'),
      phoneNumber: string()
        .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
        .required('This field is required'),
      email: string()
        .email('Not a valid email address')
        .required('This field is required'),
    }),

    pricing: pricingOptionSchema,

    events: array().when('hasExistingEvent', {
      is: false,
      then: array().required('Must select a Date & Time'),
    }),
  }),
})

function OnlineVenue({
  listingType,
  venues,
  isEditing,
  setIsEditing,
  currentTab,
  tabTitle,
  inspectedListing,
  isEditingDisabled,
}) {
  const [isCanceled, setIsCanceled] = useState(false)

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)) {
    return null
  }

  if (currentTab !== tabTitle) {
    return null
  }

  const { title, availabilities } = inspectedListing

  async function handleSubmit({ onlineActivity }) {
    await listingModule.updateOnlineAvailability(null, onlineActivity)

    setIsEditing(false)
  }

  return (
    <Formik
      id="venues"
      title="Venues"
      style={s('flex-1 flex flex-row', { overflow: 'hidden' })}
      enableReinitialize
      initialValues={{
        description: {
          title,
        },

        initialVenues: venues,

        onlineActivity: getOnlineAvailability(availabilities),
      }}
      validationSchema={onlineActivitySchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form
          style={s('flex flex-column flex-1', { overflow: 'hidden' })}
          key={isCanceled}
        >
          <Root>
            <Label>Online activity</Label>
            <OnlineActivityForm isEditing={isEditing} />
          </Root>

          <TabEditing
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isCanceled={isCanceled}
            setIsCanceled={setIsCanceled}
            formError={errors}
            disabled={isEditingDisabled}
          />
        </Form>
      )}
    </Formik>
  )
}

export { OnlineVenue }
