import { Field } from 'formik'

import { styled, s } from 'lib/styled'

const Container = styled.div(
  s(
    'flex-1 flex flex-row text-black border-solid border-0 border-b-2 border-transparent rounded-lg px-3 py-2 items-center'
  ),
  ({ isChecked }) =>
    isChecked ? s('bg-gray-200 border-gray-500') : s('bg-transparent '),
  ({ readOnly }) => ({
    cursor: readOnly ? 'default' : 'pointer',
  })
)
const Label = styled.label(s('m-0 ml-2 text-sm font-light'), ({ readOnly }) =>
  readOnly ? s('text-gray-700', { cursor: 'default' }) : { cursor: 'pointer' }
)
const Input = styled.input(({ disabled }) => ({
  cursor: disabled ? 'default' : 'pointer',
}))

function RadioButtonField({
  id,
  name,
  label,
  value,
  containerProps,
  readOnly,
  ...props
}) {
  return (
    <Field name={name}>
      {({ field }) => {
        const isChecked = value === field.value
        const onChange = () => {
          if (readOnly) {
            return
          }

          field.onChange(field.name)(value)
        }

        return (
          <Container isChecked={isChecked} readOnly={readOnly} onClick={onChange}>
            <Input
              {...field}
              type="radio"
              name={field.name}
              checked={value === field.value}
              onChange={onChange}
              disabled={readOnly}
              {...props}
            />
            <Label readOnly={readOnly} htmlFor={id}>
              {label}
            </Label>
          </Container>
        )
      }}
    </Field>
  )
}

export { RadioButtonField }
