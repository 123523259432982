import { path, prop, pick } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import Modal from 'react-modal'
import * as Validator from 'yup'

import { TimezoneField } from './TimezoneField'

import { AddressField } from 'components/common/AddressField'
import { Button } from 'components/common/Button'
import { CheckboxField } from 'components/common/CheckboxField'
import { Form } from 'components/common/Form'
import { RadioButtonField } from 'components/common/RadioButtonField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TextField } from 'components/common/TextField'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { VENUE_TYPES, venueModule } from 'modules/venue'

const { string, object, boolean } = Validator

const validationSchema = Validator.object().shape({
  name: string()
    .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
    .required('This field is required'),
  type: string().oneOf(VENUE_TYPES.values()),
  address: object(),
  hasWheelchairAccess: boolean(),
  transportNotes: string().nullable().optional(),
  parkingNotes: string().nullable().optional(),
  timezone: string(),
})

const Root = styled.div(s('bg-white rounded-lg p-6'))
const Title = styled.h2(s('text-lg font-semibold mb-6'))
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)

const Forwarded = forwardRef(EditLocationModal)

function EditLocationModal(props, ref) {
  const [isOpen, setIsOpen] = useState(false)
  const [venueId, setVenueId] = useState('')
  const { values } = useFormContext()
  const availability = values.availabilities.find(
    (availability) => availability.venue.id === venueId
  )
  const venue = prop('venue', availability)

  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  function open(venueId) {
    setIsOpen(true)
    setVenueId(venueId)
  }

  function close() {
    setIsOpen(false)
  }

  async function handleSubmit(formValues) {
    await venueModule.updatePhysicalVenue(
      null,
      venueId,
      pick(
        [
          'name',
          'address',
          'hasWheelchairAccess',
          'transportNotes',
          'parkingNotes',
          'timezone',
        ],
        formValues
      )
    )

    close()
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
  }))

  return (
    <Modal
      style={{
        overlay: s('z-1 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
        }),
      }}
      isOpen={isOpen}
    >
      <Form
        initialValues={{
          type: prop('venueType', venue),
          name: prop('name', venue),
          address: undefined,
          hasWheelchairAccess: prop('wheelchairAccessible', venue),
          transportNotes: path(['location', 'transportNotes'], availability),
          parkingNotes: path(['location', 'parkingNotes'], availability),
          timezone: prop('timezone', venue),
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Container>
          <Row justify="center">
            <Col sm={18} md={16} lg={14} xl={12}>
              <Root>
                <Title>Edit venue</Title>

                <Label>Type</Label>
                <Row gutter={[8, 16]}>
                  <Col>
                    <RadioButtonField
                      readOnly
                      name="type"
                      value={VENUE_TYPES.valueOf(VENUE_TYPES.FIXED)}
                      label={VENUE_TYPES.propertiesOf(VENUE_TYPES.FIXED).readableValue}
                    />
                  </Col>

                  <Col>
                    <RadioButtonField
                      readOnly
                      name="type"
                      value={VENUE_TYPES.valueOf(VENUE_TYPES.MOBILE)}
                      label={VENUE_TYPES.propertiesOf(VENUE_TYPES.MOBILE).readableValue}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextField characterLimit={150} name="name" label="Name" />
                  </Col>
                </Row>

                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <AddressField
                      name="address"
                      label="Address"
                      placeholder={path(['address', 'formattedAddress'], venue)}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <CheckboxField
                      name="hasWheelchairAccess"
                      label="Wheelchair access"
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TimezoneField />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextAreaField
                      name="transportNotes"
                      label="Transport Notes"
                      minRows={3}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <TextAreaField
                      name="parkingNotes"
                      label="Parking Notes"
                      minRows={3}
                    />
                  </Col>
                </Row>

                <Row style={s('mt-6')}>
                  <Col span={12}>
                    <Button
                      variant="secondary"
                      onClick={close}
                      style={s('bg-gray-100')}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col span={12} style={s('flex flex-row-reverse')}>
                    <Button type="submit">Save</Button>
                  </Col>
                </Row>
              </Root>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  )
}

export { Forwarded as EditLocationModal }
