import { isNil } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { Redirect } from 'react-router-dom'

import { ResendEmailControl } from './ResendEmailControl'

import { Header } from 'components/common/Header'
import { styled, s } from 'lib/styled'
import { hasVerifiedEmail$, hasOnboarded$, emailAddress$ } from 'modules/auth'
import { useQueryParams } from 'utils/hooks'

const Container = styled.div(s('h-full flex flex-column items-center justify-center'))
const Content = styled.div(s('flex-1 flex flex-column items-center justify-center'))
const Card = styled.div(s('max-w-24 bg-white shadow-sm rounded-lg p-10 mx-4'))
const Title = styled.h1(s('m-0 mb-8 font-bold text-3xl text-primary'))
const Message = styled.p(
  s('m-0 mb-8 p-0 text-lg font-normal', {
    '& > b': s('font-semibold'),
  })
)
const ConnectedVerifyEmailPage = connect(() => ({
  hasVerifiedEmail: hasVerifiedEmail$,
  hasOnboarded: hasOnboarded$,
  emailAddress: emailAddress$,
}))(VerifyEmailPage)

VerifyEmailPage.propTypes = {
  hasVerifiedEmail: Props.bool,
  hasOnboarded: Props.bool,
  emailAddress: Props.string,
}

function VerifyEmailPage({ hasVerifiedEmail, hasOnboarded, emailAddress }) {
  const { success } = useQueryParams()

  if (hasVerifiedEmail) {
    return <Redirect to={hasOnboarded ? '/' : '/setup'} />
  }

  return (
    <Container>
      <Header />

      <Content>
        <Card>
          <Title>{isNil(success) ? 'Verify your account' : 'Invalid link'}</Title>

          <Message>
            {isNil(success) ? (
              <>
                Follow the link sent to <b>{emailAddress}</b> to verify your account.
                Make sure to check your spam folder if the email doesn&apos;t arrive
                after a few minutes.
              </>
            ) : (
              <>The link you followed has expired, or is invalid.</>
            )}
          </Message>

          <ResendEmailControl />
        </Card>
      </Content>
    </Container>
  )
}

export { ConnectedVerifyEmailPage as VerifyEmailPage }
