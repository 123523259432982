import Modal from 'react-modal'

import { Button as ButtonBase } from 'components/common/Button'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('text-black bg-white rounded-lg p-6 pb-2'))

const Title = styled.h2(s('text-xl font-semibold mt-2 mb-4 px-2'))

const Message = styled.div(s('text-base py-1 px-2'))

const OkButton = styled(ButtonBase)(s('w-5'))

Modal.setAppElement('#root')

function SuspendMessageModal({ forbidActionMessage, isOpen, setIsOpen }) {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Container>
        <Row justify="center">
          <Col>
            <Root>
              <Title>Your account has been suspended</Title>
              <Message>{forbidActionMessage}</Message>
              <Message>{'Please contact Bula for further support.'}</Message>

              <Row gutter={[16, 16]} style={s('mt-2')}>
                <Col span={24} style={s('text-right')}>
                  <OkButton type="button" onClick={() => setIsOpen(false)}>
                    Ok
                  </OkButton>
                </Col>
              </Row>
            </Root>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export { SuspendMessageModal }
