import { useFormikContext as useFormContext } from 'formik'

import { AddressField } from 'components/common/AddressField'
import { FullDescription } from 'components/common/FullDescription'
import { NumberField } from 'components/common/NumberField'
import { TagInput } from 'components/common/TagInput'
import { TextField } from 'components/common/TextField'
import { Categories } from 'components/products/common/Categories'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'

const Scroll = styled.div(
  s('flex-1', {
    overflowX: 'hidden',
    overflowY: 'auto',
  })
)

const Root = styled.div(s('flex flex-column mt-6'))

function Details({ isEditing }) {
  const { values, setFieldValue } = useFormContext()

  function addressPlaceholder() {
    return (
      values?.details?.location?.formatted_address ??
      values?.details?.address?.formattedAddress ??
      null
    )
  }

  return (
    <Scroll>
      <Root>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <TextField name="details.name" label="Product name" readOnly={!isEditing} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <NumberField
              name="details.price"
              label="Product price"
              type="number"
              readOnly={!isEditing}
              onChange={(e) => {
                setFieldValue(`details.price`, parseFloat(e.target.value))
              }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <FullDescription
              name="details.description"
              label="Product description"
              disabled={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <Categories
              name="details.categories"
              label="Categories"
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            {/**
             * The following placeholder value is used to re-populate the address field visually
             * if the wizard is backtracked through. There are a few reasons for this, the most
             * important being that a proper location object is only added to the submission state
             * when an item in the downshift dropdown menu is clicked. We don't want people to manually
             * edit addresses instead of getting them from the maps api autocomplete. It also seems to be
             * downshift specific behavior that causes the disconnect between view and state when selecting
             * an address, and this would be messy to programatically replicate.
             */}

            <AddressField
              name="details.location"
              label="Product Location"
              placeholder={addressPlaceholder()}
              readOnly={!isEditing}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <TagInput
              name="details.keywords"
              label="Product keywords"
              readOnly={!isEditing}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={s('justify-center')}>
          <Col span={20}>
            <FullDescription
              name="details.summary"
              label="Product summary"
              disabled={!isEditing}
            />
          </Col>
        </Row>
      </Root>
    </Scroll>
  )
}

export { Details }
