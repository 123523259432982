/* eslint-disable complexity */
import { isEmpty, isNil, isNotNilOrEmpty, prop } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useLayoutEffect, useRef, useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { Spinner } from 'components/common/Spinner'
import { WizardNavigation } from 'components/common/WizardNavigation'
import { WizardProgress } from 'components/common/WizardProgress'
import { DescriptionStep } from 'components/listings/common/ListingWizard/DescriptionStep'
import { MediaStep } from 'components/listings/common/ListingWizard/MediaStep'
import { PublishStep } from 'components/listings/common/ListingWizard/PublishStep'
import { VenueStep } from 'components/listings/common/ListingWizard/VenueStep'
import { GENDERS } from 'constants/genders'
import { Wizard } from 'lib/formik-wizard'
import { styled, s } from 'lib/styled'
import { LISTING_STATUS, LISTING_TYPES, inspectedListing$ } from 'modules/listing'
import { draftStatus$, isPublishingDraft$ } from 'modules/listing/draft'
import { listingEventModule } from 'modules/listing/event'
import { currentProviderTimezone$, provider$ } from 'modules/provider'
import { venueModule, physicalVenueList$, isLoadingVenues$ } from 'modules/venue'
import { convertFromHtml, calcCharscount } from 'utils/convertSlateData'
import {
  getCommunityAvailability,
  getInitialCommunityActivityObject,
} from 'utils/getCommunityAvailability'
import { getMedia } from 'utils/getMedia'
import {
  getOnlineAvailability,
  getInitialOnlineActivityObject,
} from 'utils/getOnlineAvailability'
import {
  getPhysicalAvailabilities,
  getInitialPhysicalActivityObject,
} from 'utils/getPhysicalAvailabilities'

const Root = styled.div(
  s('flex flex-column bg-white w-full rounded-lg px-6 pt-1 pb-4 shadow-sm')
)

const Connected = connect(() => ({
  isLoadingVenues: isLoadingVenues$,
  currentProviderTimezone: currentProviderTimezone$,
  provider: provider$,
  venues: physicalVenueList$,
  draftStatus: draftStatus$,
  isPublishingDraft: isPublishingDraft$,
  inspectedListing: inspectedListing$,
}))(EditDraftListingWizard)

function EditDraftListingWizard({
  isLoadingVenues,
  currentProviderTimezone,
  provider,
  venues,
  draftStatus,
  isPublishingDraft,
  inspectedListing,
}) {
  const history = useHistory()
  const onlineActivityRef = useRef(undefined)
  const availabilitiesRef = useRef(undefined)
  const communityActivityRef = useRef(undefined)

  const availabilities = prop('availabilities')(inspectedListing)
  const listingType = prop('listingType')(inspectedListing)

  const activityMapper = {
    [LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)]: {
      ref: onlineActivityRef,
      value: getOnlineAvailability(availabilities),
      initialValue: getInitialOnlineActivityObject(provider, currentProviderTimezone),
    },
    [LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)]: {
      ref: availabilitiesRef,
      value: getPhysicalAvailabilities(availabilities, provider, venues),
      initialValue: getInitialPhysicalActivityObject(provider, venues),
    },
    [LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)]: {
      ref: communityActivityRef,
      value: getCommunityAvailability(availabilities),
      initialValue: getInitialCommunityActivityObject(
        provider,
        currentProviderTimezone
      ),
    },
  }

  useLayoutEffect(() => {
    venueModule.fetchVenues()
    listingEventModule.fetchEvents()
  }, [])

  useEffect(() => {
    if (isNil(inspectedListing)) {
      return
    }

    const { ref, value, initialValue } = activityMapper[listingType]

    ref.current = isNotNilOrEmpty(availabilities) ? value : initialValue
  }, [availabilities, venues])

  if (isNil(inspectedListing)) {
    return <Redirect to="/listings" />
  }

  const {
    id,
    title,
    categories,
    keywords,
    itemsToBring,
    itemsToWear,
    genders,
    description,
    media,
  } = inspectedListing

  const isLoading = isLoadingVenues || isNil(availabilities) || isNil(media)

  const isNotDraft =
    draftStatus === LISTING_STATUS.AWAITING_APPROVAL ||
    draftStatus === LISTING_STATUS.LISTED

  const shouldDisableBackButton = isNotDraft || isPublishingDraft

  function handleSubmit() {
    history.push('/listings')
  }

  return (
    <Root>
      <Wizard
        isLoading={isLoading}
        loadingComponent={<Spinner style={s('mt-12')} />}
        basePath={`/listings/draft/${id}`}
        formProps={{
          style: s('flex-1 flex flex-column', { overflow: 'hidden' }),
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        initialValues={{
          draftId: id,
          listingType,

          currentProviderTimezone,

          // Description Step
          description: {
            id,
            title,
            description: {
              text: convertFromHtml(description),
              charsCount: calcCharscount(convertFromHtml(description)),
              new: false,
            },
            categories,
            keywords,
            needToBring: itemsToBring,
            whatToWear: itemsToWear,
            genders: isEmpty(genders) ? [GENDERS.UNSPECIFIED] : genders,
          },

          // Media step
          media: getMedia(inspectedListing),

          // Venues step
          onlineActivity: onlineActivityRef.current,

          availabilities: availabilitiesRef.current,

          communityActivity: communityActivityRef.current,
        }}
      >
        <WizardProgress />

        <DescriptionStep />

        <MediaStep />

        <VenueStep venues={venues} provider={provider} />

        <PublishStep />

        <WizardNavigation
          shouldDisableBackButton={shouldDisableBackButton}
          style={s('mt-6')}
        />
      </Wizard>
    </Root>
  )
}

export { Connected as EditDraftListingWizard }
