import { http } from 'httpClient'
import { createAddressObject } from 'utils/createAddressObject'

const ProductDraftService = {
  async create(data) {
    const dataToSend = { ...data }
    if (data?.location !== undefined) {
      dataToSend.address = createAddressObject(data.location)
      dataToSend.location = {
        type: 'Point',
        coordinates: [
          data?.location?.geometry?.location?.lng(),
          data?.location?.geometry?.location?.lat(),
        ],
      }
    }
    const config = { json: dataToSend }
    return http.post('products', config).json()
  },
}

export { ProductDraftService }
