import { Link as LinkBase } from 'react-router-dom'

import { StatusSelector } from '../../common/StatusSelector'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { SearchInput } from 'components/common/SearchInput'
import { PRODUCT_STATUSES } from 'constants/productStatus'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-gray-400'
  ),
  {
    'z-index': 2,
  }
)

const CreateProductButton = styled.div(
  s(
    'ml-a flex flex-row items-center bg-primary text-white font-normal border-0 text-xs uppercase tracking-wide pl-2 pr-3 py-2 rounded-lg'
  ),
  {
    '&:hover': s('bg-primary-dark', {
      cursor: 'pointer',
    }),
  }
)

const Link = styled(LinkBase)(s('ml-a'))

const statusOptions = [
  { label: 'All listings', value: '' },
  { label: 'Draft listings', value: PRODUCT_STATUSES.DRAFT },
  { label: 'Awaiting Approval', value: PRODUCT_STATUSES.AWAITING_APPROVAL },
  { label: 'Approved listings', value: PRODUCT_STATUSES.LISTED },
  { label: 'Suspended listings', value: PRODUCT_STATUSES.SUSPENDED },
  { label: 'Rejected listings', value: PRODUCT_STATUSES.REJECTED },
]

function Filters({ filterQuery, filterProducts, filterStatus, filterStatusProducts }) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterProducts}
        placeholder="Search by Name"
      />

      <StatusSelector
        style={s('ml-3')}
        statusOptions={statusOptions}
        value={filterStatus}
        onChange={filterStatusProducts}
      />

      <Link to="/products/new-product">
        <CreateProductButton>
          <PlusIcon
            width={16}
            height={16}
            stroke="#FFF"
            strokeWidth="3px"
            style={s('mr-1')}
          />
          New Product
        </CreateProductButton>
      </Link>
    </Root>
  )
}

export { Filters }
