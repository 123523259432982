import CONSTANTS from '@solta/constants'
import { prop, isNotNilOrEmpty } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import * as Validator from 'yup'

import { CommunityActivityForm } from 'components/listings/common/CommunityActivityForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { Wizard } from 'lib/formik-wizard'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'
import { DraftModule } from 'modules/listing/draft'
import {
  getCommunityAvailability,
  getInitialCommunityActivityObject,
} from 'utils/getCommunityAvailability'

const { object, string, boolean } = Validator

const communityActivitySchema = Validator.object({
  communityActivity: object({
    location: object().shape({
      name: string()
        .matches(NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX, 'Must not contain: !@#$%^&*~')
        .required('This field is required'),
      address: object().required('Not a valid address'),
      hasWheelchairAccess: boolean(),
      transportNotes: string().nullable().optional(),
      parkingNotes: string().nullable().optional(),
      timezone: string(),
    }),

    contact: object({
      name: string().required('This field is required'),
      phoneNumber: string()
        .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
        .required('This field is required'),
      email: string()
        .email('Not a valid email address')
        .required('This field is required'),
    }),
  }),
})

const Root = styled.div(s('flex-1 flex flex-column'), { overflow: 'hidden' })

function CommunityVenue({ provider }) {
  const { values, setFieldValue } = useFormContext()

  const { listingType } = values

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)) {
    return null
  }

  async function handleSubmit({ draftId, currentProviderTimezone, communityActivity }) {
    await DraftModule.createCommunityAvailability(null, communityActivity)

    const newDraftListing = await DraftModule.read(draftId)

    const availabilities = prop('availabilities')(newDraftListing)

    const newCommunityActivity = isNotNilOrEmpty(availabilities)
      ? getCommunityAvailability(availabilities)
      : getInitialCommunityActivityObject(provider, currentProviderTimezone)

    setFieldValue('communityActivity', newCommunityActivity)
  }

  return (
    <Wizard.Step
      id="venues"
      title="Venues"
      validationSchema={communityActivitySchema}
      onSubmit={handleSubmit}
    >
      <Root>
        <CommunityActivityForm />
      </Root>
    </Wizard.Step>
  )
}

export { CommunityVenue }
