import moment from 'moment'

const getMonthInUtc = (endOf) => moment().utc()[endOf ? 'endOf' : 'startOf']('month')

const getStartOfMonth = ({ endOf = false } = {}) =>
  getMonthInUtc(endOf).format('YYYY-MM-DD')

const getEndOfMonth = ({ endOf = true } = {}) =>
  getMonthInUtc(endOf).format('YYYY-MM-DD')

const getLastThirtyDays = () => moment().utc().subtract(30, 'days').format('YYYY-MM-DD')

export { getStartOfMonth, getEndOfMonth, getLastThirtyDays }
