import { path, includes, find, pathEq, defaultTo } from '@soltalabs/ramda-extra'

function getInitialPhysicalActivityObject(provider, venues) {
  return venues.map((venue) => ({
    id: undefined,

    isSelected: false,
    hasOrders: false,
    hasExistingEvent: false,

    venueId: venue.id,
    venue,

    location: {
      address: venue.address,
      hasWheelchairAccess: venue.wheelchairAccessible,
      timezone: venue.timezone,
      transportNotes: path(['transports', 0], venue),
      parkingNotes: path(['parkings', 0], venue),
    },

    contact: {
      name: provider.name,
      phoneNumber: provider.phone,
      email: provider.email,
    },

    pricing: {
      hasLimitedSpots: false,
      totalSpots: 10,
      options: [
        {
          name: '',
          description: undefined,
          spots: 1,
          price: 0,
        },
      ],
    },

    events: [],
  }))
}

function getPhysicalAvailabilities(availabilities = [], provider, venues) {
  const availabilitiesIdList = availabilities.map(({ venue }) => venue.id)

  return venues.map((venue) => {
    const isSelected = includes(venue.id, availabilitiesIdList)

    const availability = defaultTo({})(
      find(pathEq(['venue', 'id'], venue.id))(availabilities)
    )
    const { id, contact, pricings, spots, hasOrders } = availability

    return {
      id: isSelected ? id : undefined,

      isSelected,
      hasOrders: isSelected ? hasOrders : false,
      hasExistingEvent: isSelected,

      venueId: venue.id,
      venue,

      location: {
        address: venue.address,
        hasWheelchairAccess: venue.wheelchairAccessible,
        timezone: venue.timezone,
        transportNotes: path(['transports', 0], venue),
        parkingNotes: path(['parkings', 0], venue),
      },

      contact: isSelected
        ? {
            name: contact.name,
            phoneNumber: contact.phone,
            email: contact.email,
          }
        : {
            name: provider.name,
            phoneNumber: provider.phone,
            email: provider.email,
          },

      pricing: isSelected
        ? {
            hasLimitedSpots: false,

            totalSpots: spots,

            options: pricings.map((pricing) => ({
              id: pricing.id,
              name: pricing.name,
              description: pricing.description,
              spots: pricing.occupiedSpots,
              price: pricing.price,
            })),
          }
        : {
            hasLimitedSpots: false,
            totalSpots: 10,
            options: [
              {
                name: '',
                description: undefined,
                spots: 1,
                price: 0,
              },
            ],
          },

      events: [],
    }
  })
}

export { getPhysicalAvailabilities, getInitialPhysicalActivityObject }
