import { pluck, isNotNil, prop } from '@soltalabs/ramda-extra'
import { useField, FieldArray } from 'formik'
import { useState } from 'react'

import { Photos } from './Photos'
import { PhotoUpload } from './PhotosUpload'

import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Panel = styled(Col)(s('ml-3 mt-4 bg-gray-200 rounded-lg flex flex-column'))
const Tags = styled.div(
  s(
    'flex flex-row items-center my-2 mx-3 px-3 py-2 border-0 rounded-lg bg-gray-300 min-h-2'
  )
)
const Tag = styled.span(
  s(
    'px-3 py-1 mx-2 inline-flex self-center text-xs border-0 rounded-full bg-primary-lighter text-black'
  ),
  {
    '&:first-of-type': s('ml-0'),
    '&:last-of-type': s('mr-0'),
  }
)
const Text = styled.div(s('mx-3 my-2'), {
  '&::hover': s('underline'),
})

const ErrorMessage = styled.span(s('block text-error text-sm'))

const Memoized = deepMemo(PhotoGallery)

function PhotoGallery({ name, disabled, ...props }) {
  const [fieldProps, { touched, error }, { setTouched }] = useField({ name, ...props })
  const { value: uploadedPhotos, onChange } = fieldProps

  const [inspectedPhoto, setInspectedPhoto] = useState(prop(0, uploadedPhotos))

  const tags = pluck('tags', uploadedPhotos).map((tag) => prop(0, tag))

  const filteredTags = tags.filter((tag) => isNotNil(tag) && tag !== '')
  const uniqueTags = filteredTags.filter(
    (tag, index) => filteredTags.indexOf(tag) === index
  )

  return (
    <Row>
      <Col span={8}>
        <PhotoUpload
          name={name}
          acceptType="image/*"
          maxSize={3e7}
          uploadedPhotos={uploadedPhotos}
          inspectedPhoto={inspectedPhoto}
          setInspectedPhoto={setInspectedPhoto}
          onChange={onChange}
          disabled={disabled}
        />
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
      </Col>
      <Col span={16} style={s('flex')}>
        <Panel flex="auto">
          <Text>Tags</Text>
          <Tags>
            {uniqueTags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Tags>
          <Row style={s('flex-wrap mx-2')}>
            <FieldArray name={name}>
              {({ remove }) => (
                <>
                  {uploadedPhotos.map((photo, index) => (
                    <Photos
                      name={name}
                      key={index}
                      photo={photo}
                      index={index}
                      remove={remove}
                      inspectedPhoto={inspectedPhoto}
                      setInspectedPhoto={setInspectedPhoto}
                      setTouched={setTouched}
                      disabled={disabled}
                    />
                  ))}
                </>
              )}
            </FieldArray>
          </Row>
        </Panel>
      </Col>
    </Row>
  )
}

export { Memoized as PhotoGallery }
