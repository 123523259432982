import { createModule } from '@soltalabs/stateless'

import { TIMEZONES } from './constants'
import { OperatingSystemService } from './service'

const INITIAL_STATE = Object.freeze({
  timezones: TIMEZONES,
})

const updateTimezones = (module) => async () => {
  const timezones = await OperatingSystemService.readTimezones()

  module.setState({ timezones })
}

const operatingSystemModule = createModule({
  name: 'operatingSystem',
  initialState: INITIAL_STATE,
  decorators: {
    updateTimezones,
  },
})

export { operatingSystemModule }
