import { createModule } from '@soltalabs/stateless'

import { OrderService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { getLastThirtyDays } from 'utils/getDate'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  sortBy: '',
  sortOrder: '',
  status: '',
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
  dateRange: {
    from: getLastThirtyDays(),
    to: undefined,
  },
})

const fetchOrders =
  (module) =>
  async (_, { turnPage = false, turnNext } = {}) => {
    const { filterQuery, status, limit, paging, dateRange, sortBy, sortOrder } =
      module.getState()
    const next = calcNext(turnPage, turnNext, paging, limit)
    const { from, to } = dateRange

    const {
      entities,
      order,
      next: newNext,
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    } = await OrderService.list({
      query: filterQuery,
      next,
      sortBy,
      sortOrder,
      status,
      limit,
      from,
      to,
    })

    module.setState({
      entities,
      order,
      paging: {
        startRow: calcStartRow(newNext, limit, paging),
        next: newNext,
      },
      sortBy: newSortBy,
      sortOrder: newSortOrder,
    })
  }

const sortOrders = (module) => (sortBy, sortOrder) => {
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
    sortBy,
    sortOrder,
  })

  module.fetchOrders(null, { turnPage: false })
}

const turnPage =
  (module) =>
  ({ turnNext }) => {
    module.fetchOrders(null, { turnPage: true, turnNext })
  }

const filterDate =
  (module) =>
  ({ from, to }) => {
    module.setState({
      dateRange: {
        from,
        to,
      },
      paging: {
        startRow: undefined,
        next: undefined,
      },
    })

    module.fetchOrders(null, { turnPage: false })
  }

const filterStatusOrders = (module) => (status) => {
  module.setState({
    status,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })
  module.fetchOrders(null, { turnPage: false })
}

const filterOrders = (module) => (query) => {
  module.setState({
    filterQuery: query,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

  module.fetchOrders(null, { turnPage: false })
}

const inspectOrder = (module) => async (id) => {
  module.setState({
    inspectedEntity: id,
  })

  const order = await OrderService.read(id)
  module.setState({
    entities: { [id]: order },
  })
}

const orderModule = createModule({
  name: 'order',
  initialState: INITIAL_STATE,
  decorators: {
    fetchOrders,
    filterOrders,
    filterStatusOrders,
    sortOrders,
    inspectOrder,
    turnPage,
    filterDate,
  },
})

export { orderModule }
