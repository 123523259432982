import CONSTANTS from '@solta/constants'
import {
  reduce,
  pipe,
  filter,
  prop,
  map,
  isNotNilOrEmpty,
  isNil,
  isNotEmpty,
} from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import { useRef, useEffect, useState } from 'react'
import * as Validator from 'yup'

import { Button } from 'components/common/Button'
import { CreateVenueModal } from 'components/listings/common/CreateVenueModal'
import { PhysicalActivitiesForm } from 'components/listings/common/PhysicalActivitiesForm'
import { NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX } from 'constants/regex'
import { Wizard } from 'lib/formik-wizard'
import { useWizard } from 'lib/formik-wizard/context'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'
import { DraftModule } from 'modules/listing/draft'
import {
  getPhysicalAvailabilities,
  getInitialPhysicalActivityObject,
} from 'utils/getPhysicalAvailabilities'
import { getPhysicalVenueError } from 'utils/getPhysicalVenueError'

const { object, string, boolean, number, array, ref } = Validator

const pricingOptionSchema = object().when('isSelected', {
  is: true,
  then: object({
    hasLimitedSpots: boolean(),
    totalSpots: number()
      .min(0, 'Must be a positive number')
      .max(9999, 'Must be less than 9999')
      .optional(),

    options: array()
      .required('Must have at least one pricing')
      .when('hasLimitedSpots', {
        is: true,
        then: array().test({
          name: "Spots don't exceed limit",
          message:
            'Total spot allocation exceeded. Please re-check your pricing options.',
          test(options) {
            const totalSpots = this.resolve(ref('totalSpots'))
            const allocatedSpots = reduce(
              (acc, option) => acc + option.spots,
              0,
              options
            )
            const hasAllocatedExcessSpots = allocatedSpots > totalSpots

            return !hasAllocatedExcessSpots
          },
        }),
      })
      .of(
        object({
          name: string()
            .matches(
              NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX,
              'Must not contain: !@#$%^&*~'
            )
            .required('This field is required'),
          description: string()
            .matches(
              NO_FORBIDDEN_SPECIAL_CHARACTERS_REGEX,
              'Must not contain: !@#$%^&*~'
            )
            .optional(),
          spots: number()
            .min(1, 'Must be at least 1')
            .required('This field is required'),
          price: number().required('This field is required'),
        })
      ),
  }),
})

const physicalActivitiesSchema = Validator.object({
  availabilities: array()
    .of(
      object({
        contact: object().when('isSelected', {
          is: true,
          then: object({
            name: string().required('This field is required'),
            phoneNumber: string()
              .matches(CONSTANTS.REGEX.E164_PHONE_FORMAT, 'Not a valid phone number')
              .required('This field is required'),
            email: string()
              .email('Not a valid email address')
              .required('This field is required'),
          }),
        }),

        pricing: pricingOptionSchema,

        events: array().when('isSelected', {
          is: true,
          then: array().when('hasExistingEvent', {
            is: false,
            then: array().required('Must select a Date & Time'),
          }),
        }),
      })
    )
    .test({
      name: 'Must select one venue',
      message: 'Must select at least one venue',
      test(availabilities) {
        const selectedAvailabilities = filter(({ isSelected }) => isSelected)(
          availabilities
        )

        return isNotEmpty(selectedAvailabilities)
      },
    }),
})

const Root = styled.div(s('flex-1 flex flex-column mt-6'), { overflow: 'hidden' })
const Row = styled.div(s('flex flex-row justify-between'))
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 mb-2')
)
const CreateVenueButton = styled(Button)(s('border-0'))

function PhysicalVenue({ venues, provider }) {
  const modalRef = useRef()
  const { values, errors, submitCount, setFieldValue } = useFormContext()
  const { setSubmitContext, currentStep } = useWizard()

  const { listingType, availabilities } = values
  const isOnVenueStep = prop('id')(currentStep) === 'venues'
  const [initialSubmitCount, setInitialSubmitCount] = useState(0)

  const hasAttemptedSubmit = submitCount > initialSubmitCount

  useEffect(() => {
    if (isOnVenueStep) {
      setInitialSubmitCount(submitCount)
    }
  }, [isOnVenueStep])

  useEffect(() => {
    if (!isOnVenueStep) {
      setSubmitContext({})
      return
    }

    const errorMessage = getPhysicalVenueError(errors, availabilities)

    if (isNil(errorMessage)) {
      setSubmitContext({})
    }

    if (hasAttemptedSubmit && errorMessage) {
      setSubmitContext({
        status: 'error',
        error: new Error(errorMessage),
      })
    }
  }, [errors, hasAttemptedSubmit, isOnVenueStep])

  if (listingType !== LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)) {
    return null
  }

  async function handleSubmit({ draftId, availabilities: submitAvailabilities }) {
    const mappedAvailabilities = []

    mappedAvailabilities.push(
      ...pipe(
        filter(prop('isSelected')),
        map(({ venue, contact, pricing, events }) => ({
          isOnline: false,
          activity: {
            venueId: venue.id,
            contact,
          },
          pricing,
          events,
        }))
      )(submitAvailabilities)
    )

    await DraftModule.createPhysicalAvailabilities(
      null,
      submitAvailabilities,
      mappedAvailabilities
    )

    const newDraftListing = await DraftModule.read(draftId)

    const availabilities = prop('availabilities')(newDraftListing)

    const newAvailabilities = isNotNilOrEmpty(availabilities)
      ? getPhysicalAvailabilities(availabilities, provider, venues)
      : getInitialPhysicalActivityObject(provider, venues)

    setFieldValue('availabilities', newAvailabilities)
  }

  return (
    <Wizard.Step
      id="venues"
      title="Venues"
      validationSchema={physicalActivitiesSchema}
      onSubmit={handleSubmit}
    >
      <CreateVenueModal ref={modalRef} />

      <Root>
        <Row>
          <Label>Physical venues</Label>

          <CreateVenueButton onClick={() => modalRef.current.open()}>
            Create new venue
          </CreateVenueButton>
        </Row>

        <PhysicalActivitiesForm />
      </Root>
    </Wizard.Step>
  )
}

export { PhysicalVenue }
