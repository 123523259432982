import { equals } from '@soltalabs/ramda-extra'
import React from 'react'

function deepMemo(Component) {
  return React.memo(Component, equals)
}

function memo(Component, propsAreEqual = equals) {
  return React.memo(Component, propsAreEqual)
}

export { deepMemo, memo }
