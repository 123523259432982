import {
  isNotNilOrEmpty,
  isString,
  path,
  append,
  map,
  prop,
  filter,
} from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useFormikContext as useFormContext, FieldArray } from 'formik'
import { useRef, useState, useCallback } from 'react'
import {
  Tabs,
  TabList as TabListBase,
  Tab as TabBase,
  TabPanel as PanelBase,
} from 'react-tabs'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { ReactComponent as CloseIcon } from 'assets/feathers/x-square.svg'
import { Button } from 'components/common/Button'
import { CheckboxField } from 'components/common/CheckboxField'
import { NumberField } from 'components/common/NumberField'
import { PhoneField } from 'components/common/PhoneField'
import { SelectField } from 'components/common/SelectField'
import { TextField } from 'components/common/TextField'
import { AddEventModal } from 'components/listings/common/AddEventModal'
import { EditEventModal } from 'components/listings/common/EditEventModal'
import { EventCalendar } from 'components/listings/common/EventCalendar'
import { ReadEventModal } from 'components/listings/common/ReadEventModal'
import { Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { timezones$ } from 'modules/operatingSystem'

import 'react-tabs/style/react-tabs.css'

const Root = styled(Tabs)(s('flex-1 flex flex-column'), {
  minHeight: 'min-content',
  overflowY: 'auto',
})
const Tab = styled(TabBase)(
  s('uppercase tracking-wide text-xs font-semibold px-4 py-2 rounded-t-lg')
)
const TabList = styled(TabListBase)(s('m-0 border-gray-500'))
const Panel = styled(PanelBase)(
  s('flex-1 p-4 border-1 border-transparent'),
  ({ readOnly }) =>
    readOnly
      ? {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        }
      : {}
)
const SectionTitle = styled.h2(s('my-6 text-base font-semibold text-gray-700'))
const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600'))
const ErrorMessage = styled.div(s('mb-2 text-error text-sm'))
const RemoveOptionButton = styled.button(s('border-0 p-0 m-0 bg-transparent'), {
  cursor: 'pointer',
})
const NewPricingOptionButton = styled(Button)(
  s('flex flex-row items-center border-0 bg-white p-2'),
  {
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': s('bg-gray-400'),
    '&:active': s('bg-gray-200'),
  }
)

Root.tabsRole = 'Tabs'
Tab.tabsRole = 'Tab'
TabList.tabsRole = 'TabList'
Panel.tabsRole = 'TabPanel'

const Connected = connect(() => ({
  timezones: timezones$,
}))(OnlineActivityForm)

function OnlineActivityForm({ timezones, isEditing = true }) {
  const addEventModalRef = useRef()
  const editEventModalRef = useRef()
  const readEventModalRef = useRef()

  const [isCalenderSelected, setIsCalenderSelected] = useState(false)

  const { values, errors, setFieldValue } = useFormContext()
  const hasLimitedSpots = path(['onlineActivity', 'pricing', 'hasLimitedSpots'], values)
  const options = path(['onlineActivity', 'pricing', 'options'], values)
  const events = path(['onlineActivity', 'events'], values)
  const listingTitle = path(['description', 'title'], values)
  const availability = prop('onlineActivity', values)

  const pricingOptionError = path(['onlineActivity', 'pricing', 'options'], errors)

  function handleCreateEvent(selectedStartDate) {
    addEventModalRef.current.open({
      title: 'Online activity',
      field: 'onlineActivity.events',
      listingTitle,
      selectedStartDate,
    })
  }

  const handleEditEvent = useCallback(({ start: startAt, ...rest }) => {
    editEventModalRef.current.open({
      field: 'onlineActivity.events',
      listingTitle,
      startAt,
      ...rest,
    })
  }, [])

  const handleReadEvent = useCallback(
    ({
      start: startAt,
      isThisAvailability,
      listingTitle: otherListingTitle,
      ...rest
    }) => {
      readEventModalRef.current.open({
        field: 'onlineActivity.events',
        listingTitle: isThisAvailability ? listingTitle : otherListingTitle,
        startAt,
        ...rest,
      })
    },
    []
  )

  function handleEventCreated(field, event) {
    setFieldValue(field, append(event, events))
  }

  const handleEventEdited = useCallback(
    (field, event) => {
      // remove same id event
      const updateEventId = prop('id')(event)
      const filteredEvents = filter(({ id }) => id !== updateEventId)(events)

      setFieldValue(field, append(event, filteredEvents))
    },
    [events]
  )

  function createAddPricingOptionHandler(push) {
    return () => push({ name: '', description: undefined, spots: 1, price: 0 })
  }

  function handleSelect(index) {
    if (index === 3) {
      setIsCalenderSelected(true)
      return
    }
    setIsCalenderSelected(false)
  }

  return (
    <Root onSelect={handleSelect}>
      <TabList>
        <Tab>Conference Details</Tab>
        <Tab>Contact Information</Tab>
        <Tab>Pricing</Tab>
        <Tab>Date & Time</Tab>
      </TabList>

      <Panel readOnly={!isEditing}>
        <Row>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={10}>
                <TextField name="onlineActivity.conference.link" label="Link" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={10}>
                <TextField name="onlineActivity.conference.passcode" label="Passcode" />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10}>
                <SelectField
                  items={timezones}
                  name="onlineActivity.conference.timezone"
                  label="Timezone"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>

      <Panel readOnly={!isEditing}>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <TextField name="onlineActivity.contact.name" label="Name" />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={10}>
            <PhoneField
              name="onlineActivity.contact.phoneNumber"
              label="Phone number"
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={10}>
            <TextField name="onlineActivity.contact.email" label="E-mail" />
          </Col>
        </Row>
      </Panel>

      <Panel readOnly={!isEditing}>
        <Row gutter={[16, 0]}>
          <Col sm={7} md={6} lg={6} xl={5}>
            <NumberField
              disabled={!hasLimitedSpots}
              name="onlineActivity.pricing.totalSpots"
              label="Total spots"
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={7} md={6} lg={6} xl={5}>
            <CheckboxField
              name="onlineActivity.pricing.hasLimitedSpots"
              label="Limited spots"
            />
          </Col>
        </Row>

        <SectionTitle>Pricing options</SectionTitle>

        <Row gutter={[16, 16]} style={options.length === 0 ? s('hidden') : {}}>
          <Col span={5}>
            <Label>Name</Label>
          </Col>

          <Col span={11}>
            <Label>Description</Label>
          </Col>

          <Col span={2}>
            <Label>Spots</Label>
          </Col>

          <Col span={3}>
            <Label>Price</Label>
          </Col>
        </Row>

        <FieldArray name="onlineActivity.pricing.options">
          {({ push, remove }) => (
            <>
              {options.map((option, index) => (
                <Row key={index} gutter={[16, 16]}>
                  <Col span={5}>
                    <TextField
                      characterLimit={100}
                      name={`onlineActivity.pricing.options[${index}].name`}
                    />
                  </Col>

                  <Col span={11}>
                    <TextField
                      characterLimit={100}
                      name={`onlineActivity.pricing.options[${index}].description`}
                    />
                  </Col>

                  <Col span={2}>
                    <NumberField
                      name={`onlineActivity.pricing.options[${index}].spots`}
                    />
                  </Col>

                  <Col span={3}>
                    <NumberField
                      name={`onlineActivity.pricing.options[${index}].price`}
                    />
                  </Col>

                  <Col span={3} style={s('flex flex-row items-center')}>
                    <RemoveOptionButton type="button" onClick={() => remove(index)}>
                      <CloseIcon width={28} height={28} stroke="#EE6C87" />
                    </RemoveOptionButton>
                  </Col>
                </Row>
              ))}

              {isString(pricingOptionError) && isNotNilOrEmpty(pricingOptionError) && (
                <ErrorMessage>{pricingOptionError}</ErrorMessage>
              )}

              <NewPricingOptionButton
                type="button"
                onClick={createAddPricingOptionHandler(push)}
              >
                <PlusIcon width={17} height={17} style={s('mr-2')} />
                Add pricing option
              </NewPricingOptionButton>
            </>
          )}
        </FieldArray>
      </Panel>

      <Panel forceRender>
        <AddEventModal onEventCreated={handleEventCreated} ref={addEventModalRef} />
        <EditEventModal
          onEventEdited={handleEventEdited}
          isEditing={isEditing}
          ref={editEventModalRef}
        />
        <ReadEventModal ref={readEventModalRef} />

        <EventCalendar
          isCalenderSelected={isCalenderSelected}
          availability={availability}
          events={useCallback(
            map(
              ({
                title,
                startDate,
                endDate,
                reoccurrence,
                reoccurrenceEnd,
                shouldUpdateEvent,
                ...rest
              }) => ({
                title,
                start: startDate,
                end: endDate,
                reoccurrence,
                reoccurrenceEnd,
                shouldUpdateEvent,
                type: shouldUpdateEvent ? 'scheduled' : undefined,
                isThisAvailability: true,
                ...rest,
              }),
              events
            ),
            [events]
          )}
          style={s('min-h-32')}
          onCreateEvent={handleCreateEvent}
          onEditEvent={handleEditEvent}
          onReadEvent={handleReadEvent}
          isEditing={isEditing}
        />
      </Panel>
    </Root>
  )
}

export { Connected as OnlineActivityForm }
