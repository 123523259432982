import { getMedia } from './getMedia'

import { PRODUCT_STATUSES, PRODUCT_STATUS_LABELS } from 'modules/product'
import { calcCharscount, convertFromHtml } from 'utils/convertSlateData'

function getProductFormInitialValues(inspectedProduct = null) {
  const initialValues = {
    details: {
      name: undefined,
      description: { text: undefined, charsCount: undefined },
      categories: [],
      summary: { text: undefined, charsCount: undefined },
      keywords: [],
      status: {
        label: PRODUCT_STATUS_LABELS[PRODUCT_STATUSES.DRAFT],
        value: PRODUCT_STATUSES.DRAFT,
      },
      price: 0,
    },
    media: {
      photos: [],
      video: undefined,
      brochure: undefined,
    },
    optionsAndVariants: {
      options: [],
      variants: [],
    },
  }

  if (inspectedProduct) {
    const {
      name,
      price,
      description,
      summary,
      status,
      categories,
      keywords,
      location,
      address,
      options,
      variants,
    } = inspectedProduct

    const details = {
      name,
      price,
      description: {
        text: convertFromHtml(description),
        charsCount: calcCharscount(convertFromHtml(description)),
        new: false,
      },
      summary: {
        text: convertFromHtml(summary),
        charsCount: calcCharscount(convertFromHtml(summary)),
        new: false,
      },
      status: {
        label: PRODUCT_STATUS_LABELS[status],
        value: status,
      },
      categories,
      keywords,
      location,
      address,
    }

    initialValues.details = details
    initialValues.media = getMedia(inspectedProduct)
    initialValues.optionsAndVariants = { options: options ?? [], variants }
  }
  return initialValues
}

export { getProductFormInitialValues }
