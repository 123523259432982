import { connect } from '@soltalabs/stateless'

import { SelectField } from 'components/common/SelectField'
import { timezones$ } from 'modules/operatingSystem'

const ConnectedTimezoneField = connect(() => ({
  timezones: timezones$,
}))(TimezoneField)

function TimezoneField({ timezones, ...props }) {
  return <SelectField items={timezones} {...props} />
}

export { ConnectedTimezoneField as TimezoneField }
