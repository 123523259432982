/* eslint-disable complexity */
import { isNil } from 'ramda'
import { useMemo } from 'react'

import { ReactComponent as TrashCan } from 'assets/feathers/trash-can.svg'
import { LISTING_STATUSES } from 'constants/listingStatus'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'
import { safelyParse } from 'utils/safelyParse'

const totalRows = 20

const statusMapper = {
  [LISTING_STATUSES.DRAFT]: 'Draft',
  [LISTING_STATUSES.AWAITING_APPROVAL]: 'Awaiting Approval',
  [LISTING_STATUSES.LISTED]: 'Approved',
  [LISTING_STATUSES.SUSPENDED]: 'Suspended',
  [LISTING_STATUSES.REJECTED]: 'Rejected',
}

const listingTypesMapper = {
  [LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)]: 'Online',
  [LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)]: 'Physical',
  [LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)]: 'Community',
}

const createEmptyRows = (fetchedList) => {
  for (let index = fetchedList.length; index < totalRows; index += 1) {
    fetchedList.push({
      id: null,
      title: null,
      createdAt: null,
      status: null,
    })
  }
}

const StyledTrashCan = styled(TrashCan)(s('mt-1 p-2'), {
  '&:hover': s('rounded-full bg-grey-light', { fill: '#000000' }),
})

export const Rows = (list) =>
  useMemo(() => {
    const listingsList = list.slice()
    if (listingsList.length < totalRows) {
      createEmptyRows(listingsList)
    }
    return listingsList.map(({ id, title, listingType, createdAt, status }) => ({
      id,
      title,
      listingType: listingTypesMapper[listingType],
      createdAt: isNil(createdAt) ? null : safelyParse(createdAt),
      status: statusMapper[status],
      delete:
        statusMapper[status] === 'Draft' ? (
          <StyledTrashCan key="delete" fill="#8795a1" width={30} height={30} />
        ) : null,
      isRowRequiresActiveProvider: statusMapper[status] === 'Draft',
    }))
  }, [list])
