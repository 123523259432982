import { StatusSelector } from '../../common/StatusSelector'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { SearchInput } from 'components/common/SearchInput'
import { DateRangePicker } from 'components/common/withTable/DateRangePicker'
import { LISTING_STATUSES } from 'constants/listingStatus'
import { SUSPEND_ACTIONS } from 'constants/suspendActions'
import { styled, s } from 'lib/styled'

const Root = styled.div(
  s(
    'flex flex-row px-4 py-3 items-center border-0 border-b-1 border-solid border-gray-400'
  ),
  {
    'z-index': 2,
  }
)
const CreateListingButton = styled.div(
  s(
    'ml-a flex flex-row items-center bg-primary text-white font-normal border-0 text-xs uppercase tracking-wide pl-2 pr-3 py-2 rounded-lg'
  ),
  {
    '&:hover': s('bg-primary-dark', {
      cursor: 'pointer',
    }),
  }
)

const statusOptions = [
  { label: 'All listings', value: '' },
  { label: 'Draft listings', value: LISTING_STATUSES.DRAFT },
  { label: 'Awaiting Approval', value: LISTING_STATUSES.AWAITING_APPROVAL },
  { label: 'Approved listings', value: LISTING_STATUSES.LISTED },
  { label: 'Suspended listings', value: LISTING_STATUSES.SUSPENDED },
  { label: 'Rejected listings', value: LISTING_STATUSES.REJECTED },
]

function Filters({
  isProviderSuspended,
  filterQuery,
  filterListings,
  filterStatus,
  filterStatusListings,
  dateRange,
  filterDate,
  onOpenSuspendMessageModal,
  onOpenSelectListingTypeModal,
}) {
  return (
    <Root>
      <SearchInput
        value={filterQuery}
        onChange={filterListings}
        placeholder="Search by title"
      />
      <DateRangePicker
        style={s('ml-3')}
        dateRange={dateRange}
        filterDate={filterDate}
      />
      <StatusSelector
        style={s('ml-3')}
        statusOptions={statusOptions}
        value={filterStatus}
        onChange={filterStatusListings}
      />

      <CreateListingButton
        onClick={() => {
          if (isProviderSuspended) {
            onOpenSuspendMessageModal(SUSPEND_ACTIONS.CREATE_NEW_LISTING)
            return
          }
          onOpenSelectListingTypeModal(true)
        }}
      >
        <PlusIcon
          width={16}
          height={16}
          stroke="#FFF"
          strokeWidth="3px"
          style={s('mr-1')}
        />
        New listing
      </CreateListingButton>
    </Root>
  )
}

export { Filters }
