import { pathOr, mergeRight } from '@soltalabs/ramda-extra'

function transformProvider(provider) {
  return mergeRight(
    { ...provider },
    {
      socialNetworks: {
        facebook: pathOr('')(['socialNetworks', 'facebook'])(provider),
        instagram: pathOr('')(['socialNetworks', 'instagram'])(provider),
        twitter: pathOr('')(['socialNetworks', 'twitter'])(provider),
      },
    }
  )
}

export { transformProvider }
