import { getFuzzyLocalTimeFromPoint } from '@mapbox/timespace'
import { isNotNil } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { useFormikContext as useFormContext } from 'formik'
import { useEffect } from 'react'

import { SelectField } from 'components/common/SelectField'
import { timezones$ } from 'modules/operatingSystem'

const ConnectedTimezoneField = connect(() => ({
  timezones: timezones$,
}))(TimezoneField)

function TimezoneField({ name, label, timezones }) {
  const { values, setFieldValue } = useFormContext()
  const { address } = values

  useEffect(() => {
    if (isNotNil(address)) {
      const timezone = getFuzzyLocalTimeFromPoint(Date.now(), [
        address?.geometry?.location?.lng(),
        address?.geometry?.location?.lat(),
      ]).tz()

      setFieldValue(name, timezone)
    }
  }, [address])

  return <SelectField items={timezones} name={name} label={label} />
}

export { ConnectedTimezoneField as TimezoneField }
