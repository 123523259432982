import { defaultTo, prop } from '@soltalabs/ramda-extra'
import { normalize, schema } from 'normalizr'

import { http } from 'httpClient'

const listingEntity = new schema.Entity('listingEvent')

const ListingEventService = {
  async list({ from, to }) {
    const config = {
      searchParams: {
        limit: 999,
        from,
        to,
      },
    }

    const { items } = await http.get('listing-events', config).json()

    const normalized = normalize(items, [listingEntity])
    const entities = defaultTo({}, prop('listingEvent', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
    }
  },

  async read(id) {
    const config = {
      authorize: true,
    }
    return http.get(`listing-events/${id}`, config).json()
  },
}

export { ListingEventService }
