import { isNil, prop, path, map, reduce, append } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { availabilityModule } from './module'

const { state$ } = availabilityModule

const availabilityEntities$ = select(state$, prop('entities'))

const order$ = select(state$, prop('order'))

const availabilityList$ = select(availabilityEntities$, order$, (entities, order) =>
  map((id) => prop(id, entities), order)
)

const scheduledEvents$ = select(
  availabilityList$,
  reduce((acc, availability) => {
    const listingTitle = path(['listing', 'title'], availability)
    const { startAt } = availability

    if (isNil(startAt)) {
      return acc
    }

    const event = {
      type: 'scheduled',
      listingTitle,
      startAt,
    }

    return append(event, acc)
  }, [])
)

export { scheduledEvents$ }
