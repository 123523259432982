import { isEmpty, filter, findIndex } from '@soltalabs/ramda-extra'
import { useSelect } from 'downshift'
import { useField } from 'formik'
import { useRef, useState, useEffect } from 'react'
import { AutoSizer, List as VirtualizedList } from 'react-virtualized'

import { RequiredIndicator } from './RequiredIndicator'

import { ReactComponent as ChevronDownIcon } from 'assets/feathers/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/feathers/chevron-up.svg'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('relative'), ({ renderShadow }) =>
  renderShadow ? s('shadow-sm') : {}
)
const Label = styled.label(
  s('block uppercase tracking-wide text-xs text-gray-600 font-light'),
  { lineHeight: 1 },
  ({ labelPosition }) => (labelPosition === 'inline' ? s('mr-2') : s('mb-2'))
)
const InputButton = styled.button(
  s(
    'flex flex-row w-full items-center px-3 py-2 border-0 border-b-2 border-solid border-gray-500 bg-gray-200 rounded-lg text-black'
  ),
  { overflow: 'hidden' },
  ({ disabled }) =>
    disabled
      ? s('bg-gray-400 border-gray-700 text-gray-800', {
          cursor: disabled ? 'default' : 'pointer',
        })
      : {},
  ({ isAttachedToSuggestions }) => (isAttachedToSuggestions ? s('rounded-b-none') : {})
)
const Menu = styled.ul(s('absolute z-1 p-0 m-0 bg-gray-200'), ({ isVisible }) =>
  isVisible ? s('block') : s('hidden')
)
const Filter = styled.input(
  s('bg-gray-100 rounded-lg my-2 mx-2 p-2 border-1 border-solid border-gray-300'),
  ({ width }) => ({
    width: width - 16,
  }),
  ({ isEnabled }) =>
    !isEnabled
      ? s('hidden', {
          '&:first-of-type': s('border-t-0'),
        })
      : {}
)
const Suggestions = styled(VirtualizedList)(
  s('border-0 border-b-2 border-solid border-gray-500 rounded-b-lg text-gray-700'),
  {
    overflow: 'hidden',
  }
)
const Item = styled.li(
  s('px-3 py-2'),
  {
    cursor: 'pointer',
    '&:hover': s('bg-gray-400'),
  },
  ({ isHighlighted, isCurrentValue }) =>
    isHighlighted && !isCurrentValue ? s('bg-gray-400') : {}
)
const ErrorMessage = styled.div(s('static mt-2 text-error text-sm'))

// eslint-disable-next-line complexity
function SelectField({
  items = [],
  itemToString = (item) => item,
  disabled,
  name,
  id,
  type = 'select',
  label,
  searchable = true,
  style,
  showRequiredIndicator = false,
  ...props
}) {
  const suggestionsRef = useRef()
  const [filterQuery, setFilterQuery] = useState('')
  const [filteredItems, setFilteredItems] = useState(items)

  const [
    { onChange: onFieldChange, onBlur: onFieldBlur, ...fieldProps },
    { touched, error },
  ] = useField({
    name,
    id,
    type,
    ...props,
  })

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    itemToString,
    defaultSelectedItem: fieldProps.value,

    onSelectedItemChange: ({ selectedItem }) => {
      onFieldChange({ target: { value: selectedItem, name } })
    },
  })

  useEffect(() => {
    if (isEmpty(filterQuery)) {
      setFilteredItems(items)
    } else {
      setFilteredItems(
        filter((item) => item.match(new RegExp(filterQuery, 'i')), items)
      )
    }

    suggestionsRef.current.forceUpdateGrid()
  }, [filterQuery])

  const ButtonIcon = isOpen ? ChevronUpIcon : ChevronDownIcon

  return (
    <Root {...props}>
      <Label {...getLabelProps({ htmlFor: name })}>
        {label} <RequiredIndicator showRequiredIndicator={showRequiredIndicator} />
      </Label>

      <InputButton
        type="button"
        isAttachedToSuggestions={isOpen}
        disabled={disabled}
        {...getToggleButtonProps({ disabled, ...fieldProps })}
      >
        {itemToString(selectedItem)}

        {!disabled && (
          <ButtonIcon
            stroke="#2C2C2C"
            strokeWidth="2px"
            width={20}
            height={20}
            style={s('ml-a')}
          />
        )}
      </InputButton>

      {!isOpen && touched && error && <ErrorMessage>{error}</ErrorMessage>}

      <AutoSizer style={s('absolute')}>
        {({ width }) => (
          <Menu {...getMenuProps()} isVisible={isOpen}>
            <Filter
              isEnabled={searchable}
              placeholder="Search"
              type="search"
              value={filterQuery}
              onChange={(event) => setFilterQuery(event.target.value)}
              width={width}
            />

            <Suggestions
              ref={suggestionsRef}
              rowCount={filteredItems.length}
              rowHeight={30}
              width={width}
              height={128}
              rowRenderer={({ index: virtualIndex, style: itemStyle }) => {
                const virtualItem = filteredItems[virtualIndex]
                const index = findIndex((item) => item === virtualItem, items)

                return (
                  <Item
                    {...getItemProps({ item: virtualItem, index, disabled })}
                    key={virtualItem}
                    isHighlighted={highlightedIndex === index}
                    style={itemStyle}
                  >
                    {itemToString(virtualItem)}
                  </Item>
                )
              }}
            />
          </Menu>
        )}
      </AutoSizer>
    </Root>
  )
}

export { SelectField }
