import { path, prop, filter } from '@soltalabs/ramda-extra'

const isAdministrativeAreaLevelTwo = (addressComponent) =>
  addressComponent.types.includes('administrative_area_level_2')
const isLocality = (addressComponent) => addressComponent.types.includes('locality')
const isRoute = (addressComponent) => addressComponent.types.includes('route')
const isCountry = (addressComponent) => addressComponent.types.includes('country')

function createAddressObject(address) {
  return {
    formattedAddress: prop('formatted_address', address),
    street: path(
      [0, 'long_name'],
      filter(isRoute, prop('address_components', address))
    ),
    suburb: path(
      [0, 'long_name'],
      filter(isLocality, prop('address_components', address))
    ),
    state: path(
      [0, 'long_name'],
      filter(isAdministrativeAreaLevelTwo, prop('address_components', address))
    ),
    country: path(
      [0, 'long_name'],
      filter(isCountry, prop('address_components', address))
    ),
  }
}

export { createAddressObject }
