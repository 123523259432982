import { isNil } from '@soltalabs/ramda-extra'
import { useState } from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import { ReactComponent as InfoIcon } from 'assets/feathers/info.svg'
import { ReactComponent as XIcon } from 'assets/feathers/x.svg'
// import CommunityImg from 'assets/img/community.jpg'
import PhysicalActivityImg from 'assets/img/physical-activity.jpg'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import { LISTING_TYPES } from 'modules/listing'

const Root = styled.div(s('text-black bg-white rounded-lg p-4 px-8 pb-2'))

const Title = styled.h2(s('text-2xl mb-5 px-2'))

const NextButton = styled.div(
  s(
    'flex flex-1 justify-center bg-primary text-white font-semibold border-0 text-base uppercase tracking-wide py-3 rounded-lg'
  ),

  ({ disabled }) =>
    disabled
      ? s('bg-grey', { cursor: 'default' })
      : {
          cursor: 'pointer',
          '&:hover': s('bg-primary-dark'),
        }
)

const StyledXIcon = styled(XIcon)(s('p-1'), {
  boxSizing: 'content-box',
  '&:hover': s('rounded-lg bg-grey-lighter', { fill: '#000000' }),
})

const ListingTypeContainer = styled.div(
  s('p-3 border-solid border-1 rounded-lg shadow-lg mx-3'),
  { width: '280px' },
  ({ listingType, selectedType }) =>
    listingType === selectedType
      ? s('border-primary-dark')
      : s('border-gray-300', {
          '&:hover': s('border-primary'),
        })
)

const Detail = styled.div(s('flex flex-row flex-1'))

const createListingPathMapper = {
  [LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL)]: '/listings/new-listing/physical',
  [LISTING_TYPES.valueOf(LISTING_TYPES.ONLINE)]: '/listings/new-listing/online',
  [LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY)]: '/listings/new-listing/community',
}

const availableListingTypes = [
  {
    type: LISTING_TYPES.valueOf(LISTING_TYPES.PHYSICAL),
    displayName: 'Physical activity',
    description:
      'Participants will attend the event at your selected venue, for hands-on experience.',
    img: PhysicalActivityImg,
  },
  // {
  //   type: LISTING_TYPES.valueOf(LISTING_TYPES.COMMUNITY),
  //   displayName: 'Community',
  //   description: 'Participants will be able to explore free public facilities.',
  //   img: CommunityImg,
  // },
]

Modal.setAppElement('#root')

function SelectListingTypeModal({ isOpen, setIsOpen }) {
  const history = useHistory()
  const [selectedType, setSelectedType] = useState(undefined)

  async function handleSubmit() {
    history.push(createListingPathMapper[selectedType])

    setSelectedType(undefined)
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: s('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: s('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '15%',
        }),
      }}
    >
      <Container>
        <Row justify="center">
          <Col>
            <Root>
              <Row style={s('justify-end')}>
                <StyledXIcon
                  width={20}
                  height={20}
                  onMouseDown={() => {
                    setIsOpen(false)
                    setSelectedType(undefined)
                  }}
                />
              </Row>

              <Title>Your listing type</Title>
              <Row align="center">
                {availableListingTypes.map(
                  ({ type, displayName, description, img }) => (
                    <ListingTypeContainer
                      key={type}
                      listingType={type}
                      selectedType={selectedType}
                      onMouseDown={() => setSelectedType(type)}
                    >
                      <Detail>
                        <img style={s('w-full h-10 rounded-lg')} src={img} />
                      </Detail>

                      <Detail style={s('text-xl py-3 font-semibold')}>
                        {displayName}
                      </Detail>

                      <Detail style={s('tracking-wide', { lineHeight: 1.5 })}>
                        {description}
                      </Detail>
                    </ListingTypeContainer>
                  )
                )}
              </Row>

              <Row style={s('mt-8 mb-2 justify-center')}>
                <Col>
                  <NextButton onClick={handleSubmit} disabled={isNil(selectedType)}>
                    {"Let's create"}
                  </NextButton>

                  <Row style={s('my-2 text-center', { fontSize: '10px' })}>
                    <InfoIcon
                      width={10}
                      height={10}
                      stroke={s('text-primary').color}
                      onMouseDown={() => {
                        setIsOpen(false)
                      }}
                      style={s('mr-1')}
                    />
                    You can’t change your activity type, if you change your mind please
                    create a new listing.
                  </Row>
                </Col>
              </Row>
            </Root>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export { SelectListingTypeModal }
