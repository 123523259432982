import CONSTANTS from '@solta/constants'
import { omit, isNilOrEmpty } from '@soltalabs/ramda-extra'
import { useField } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { LogoUpload } from './LogoUpload'

import { ReactComponent as FacebookIcon } from 'assets/img/facebook-round-filled.svg'
import { ReactComponent as InstagramIcon } from 'assets/img/instagram-outline.svg'
import { ReactComponent as TwitterIcon } from 'assets/img/twitter-filled.svg'
import { AddressField } from 'components/common/AddressField'
import { CheckboxField } from 'components/common/CheckboxField'
import { PhoneField, NUMBER_FORMATS } from 'components/common/PhoneField'
import { TextAreaField } from 'components/common/TextAreaField'
import { TextField } from 'components/common/TextField'
import { Wizard } from 'lib/formik-wizard'
import { Row, Col } from 'lib/react-grid'
import { styled, s, smaller } from 'lib/styled'
import { providerModule } from 'modules/provider'

const { string, object, boolean, mixed } = Validator

const requiredString = string().required('This field is required')

const URLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const urlValidator = string().matches(URLRegex, 'Must be a URL')

const onlyNumbers = string().matches(/^[0-9]+$/, 'Must contain only numbers')

const buildTaxNumberValidation = () => ({
  is: false,
  then: string().required('This field is required'),
  otherwise: string().length(0, 'Not GST registered is checked'),
})

const validationSchema = Validator.object().shape({
  provider: object().shape({
    facebookUrl: urlValidator,
    instagramUrl: urlValidator,
    twitterUrl: urlValidator,

    name: requiredString,
    businessRegistrationNumber: onlyNumbers,
    notGSTRegistered: boolean(),
    taxNumber: string().when('notGSTRegistered', buildTaxNumberValidation()),
    tradingName: string(),
    bankDetails: object().shape({
      accountName: requiredString,
      accountNumber: onlyNumbers.required('This field is required'),
      bsbNumber: onlyNumbers.required('This field is required'),
      bankName: requiredString,
      amlVerification: boolean().optional(),
    }),
    website: urlValidator,
    phoneNumber: requiredString.matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
    email: requiredString.email('Not a valid email address'),
    currentAddress: mixed(),
    address: object().when('currentAddress', {
      is: isNilOrEmpty,
      then: object().required('Not a valid address'),
    }),
    businessSummary: string(),
  }),
})

const Root = styled.div(s('pt-8 pl-6 pr-4 pb-6'))
const Title = styled.h1(
  s('font-light m-0 mb-8 text-gray-800 text-center text-lg'),
  smaller('sm')(s('text-lg'))
)
const Label = styled.span(
  s('mb-2 uppercase tracking-wide text-xs text-gray-600 font-light')
)
const Socials = styled.div(s('flex-1 flex flex-column'))
const SocialContainer = styled.div(s('flex-1 flex flex-column pl-3'))

function BusinessStep({ logo }) {
  const history = useHistory()
  const [{ value: currentAddress }] = useField({ name: 'provider.currentAddress' })

  async function handleSubmit({ providerId, ...values }) {
    const id = await providerModule.createProvider(null, {
      ...omit(['currentAddress'])(values.provider),
      ...values.payment,
    })

    await providerModule.activateProvider(null, id)
    history.push('/')
  }

  return (
    <Wizard.Step
      id="business"
      title="Business"
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Root>
        <Row justify="center">
          <Col>
            <Title>Set up your business</Title>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12} style={s('flex flex-column')}>
            <Label htmlFor="">Logo</Label>
            <LogoUpload logo={logo} />
          </Col>

          <Col span={12}>
            <Socials>
              <Label>Socials</Label>

              <SocialContainer>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextField
                      name="provider.facebookUrl"
                      labelPosition="inline"
                      label={<FacebookIcon width={28} height={28} />}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextField
                      name="provider.instagramUrl"
                      labelPosition="inline"
                      label={<InstagramIcon width={28} height={28} />}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <TextField
                      name="provider.twitterUrl"
                      labelPosition="inline"
                      label={<TwitterIcon width={28} height={28} />}
                    />
                  </Col>
                </Row>
              </SocialContainer>
            </Socials>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextField
              name="provider.name"
              label="Registered Company Name"
              showRequiredIndicator
              characterLimit={150}
              tooltip="We need this information to provide invoices on your behalf"
            />
          </Col>

          <Col span={12}>
            <TextField
              name="provider.businessRegistrationNumber"
              label="Company Number (NZBN/ABN)"
              characterLimit={15}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextField
              name="provider.tradingName"
              label="Trading as"
              characterLimit={150}
            />
          </Col>

          <Col span={12}>
            <TextField
              name="provider.taxNumber"
              label="GST Number"
              showRequiredIndicator
              characterLimit={32}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12} />

          <Col span={12}>
            <CheckboxField
              name="provider.notGSTRegistered"
              label="Not GST Registered"
              style={s('pt-0 text-xs')}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextField
              name="provider.bankDetails.accountName"
              label="Account name"
              showRequiredIndicator
              tooltip="This is the account name for the bank account you wish to receive funds into"
              characterLimit={32}
            />
          </Col>

          <Col span={12}>
            <TextField
              name="provider.bankDetails.accountNumber"
              label="Account number"
              showRequiredIndicator
              tooltip="This is the account number of the account you would like to receive payments into"
              characterLimit={32}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextField
              name="provider.bankDetails.bsbNumber"
              label="BSB number"
              showRequiredIndicator
              tooltip="This is the BSB number associated with the bank branch you would like to receive payments into"
              characterLimit={32}
            />
          </Col>

          <Col span={12}>
            <TextField
              name="provider.bankDetails.bankName"
              label="Bank name"
              showRequiredIndicator
              tooltip="This is the name of the bank associated with the account you would like to receive payments into"
              characterLimit={32}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextField
              name="provider.email"
              label="Contact E-mail"
              showRequiredIndicator
              tooltip="This is the email address we will provide to people wanting to enquire about your listing"
            />
          </Col>
          <Col span={12}>
            <TextField name="provider.website" label="Website" />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <PhoneField
              name="provider.phoneNumber"
              label="Phone Number"
              showRequiredIndicator
              placeholderNumberType={NUMBER_FORMATS.FIXED_LINE_OR_MOBILE}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <AddressField
              name="provider.address"
              label="Service Location"
              showRequiredIndicator
              placeholder={currentAddress}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <TextAreaField
              name="provider.businessSummary"
              label="Business Summary"
              minRows={3}
              characterLimit={300}
            />
          </Col>
        </Row>
      </Root>
    </Wizard.Step>
  )
}

export { BusinessStep }
