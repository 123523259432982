import { prop, path, pathOr } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { Redirect } from 'react-router-dom'

import { BusinessStep } from './BusinessStep'
import { OnboardingNavigation } from './OnboardingNavigation'
import { ProfileForm as ProfileStep } from './ProfileStep'

import { Header } from 'components/common/Header'
import { WizardProgress } from 'components/common/WizardProgress'
import { Wizard } from 'lib/formik-wizard'
import { Container, Row, Col } from 'lib/react-grid'
import { styled, s } from 'lib/styled'
import {
  hasVerifiedEmail$,
  hasPlayzAccount$,
  hasOnboarded$,
  firstName$,
  lastName$,
  phoneNumber$,
  accountEmail$,
} from 'modules/auth'
import { PROVIDER_TYPES, provider$ } from 'modules/provider'

const Content = styled(Container)(
  s('flex-1 flex flex-column py-6', {
    overflow: 'hidden',
  })
)
const Card = styled.div(s('flex flex-column w-full shadow-sm bg-white rounded-lg'), {
  overflow: 'hidden',
})

const Scroll = styled.div(s('flex-1 flex flex-column'), {
  overflowY: 'auto',
})

const ConnectedOnboardingPage = connect(() => ({
  hasVerifiedEmail: hasVerifiedEmail$,
  hasPlayzAccount: hasPlayzAccount$,
  hasOnboarded: hasOnboarded$,
  firstName: firstName$,
  lastName: lastName$,
  phoneNumber: phoneNumber$,
  accountEmail: accountEmail$,
  provider: provider$,
}))(OnboardingPage)

function OnboardingPage({
  hasVerifiedEmail,
  hasPlayzAccount,
  hasOnboarded,
  firstName,
  lastName,
  phoneNumber,
  provider,
}) {
  const providerId = prop('id')(provider)
  const socialNetworks = prop('socialNetworks')(provider)

  if (!hasVerifiedEmail) {
    return <Redirect to="/verify" />
  }

  if (hasOnboarded) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Header />

      <Content gutter={24}>
        <Row justify="center" style={s('w-full h-full')}>
          <Col md={24} lg={20} style={s('flex flex-column justify-center h-full')}>
            <Card>
              <Wizard
                basePath="/setup"
                enableReinitialize
                initialValues={{
                  hasPlayzAccount,
                  providerId,

                  profile: {
                    firstName,
                    lastName,
                    phoneNumber,
                  },

                  provider: {
                    facebookUrl: pathOr('')(['facebook'])(socialNetworks),
                    instagramUrl: pathOr('')(['instagram'])(socialNetworks),
                    twitterUrl: pathOr('')(['twitter'])(socialNetworks),

                    name: pathOr('')(['name'])(provider),
                    businessRegistrationNumber: pathOr('')([
                      'businessRegistrationNumber',
                    ])(provider),
                    notGSTRegistered: false,
                    taxNumber: pathOr('')(['taxNumber'])(provider),
                    tradingName: pathOr('')(['tradingName'])(provider),
                    bankDetails: {
                      accountName: pathOr('')(['accountName'])(provider?.bankDetails),
                      accountNumber: pathOr('')(['accountNumber'])(
                        provider?.bankDetails
                      ),
                      bsbNumber: pathOr('')(['bsbNumber'])(provider?.bankDetails),
                      bankName: pathOr('')(['bankName'])(provider?.bankDetails),
                    },
                    website: pathOr('')(['website'])(provider),
                    phoneNumber: pathOr('')(['phone'])(provider),
                    email: pathOr('')(['email'])(provider),
                    currentAddress: path(['address', 'formattedAddress'])(provider),
                    address: undefined,
                    businessSummary: pathOr('')(['businessSummary'])(provider),
                  },

                  payment: {
                    providerType: pathOr(PROVIDER_TYPES.STANDARD)(['providerType'])(
                      provider
                    ),
                  },
                }}
                formProps={{
                  style: s('flex flex-column h-full'),
                }}
              >
                <WizardProgress style={s('mx-6 mt-2')} />

                <Scroll>
                  <ProfileStep />
                  <BusinessStep logo={prop('logo')(provider)} />
                </Scroll>

                <OnboardingNavigation style={s('mx-6 mb-4 pt-2')} />
              </Wizard>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export { ConnectedOnboardingPage as OnboardingPage }
