import { prop, includes, filter, map } from '@soltalabs/ramda-extra'
import { useField, useFormikContext as useFormContext } from 'formik'

import { SelectFieldBase } from './SelectFieldBase'

import { COUNTRIES } from 'constants/countries'
import { styled, s } from 'lib/styled'

const Root = styled.div(s('w-full flex-1 flex flex-column'))

const Label = styled.label(s('uppercase tracking-wide text-xs text-gray-600 mb-2'))
const ErrorMessage = styled.div(s('mt-2 text-error text-sm'))

const countryCodeMapper = {
  [COUNTRIES.AU]: 'Australia/',
  [COUNTRIES.NZ]: 'Pacific/Auckland',
  [COUNTRIES.Fiji]: 'Pacific/Fiji',
}

function TimezoneSelectField({ name, id, label, type, readOnly, timezones, ...props }) {
  const { values } = useFormContext()
  const { taxCountry } = values

  const [fieldProps, { touched, error }] = useField({ name, id, ...props })

  const { value, onChange } = fieldProps

  const options = map((option) => ({ value: option, label: option }))(
    filter((timezone) => includes(countryCodeMapper[taxCountry])(timezone))(timezones)
  )

  return (
    <Root>
      <Label>{label}</Label>

      <SelectFieldBase
        value={{ value, label: value }}
        type={type}
        onChange={(selectedOption) => {
          const event = { target: { value: prop('value')(selectedOption), name } }

          onChange(event)
        }}
        options={options}
        isDisabled={readOnly}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { TimezoneSelectField }
