/* eslint-disable react/jsx-key */
import { includes, isNil, path } from '@soltalabs/ramda-extra'
import { Link as LinkBase } from 'react-router-dom'

import { SUSPEND_ACTIONS } from 'constants/suspendActions'
import { styled, s } from 'lib/styled'

const unlinkableTableList = ['orderHistory']

const Link = styled(LinkBase)(s('py-3 no-decoration text-black'), {
  display: 'table-cell',
  verticalAlign: 'middle',
  '&:hover': s('text-black'),
})

const Tr = styled.tr(
  s(
    'flex items-center text-black no-decoration text-xs border-0 border-b-1 border-solid border-gray-200'
  ),
  {
    height: '44px',
    '&:hover': s('bg-blue-lightest'),

    '&:not(:hover)': {
      '& .toolIcon': s('hidden'),
    },

    '&:last-child': {
      '& td': {
        borderBottom: 0,
      },
    },
  },
  ({ domain, isEmptyRow }) =>
    (includes(domain, unlinkableTableList) || isEmptyRow) && {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }
)

const Td = styled.div(s('m-0 px-3'), {
  '&:last-child': {
    borderRight: 0,
  },
})

const Tbody = styled.tbody(s('text-center text-sm'))

const ToolIconTd = styled.div(
  s('m-0'),
  {
    '&:last-child': {
      borderRight: 0,
    },
  },
  ({ minWidth }) => ({ minWidth }),
  ({ cellValue }) => isNil(cellValue) && s('hidden'),
  ({ isProviderSuspended }) => isProviderSuspended && s('hidden')
)

function TableBody({
  getTableBodyProps,
  prepareRow,
  rows,
  onClick,
  domain,
  onOpenDeleteModal = () => {},
  onOpenSuspendMessageModal = () => {},
  isProviderSuspended = false,
}) {
  return (
    <>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)

          const isRowRequiresActiveProvider = path([
            'original',
            'isRowRequiresActiveProvider',
          ])(row)

          return (
            <Tr
              {...row.getRowProps({
                onClick: () => onClick(row.original.id),
              })}
              domain={domain}
              isEmptyRow={isNil(path(['original', 'id'], row))}
            >
              {row.cells.map((cell) =>
                path(['column', 'id'], cell) === 'toolIcon' ? (
                  <ToolIconTd
                    className="toolIcon"
                    minWidth={path(['column', 'width'], cell)}
                    textAlign={path(['column', 'textAlign'], cell)}
                    cellValue={path(['value'], cell)}
                    isProviderSuspended={isProviderSuspended}
                    onClick={() => onOpenDeleteModal(row.original.id)}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </ToolIconTd>
                ) : (
                  <Link
                    to={`/${domain}/${row.original.id}`}
                    style={{
                      width: path(['column', 'width'], cell),
                      textAlign: path(['column', 'textAlign'], cell),
                    }}
                    onClick={(e) => {
                      if (isProviderSuspended && isRowRequiresActiveProvider) {
                        e.preventDefault()

                        onOpenSuspendMessageModal(SUSPEND_ACTIONS.EDIT_DRAFT_LISTING)
                      }
                    }}
                  >
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  </Link>
                )
              )}
            </Tr>
          )
        })}
      </Tbody>
    </>
  )
}

export { TableBody }
