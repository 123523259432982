/* eslint-disable no-shadow */
import { createContext, useState, useEffect, useContext } from 'react'

const DEFAULT_CALLBACK_HANDLER = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const WalletContext = createContext()
export const useWallet = () => useContext(WalletContext)
export const WalletProvider = ({
  instance,
  onAuthorizationCallback = DEFAULT_CALLBACK_HANDLER,
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState()

  useEffect(() => {
    const initializeWallet = async () => {
      await instance.initialize()

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await instance.handleAuthorizationCallback()
        onAuthorizationCallback(appState)
      }

      const isAuthenticated = await instance.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await instance.getUser()
        setUser(user)
      }

      setIsLoading(false)
    }
    initializeWallet()
    // eslint-disable-next-line
  }, [])

  return (
    <WalletContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        requestAuthorization: (...p) => instance.requestAuthorization(...p),
        logout: (...p) => instance.logout(...p),
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
