import { keyframes, styled, s } from 'lib/styled'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div(
  s('rounded-full bg-transparent border-solid self-center', {
    animation: `1.2s ${spin} cubic-bezier(0.66, 0, 0.2, 0.94) infinite`,
  }),
  ({ size, thickness, color, backgroundColor }) => ({
    width: size,
    height: size,
    borderWidth: thickness,
    borderColor: backgroundColor,
    borderTopColor: color,
  })
)

function Spinner({
  size = 48,
  thickness = 6,
  color = '#36AF9E',
  backgroundColor = '#bbb',
  ...props
}) {
  return (
    <Container
      size={size}
      thickness={thickness}
      color={color}
      backgroundColor={backgroundColor}
      {...props}
    />
  )
}

export { Spinner }
