import { pick } from '@soltalabs/ramda-extra'
import { createModule } from '@soltalabs/stateless'
import { schema, normalize } from 'normalizr'

import { VenueService } from './service'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
})

const venueSchema = new schema.Entity('venues')

const fetchVenues = (module) => async () => {
  const { items } = await VenueService.list()
  const normalized = normalize(items, [venueSchema])
  const { venues } = normalized.entities
  const order = normalized.result

  module.setState({
    entities: venues,
    order,
  })
}

const createPhysicalVenue =
  (module) =>
  async (_, data, { fetchNewVenues = true } = {}) => {
    const venue = await VenueService.createPhysical(
      pick(
        [
          'name',
          'type',
          'address',
          'timezone',
          'hasWheelchairAccess',
          'transportNotes',
          'parkingNotes',
        ],
        data
      )
    )

    module.setState({
      entities: { [venue.id]: venue },
    })

    if (fetchNewVenues) {
      module.fetchVenues()
    }

    return venue
  }

const updatePhysicalVenue =
  (module) =>
  async (_, id, data, { fetchNewVenues = true } = {}) => {
    const venue = await VenueService.updatePhysical(
      id,
      pick(
        [
          'name',
          'address',
          'timezone',
          'hasWheelchairAccess',
          'transportNotes',
          'parkingNotes',
        ],
        data
      )
    )

    module.setState({
      entities: { [venue.id]: venue },
    })

    if (fetchNewVenues) {
      module.fetchVenues()
    }
  }

const deletePhysicalVenue = (module) => async (_, venue) => {
  const { id } = venue

  await VenueService.deletePhysical(id)

  module.fetchVenues()
}

const createOnlineVenue = (module) => async (_, data) => {
  const venue = await VenueService.createOnline(
    pick(['link', 'passcode', 'timezone'], data)
  )

  module.setState({ entities: { [venue.id]: venue } })

  return venue
}

const updateOnlineVenue = (module) => async (_, onlineVenueId, data) => {
  const venue = await VenueService.updateOnline(
    onlineVenueId,
    pick(['link', 'passcode', 'timezone'], data)
  )

  module.setState({ entities: { [venue.id]: venue } })

  return venue
}

const venueModule = createModule({
  name: 'venue',
  initialState: INITIAL_STATE,
  decorators: {
    createPhysicalVenue,
    updatePhysicalVenue,
    createOnlineVenue,
    updateOnlineVenue,
    deletePhysicalVenue,
    fetchVenues,
  },
})

export { venueModule }
