import { CommunityVenue } from './CommunityVenue'
import { OnlineVenue } from './OnlineVenue'
import { PhysicalVenue } from './PhysicalVenue'

function VenueStep({ venues, provider }) {
  return (
    <>
      <OnlineVenue provider={provider} />
      <PhysicalVenue venues={venues} provider={provider} />
      <CommunityVenue provider={provider} />
    </>
  )
}

export { VenueStep }
