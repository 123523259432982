import { Accordion, Section, Panel } from '@accessible/accordion'
import { append, path, filter, prop } from '@soltalabs/ramda-extra'
import { useFormikContext as useFormContext, getIn } from 'formik'
import { useRef, useCallback } from 'react'

import { AccordionHeader } from './AccordionHeader'
import { ActivityForm } from './ActivityForm'
import { EditLocationModal } from './EditLocationModal'

import { AddEventModal } from 'components/listings/common/AddEventModal'
import { EditEventModal } from 'components/listings/common/EditEventModal'
import { ReadEventModal } from 'components/listings/common/ReadEventModal'
import { styled, s } from 'lib/styled'
import { deepMemo } from 'utils/react'

const Root = styled.div(s('flex-1 flex flex-column'), { overflowY: 'hidden' })

const Memoized = deepMemo(PhysicalActivitiesForm)

function PhysicalActivitiesForm({ isEditing = true }) {
  const addEventModalRef = useRef()
  const editLocationModalRef = useRef()
  const editEventModalRef = useRef()
  const readEventModalRef = useRef()

  const { values, setFieldValue } = useFormContext()
  const listingTitle = path(['description', 'title'], values)
  const { availabilities } = values

  const handleCreateEvent = useCallback(({ title, field, selectedStartDate }) => {
    addEventModalRef.current.open({
      title,
      field,
      listingTitle,
      selectedStartDate,
    })
  }, [])

  const handleEditEvent = useCallback((event) => {
    editEventModalRef.current.open(event)
  }, [])

  const handleReadEvent = useCallback(
    ({ isThisAvailability, listingTitle: otherListingTitle, ...rest }) => {
      readEventModalRef.current.open({
        listingTitle: isThisAvailability ? listingTitle : otherListingTitle,
        ...rest,
      })
    },
    []
  )

  const handleEventCreated = useCallback(
    (field, event) => {
      const events = getIn(values, field)
      setFieldValue(field, append(event, events))
    },
    [availabilities]
  )

  const handleEventEdited = useCallback(
    (field, event) => {
      const events = getIn(values, field)

      // remove same id event
      const updateEventId = prop('id')(event)
      const filteredEvents = filter(({ id }) => id !== updateEventId)(events)

      setFieldValue(field, append(event, filteredEvents))
    },
    [availabilities]
  )

  return (
    <Root>
      <AddEventModal onEventCreated={handleEventCreated} ref={addEventModalRef} />
      <EditEventModal
        onEventEdited={handleEventEdited}
        isEditing={isEditing}
        ref={editEventModalRef}
      />
      <ReadEventModal ref={readEventModalRef} />
      <EditLocationModal ref={editLocationModalRef} />

      <div style={{ overflowY: 'auto' }}>
        <Accordion allowAllClosed>
          {availabilities.map(({ isSelected, venue, hasOrders }, index) => {
            const { id, name, venueType: type } = venue

            return (
              <Section key={id} disabled={!isSelected}>
                <AccordionHeader
                  index={index}
                  title={name}
                  type={type}
                  isSelected={isSelected}
                  isEditing={isEditing}
                  hasOrders={hasOrders}
                />

                <Panel>
                  <ActivityForm
                    index={index}
                    isSelected={isSelected}
                    onEditVenue={() => editLocationModalRef.current.open(id)}
                    onCreateEvent={handleCreateEvent}
                    onEditEvent={handleEditEvent}
                    onReadEvent={handleReadEvent}
                    isEditing={isEditing}
                  />
                </Panel>
              </Section>
            )
          })}
        </Accordion>
      </div>
    </Root>
  )
}

export { Memoized as PhysicalActivitiesForm }
