import CONSTANTS from '@solta/constants'
import { isNil, path, pick, isNilOrEmpty } from '@soltalabs/ramda-extra'
import { connect } from '@soltalabs/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState } from 'react'
import * as Validator from 'yup'

import { AccountDetails } from './AccountDetails'
import { BusinessDetails } from './BusinessDetails'

import { styled, s } from 'lib/styled'
import { account$, isUpdatingAccount$, authModule } from 'modules/auth'
import { provider$, isUpdatingProvider$, providerModule } from 'modules/provider'

const Root = styled.div(s('flex-1 flex flex-row p-6'))

const Container = styled.div(
  s('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
    '& input': {
      lineHeight: 'unset',
    },
  })
)

const Details = styled(Form)(s('flex-1 flex flex-column'))
const Scrollable = styled.div(
  s('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const { string, boolean, object } = Validator

const url = string().url('Must be a URL')

const requiredString = string().required('This field is required')

const requiredPhoneFormat = requiredString.matches(
  CONSTANTS.REGEX.E164_PHONE_FORMAT,
  'Not a valid phone number'
)

const requiredOnlyLetters = string()
  .trim()
  .matches(/^[a-zA-Z\s]+$/, 'Must contain only letters')
  .required('This field is required')

const onlyNumbers = string().matches(/^[0-9]+$/, 'Must contain only numbers')

const buildTaxNumberValidation = () => ({
  is: false,
  then: string().required('This field is required'),
  otherwise: string().length(0, 'Not GST registered is checked'),
})

const validationSchema = Validator.object().shape({
  firstName: requiredOnlyLetters,
  lastName: requiredOnlyLetters,
  mobile: requiredPhoneFormat,
  facebook: url,
  instagram: url,
  twitter: url,
  name: requiredString,
  businessRegistrationNumber: onlyNumbers,
  taxNumber: string().when('notGSTRegistered', buildTaxNumberValidation()),
  notGSTRegistered: boolean(),
  bankDetails: object().shape({
    accountName: requiredString,
    accountNumber: onlyNumbers.required(),
    bsbNumber: onlyNumbers.required(),
    bankName: requiredString,
    amlVerification: boolean().optional(),
  }),
  tradingName: string(),
  website: url,
  phone: requiredPhoneFormat,
  businessEmail: requiredString.email('Not a valid email address'),
  address: object(),
  businessSummary: string(),
})

const ConnectedProfileDetails = connect(() => ({
  isUpdatingProvider: isUpdatingProvider$,
  isUpdatingAccount: isUpdatingAccount$,
  account: account$,
  provider: provider$,
}))(ProfileDetails)
const { updateProvider } = providerModule
const { updateAccount } = authModule

function ProfileDetails({ provider, account, isUpdatingProvider, isUpdatingAccount }) {
  const [isEditing, setIsEditing] = useState(false)

  if (isNil(provider)) {
    return <div />
  }
  const { email, firstName, lastName, mobile } = account
  const {
    id,
    logo,
    name,
    businessRegistrationNumber,
    taxNumber,
    tradingName,
    phone,
    website,
    businessSummary,
    email: businessEmail,
    bankDetails,
  } = provider

  const facebook = path(['socialNetworks', 'facebook'], provider)
  const twitter = path(['socialNetworks', 'twitter'], provider)
  const instagram = path(['socialNetworks', 'instagram'], provider)

  const handleEditButton = () => {
    setIsEditing(true)
  }

  async function handleSubmit(values) {
    updateAccount(null, pick(['firstName', 'lastName', 'mobile'], values))
    updateProvider(
      id,
      pick(
        [
          'address',
          'businessSummary',
          'website',
          'phone',
          'name',
          'businessRegistrationNumber',
          'taxNumber',
          'tradingName',
          'instagram',
          'twitter',
          'facebook',
          'businessEmail',
          'bankDetails',
        ],
        values
      )
    )
    setIsEditing(false)
  }
  return (
    <Root>
      <Container>
        <Scrollable>
          <FormProvider
            enableReinitialize
            initialValues={{
              provider,
              email,
              firstName,
              lastName,
              mobile,
              facebook,
              twitter,
              instagram,
              name,
              businessRegistrationNumber,
              taxNumber,
              tradingName,
              phone,
              website,
              businessSummary,
              address: undefined,
              businessEmail,
              bankDetails,
              notGSTRegistered: isNilOrEmpty(taxNumber),
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Details>
              <AccountDetails
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onClick={handleEditButton}
                isUpdatingProvider={isUpdatingProvider}
                isUpdatingAccount={isUpdatingAccount}
              />
              <BusinessDetails isEditing={isEditing} logo={logo} />
            </Details>
          </FormProvider>
        </Scrollable>
      </Container>
    </Root>
  )
}

export { ConnectedProfileDetails as ProfileDetails }
