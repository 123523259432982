import { prop, path } from '@soltalabs/ramda-extra'
import { select } from '@soltalabs/stateless'

import { ProductDraftModule } from './module'

const { state$, effectState$ } = ProductDraftModule

const productDraft$ = select(state$, prop('entity'))
const productDraftStatus$ = select(productDraft$, prop('status'))

const isPublishingProductDraft$ = select(effectState$, (state) => {
  const publishStatus = path(['publish', 'status'], state)

  return publishStatus === 'pending'
})

export { productDraftStatus$, isPublishingProductDraft$ }
