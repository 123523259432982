import { prop } from '@soltalabs/ramda-extra'

import { http } from 'httpClient'

const OperatingSystemService = {
  async readTimezones() {
    const constants = await http.get('constants').json()

    return prop('TIMEZONES', constants)
  },
}

export { OperatingSystemService }
